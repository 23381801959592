const imageSectionStyle = {
  mediaBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '294px',
    alignItems: 'center',
    background: '',
    boxSizing: 'border-box',
    padding: '20px',
    gap: '16px',
  },
  main_Box: {
    display: 'flex',
    width: '100%',
    height: 'fit-content',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '',
  },
  radioButoon_Text: (theme) => ({
    color: theme.palette.button.dark,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontStyle: 'normal',
    fontWeight: theme.typography.h5.fontWeight,
    lineHeight: '24px',
  }),
}
export default imageSectionStyle
export const uploadMoreBtn = (media, theme) => ({
  visibility: media.length === 8 || media.length === 0 ? 'hidden' : 'visible',
  height: '38px',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: '9px var(--Components-Button-Spacing-X-SM, 12px)',
  fontSize: theme?.typography.menuItems.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: theme.palette.button.dark,
  fontStyle: 'normal',
  lineHeight: '20px',
  width: 'fit-content',
  textTransform: 'capitalize',
  color: 'white',
  '&.Mui-disabled': {
    color: 'white', // Text color for disabled state
  },
  ':hover': {
    background: theme.palette.button.dark,
  },
})

export const imageSwiperSectionStyle = {
  mainBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 'fit-content',
    gap: '13px',
    background: '',
    justifyContent: 'center',
  },
  swiperStyle: {
    width: '100%',
    height: '100%',
    background: '',
  },
  swiperSlide_Box: {
    position: 'relative',
    height: '200px',
    maxWidth: '350px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover .media-actions': { display: 'flex' },
  },
  video_Style: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: '10px',
  },
  image_Style: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: '10px',
  },
  action_ButtonBox: {
    display: 'none',
    position: 'absolute',
    zIndex: 1,
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    borderRadius: '10px',
  },
  icon_Style: {
    height: '30px',
    width: '30px',
    backgroundColor: 'lightgray',
    '&:hover': { backgroundColor: 'lightgray' },
  },
  custom_Size: {
    height: '20px',
    width: '20px',
  },
}
export const imageEditModalPinturaStyle = {
  modalStyle: {
    height: '731px',
    width: '680px',
    borderRadius: '24px',
    mx: 'auto',
    my: 'auto',
    background: 'white',
  },
  pintora_Box: {
    height: '100%',
    width: '100%',
    overflow: 'hidden', // Prevent overflow
    // position: "relative",
    background: 'white', // Ensure background is visible
    borderRadius: '24px',
    // mt:'auto'
  },
}
