const storyCardStyle = {
  parent_Box: {
    objectFit: 'cover',
    backgroundPosition: 'center',
    height: '530px',
    width: '320px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '8px',
    borderRadius: '16px',
    padding: '4px',
    boxSizing: 'border-box',
    background: '',
  },
  main_Box: {
    display: 'flex',
    padding: '8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '12px',
    border: '1px solid rgba(255, 255, 255, 0.50)',
    background: 'rgba(255, 255, 255, 0.12)',
    backdropFilter: 'blur(16px)',
  },
  iconBox: (theme) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    color: theme.palette.customWhite.main,
  }),
  timeText_Style: (theme) => ({
    fontSize: theme.typography.paragraph.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    lineHeight: 'normal',
  }),
  moreVertRounded_Style: (theme) => ({
    color: theme.palette.customWhite.main,
    cursor: 'pointer',
    fontSize: '20px',
  }),
  menu_Style: (theme) => ({
    boxSizing: 'border-box',
    padding:
      'var(--Overlays-Dropdowns-List-Spacing-Y, 0px) var(--Overlays-Dropdowns-List-Spacing-X, 8px)',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '16px',
    background: theme.palette.background.default,
    boxShadow: '0px 6px 10px 0px rgba(0,0,0,0.06)',
  }),
  menuItem_Style: (item, theme) => ({
    boxSizing: 'border-box',
    lineHeight: '20px',
    letterSpacing: '0.07px',
    fontSize: theme.typography?.menuItems?.fontSize || '',
    fontWeight: theme.typography?.menuItems?.fontWeight || 'normal',
    fontFamily: theme.typography?.menuItems?.fontFamily,
    color: item === 'Delete' ? 'red' : theme.palette?.black?.main,
    '&:hover': {
      backgroundColor: theme.palette?.gray?.hover || '#f5f5f5',
      borderRadius: 'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)',
    },
  }),
}
export default storyCardStyle
