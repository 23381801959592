import { useContext } from 'react'
import makeHeader from './Headers'
import UserContext from '../Context/userContext'

export const formatDateString = (isoString) => {
  const date = new Date(isoString)
  const month = date.toLocaleString('default', { month: 'short' })
  const day = date.getDate()
  const year = date.getFullYear()
  return `${month} ${day}, ${year}`
}

export const formatDate = (dateString) => {
  const date = new Date(dateString)
  const options = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }
  return date.toLocaleDateString('en-US', options)
}

export const createSpaceAPI = async (workspaceFormData, userAuth) => {
  console.log('workspaceFormData before', workspaceFormData)
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/workspace/create`

    const response = await fetch(
      apiUrl,
      makeHeader('POST', idToken, workspaceFormData)
    )

    // console.log('Full response:', response.ok)
    const responseData = await response.json()
    // console.log('new data', responseData)

    return responseData
  } catch (error) {
    console.error('Error in createSpaceAPI:', error)
    throw error
  }
}

export const getAllSpacesAPI = async (userAuth, currentUser) => {
  // console.log('currentUser in api', currentUser?._id)
  const createrId = currentUser?._id
  try {
    const idToken = await userAuth?.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/workspace/my-workspaces/${createrId}`

    const response = await fetch(apiUrl, makeHeader('GET', idToken))

    // console.log('Full response:', response.ok);

    const responseData = await response.json()

    // console.log('new data', responseData);

    return responseData // Return the data here
  } catch (error) {
    console.error('Error in getAllSpacesAPI:', error)
    throw error
  }
}

export const getSpecificSpacesAPI = async (userAuth, currentUser, spaceId) => {
  const createrId = currentUser?._id
  // console.log("data getSpecificSpacesAPI space",userAuth, currentUser, spaceId)
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/workspace/get/?spaceId=${spaceId}&creatorId=${createrId}`

    const response = await fetch(apiUrl, makeHeader('GET', idToken))
    const responseData = await response.json()

    return responseData // Return the data here
  } catch (error) {
    console.error('Error in getAllSpacesAPI:', error)
    throw error
  }
}

export const updateSpacesAPI = async (userAuth, id, body) => {
  // console.log('userAuth', userAuth, 'iddd', id, 'bodddyyyy', body)
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/workspace/update/${id}`

    const response = await fetch(apiUrl, makeHeader('PUT', idToken, body))

    // console.log('Full response:', response.ok)

    const responseData = await response.json()
    // console.log('new data', responseData)

    return responseData
  } catch (error) {
    console.error('Error in updateSpacesAPI:', error)
    throw error
  }
}

export const archiveSpacesAPI = async (userAuth, id, deleteObject) => {
  console.log('archiveobject', deleteObject, 'archiveobject ID', id)
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/workspace/delete/${id}?isDeleted=${deleteObject.isDeleted}`

    const response = await fetch(apiUrl, makeHeader('PUT', idToken, {}))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in createSpaceAPI:', error)
    throw error
  }
}

export const getArchiveSpacesAPI = async (userAuth, currentUser) => {
  // console.log('deleteobject',deleteObject);
  const createrId = currentUser?._id
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/workspace/my-archived-spaces/${createrId}`

    const response = await fetch(apiUrl, makeHeader('GET', idToken))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in getArchiveSpacesAPI:', error)
    throw error
  }
}

export const getAllRolesAPI = async (userAuth, id) => {
  console.log('userAuth of roles', userAuth)
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/workspace/roles/${id}`

    const response = await fetch(apiUrl, makeHeader('GET', idToken))
    const responseData = await response.json()
    console.log('responseData', response)

    return responseData // Return the data here
  } catch (error) {
    console.error('Error in getAllRolesAPI:', error)
    throw error
  }
}

export const addNewRoleAPI = async (userAuth, body) => {
  console.log('userAuth of addNewRoleAPI', userAuth)
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/role/add`

    const response = await fetch(apiUrl, makeHeader('POST', idToken, body))
    const responseData = await response.json()
    console.log('responseData of addNewRoleAPI', response)

    return responseData // Return the data here
  } catch (error) {
    console.error('Error in addNewRoleAPI:', error)
    throw error
  }
}

export const getAllPermissionAPI = async (userAuth, appName) => {
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/permission/get_all?appName=${appName}`

    const response = await fetch(apiUrl, makeHeader('GET', idToken))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in getAllPermissionAPI:', error)
    throw error
  }
}

export const inviteMemberAPI = async (userAuth, body) => {
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/member/invite`

    const response = await fetch(apiUrl, makeHeader('POST', idToken, body))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in inviteMemberAPI:', error)
    throw error
  }
}

export const workspaceMembersAPI = async (userAuth, workspaceId) => {
  // console.log('workspaceId yes',workspaceId);
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/workspace/members/${workspaceId}`

    const response = await fetch(apiUrl, makeHeader('GET', idToken))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in workspaceMembersAPI:', error)
    throw error
  }
}

export const specificRolePermissionAPI = async (userAuth, roleId) => {
  console.log('roleId', roleId)
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/role/permissions/${roleId}`

    const response = await fetch(apiUrl, makeHeader('GET', idToken))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in specificRolePermissionAPI:', error)
    throw error
  }
}

export const updateSpecificRoleAPI = async (userAuth, roleId, body) => {
  console.log('body', body)
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/role/update/${roleId}`

    const response = await fetch(apiUrl, makeHeader('PUT', idToken, body))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in updateSpecificRoleAPI:', error)
    throw error
  }
}

export const updateSpecificMemberRoleAPI = async (userAuth, memberId, body) => {
  console.log('body', body)
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/member/edit_role/${memberId}`

    const response = await fetch(apiUrl, makeHeader('PUT', idToken, body))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in updateSpecificMemberRoleAPI', error)
    throw error
  }
}

export const resendInviteMemberAPI = async (userAuth, body) => {
  // console.log('resendInviteMemberAPI body',body);
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/member/invite/resend`

    const response = await fetch(apiUrl, makeHeader('POST', idToken, body))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in resendInviteMemberAPI', error)
    throw error
  }
}

export const handleAcceptInviteMemberAPI = async (userAuth, memberId, body) => {
  // console.log('resendInviteMemberAPI body',body);
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/member/handle_accept/${memberId}`

    const response = await fetch(apiUrl, makeHeader('POST', idToken, body))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in handleInviteMemberAPI', error)
    throw error
  }
}
export const handleRejectInviteMemberAPI = async (memberId, body) => {
  // console.log('resendInviteMemberAPI body',body);
  try {
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/member/handle_reject/${memberId}`

    const response = await fetch(apiUrl, makeHeader('POST', null, body))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in handleInviteMemberAPI', error)
    throw error
  }
}
export const invitedWorkspaceDataAPI = async (memberId, workspaceId) => {
  // console.log('resendInviteMemberAPI body',body);
  try {
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/member/invitation/data?spaceId=${workspaceId}&memberId=${memberId}`

    const response = await fetch(apiUrl, makeHeader('GET'))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in invitedWorkspaceDataAPI', error)
    throw error
  }
}

export const userAllWorkspaces = async (userAuth) => {
  // console.log('resendInviteMemberAPI body',body);
  const idToken = await userAuth.getIdToken(true)

  try {
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/workspace/getAll`

    const response = await fetch(apiUrl, makeHeader('GET', idToken))

    const responseData = await response.json()

    return responseData
  } catch (error) {
    console.error('Error in userAllWorkspaces', error)
    throw error
  }
}
