/* eslint-disable no-unused-expressions */
import React from 'react'
export const VideoSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='97'
    height='77'
    viewBox='0 0 97 77'
    fill='none'
  >
    <path
      d='M16.5328 24.0904H16.8279C19.582 23.8936 21.6475 21.533 21.5492 18.7789C21.3525 16.0248 18.9918 13.9592 16.2377 14.0576C13.4836 14.1559 11.418 16.6149 11.5164 19.369C11.6148 22.0248 13.877 24.0904 16.5328 24.0904ZM14.1721 17.0084C14.6639 16.4182 15.4508 16.0248 16.2377 16.0248H16.4344C18.1066 16.0248 19.4836 17.4018 19.4836 19.074C19.4836 20.7461 18.1066 22.1231 16.4344 22.1231C14.7623 22.1231 13.3852 20.7461 13.3852 19.074C13.3852 18.2871 13.6803 17.5986 14.1721 17.0084Z'
      fill='#858585'
    />
    <path
      d='M83.8115 18.5818L57.5492 17.1064L57.3525 14.1555C57.0574 8.54898 52.2377 4.22111 46.5328 4.51619L13.1885 6.38504C7.58197 6.68012 3.2541 11.5982 3.54918 17.2047L5.41803 50.6473V51.4342C6.10656 56.549 10.4344 60.385 15.5492 60.385H16.1393L37.4836 59.2047V59.7949C37.1885 65.4014 41.418 70.3195 47.123 70.6146L80.5656 72.5818H81.1557C86.5656 72.5818 91.0902 68.3523 91.3853 62.9424L93.3525 29.4998C93.7459 23.7949 89.418 18.9752 83.8115 18.5818ZM7.58197 11.1064C9.05738 9.43422 11.123 8.45062 13.2869 8.35226L46.7295 6.4834C51.2541 6.18832 55.1885 9.72931 55.4836 14.2539L55.6803 17.008L50.4672 16.7129C44.8607 16.4178 39.9426 20.6473 39.6475 26.3523L39.0574 35.6965L29.7131 46.2211C29.5164 46.5162 29.123 46.6146 28.7295 46.7129C28.3361 46.7129 28.041 46.6146 27.7459 46.3195L20.0738 39.4342C18.6967 38.1555 16.6311 38.3523 15.3525 39.7293L7.38525 48.6801L5.61475 17.2047C5.41803 14.9424 6.20492 12.7785 7.58197 11.1064ZM16.1393 58.3195C11.9098 58.5162 8.17213 55.5654 7.48361 51.3359L16.7295 41.008C16.9262 40.7129 17.3197 40.6146 17.7131 40.5162C18.1066 40.5162 18.4016 40.6146 18.6967 40.9096L26.3689 47.7949C27.0574 48.385 27.9426 48.6801 28.8279 48.6801C29.7131 48.6801 30.5 48.1883 31.0902 47.5982L38.7623 38.9424L37.6803 57.2375L16.1393 58.3195ZM91.3853 29.3031L89.418 62.7457C89.123 67.2703 85.1885 70.8113 80.6639 70.5162L47.2213 68.549C42.6967 68.2539 39.1557 64.3195 39.4508 59.7949L41.418 26.3523C41.7131 22.0244 45.2541 18.5818 49.6803 18.5818H50.1721L83.6148 20.549C88.2377 20.8441 91.6803 24.7785 91.3853 29.3031Z'
      fill='#858585'
    />
    <path
      d='M77.418 41.4019L60.7951 30.4838C58.7295 29.1068 55.9754 29.697 54.6967 31.7625C54.3033 32.451 54.0082 33.1396 54.0082 33.9265L52.8279 53.697C52.7295 56.156 54.5 58.2215 56.959 58.4183H57.2541C57.9426 58.4183 58.6311 58.2215 59.2213 57.9265L76.9262 49.074C79.0902 47.992 79.9754 45.3363 78.8934 43.1724C78.5 42.4838 78.0082 41.8937 77.418 41.4019ZM76.041 47.3035L58.3361 56.156C57.9426 56.3527 57.5492 56.4511 57.0574 56.4511C56.6639 56.4511 56.1721 56.2543 55.877 56.0576C55.1885 55.5658 54.6967 54.7789 54.7951 53.8937L55.9754 34.1232C56.0738 33.2379 56.5656 32.451 57.3525 32.0576C58.1393 31.6642 59.0246 31.7625 59.8115 32.156L76.2377 43.074C77.418 43.8609 77.7131 45.3363 76.9262 46.4183C76.7295 46.8117 76.4344 47.1068 76.041 47.3035Z'
      fill='#858585'
    />
  </svg>
)
export const AiBlueIconSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='16'
    viewBox='0 0 21 16'
    fill='none'
  >
    <path
      d='M5.97897 8.49967C5.51977 8.32481 5.51977 7.67519 5.97897 7.50033C8.82993 6.41474 11.0815 4.16318 12.1671 1.31223C12.3419 0.85302 12.9916 0.85302 13.1664 1.31223C14.252 4.16318 16.5036 6.41474 19.3545 7.50033C19.8137 7.67519 19.8137 8.32481 19.3545 8.49967C16.5036 9.58526 14.252 11.8368 13.1664 14.6878C12.9916 15.147 12.3419 15.147 12.1671 14.6878C11.0815 11.8368 8.82993 9.58526 5.97897 8.49967Z'
      fill='#4950FF'
    />
    <path
      d='M3.10416 3.49989C2.95109 3.4416 2.95109 3.22506 3.10416 3.16678C4.05447 2.80491 4.805 2.05439 5.16686 1.10408C5.22514 0.951007 5.44169 0.951007 5.49997 1.10407C5.86183 2.05439 6.61235 2.80491 7.56267 3.16678C7.71574 3.22506 7.71574 3.4416 7.56267 3.49989C6.61235 3.86175 5.86183 4.61227 5.49997 5.56259C5.44169 5.71566 5.22514 5.71566 5.16686 5.56259C4.805 4.61227 4.05447 3.86175 3.10416 3.49989Z'
      fill='#4950FF'
    />
    <path
      d='M0.492085 11.8539C0.319883 11.7883 0.319883 11.5447 0.492085 11.4791C1.56119 11.072 2.40553 10.2277 2.81262 9.15859C2.8782 8.98639 3.1218 8.98639 3.18738 9.15859C3.59447 10.2277 4.43881 11.072 5.50792 11.4791C5.68012 11.5447 5.68012 11.7883 5.50792 11.8539C4.43881 12.261 3.59447 13.1053 3.18738 14.1744C3.1218 14.3466 2.8782 14.3466 2.81262 14.1744C2.40553 13.1053 1.56119 12.261 0.492085 11.8539Z'
      fill='#4950FF'
    />
  </svg>
)
export const AiWhiteIconSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='16'
    viewBox='0 0 21 16'
    fill='none'
  >
    <path
      d='M5.97897 8.49967C5.51977 8.32481 5.51977 7.67519 5.97897 7.50033C8.82993 6.41474 11.0815 4.16318 12.1671 1.31223C12.3419 0.85302 12.9916 0.85302 13.1664 1.31223C14.252 4.16318 16.5036 6.41474 19.3545 7.50033C19.8137 7.67519 19.8137 8.32481 19.3545 8.49967C16.5036 9.58526 14.252 11.8368 13.1664 14.6878C12.9916 15.147 12.3419 15.147 12.1671 14.6878C11.0815 11.8368 8.82993 9.58526 5.97897 8.49967Z'
      fill='#FFF'
    />
    <path
      d='M3.10416 3.49989C2.95109 3.4416 2.95109 3.22506 3.10416 3.16678C4.05447 2.80491 4.805 2.05439 5.16686 1.10408C5.22514 0.951007 5.44169 0.951007 5.49997 1.10407C5.86183 2.05439 6.61235 2.80491 7.56267 3.16678C7.71574 3.22506 7.71574 3.4416 7.56267 3.49989C6.61235 3.86175 5.86183 4.61227 5.49997 5.56259C5.44169 5.71566 5.22514 5.71566 5.16686 5.56259C4.805 4.61227 4.05447 3.86175 3.10416 3.49989Z'
      fill='#FFF'
    />
    <path
      d='M0.492085 11.8539C0.319883 11.7883 0.319883 11.5447 0.492085 11.4791C1.56119 11.072 2.40553 10.2277 2.81262 9.15859C2.8782 8.98639 3.1218 8.98639 3.18738 9.15859C3.59447 10.2277 4.43881 11.072 5.50792 11.4791C5.68012 11.5447 5.68012 11.7883 5.50792 11.8539C4.43881 12.261 3.59447 13.1053 3.18738 14.1744C3.1218 14.3466 2.8782 14.3466 2.81262 14.1744C2.40553 13.1053 1.56119 12.261 0.492085 11.8539Z'
      fill='#FFF'
    />
  </svg>
)
export const MediaIconSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='96'
    height='77'
    viewBox='0 0 96 77'
    fill='none'
  >
    <path
      d='M16.0328 24.0904H16.3279C19.082 23.8936 21.1476 21.533 21.0492 18.7789C20.8525 16.0248 18.4918 13.9592 15.7377 14.0576C12.9836 14.1559 10.9181 16.6149 11.0164 19.369C11.1148 22.0248 13.3771 24.0904 16.0328 24.0904ZM13.6722 17.0084C14.164 16.4182 14.9509 16.0248 15.7377 16.0248H15.9345C17.6066 16.0248 18.9836 17.4018 18.9836 19.074C18.9836 20.7461 17.6066 22.1231 15.9345 22.1231C14.2623 22.1231 12.8853 20.7461 12.8853 19.074C12.8853 18.2871 13.1804 17.5986 13.6722 17.0084Z'
      fill='black'
    />
    <path
      d='M83.3114 18.5818L57.0492 17.1064L56.8524 14.1555C56.5574 8.54898 51.7377 4.22111 46.0328 4.51619L12.6885 6.38504C7.08194 6.68012 2.75407 11.5982 3.04915 17.2047L4.918 50.6473V51.4342C5.60653 56.549 9.9344 60.385 15.0492 60.385H15.6393L36.9836 59.2047V59.7949C36.6885 65.4014 40.918 70.3195 46.6229 70.6146L80.0656 72.5818H80.6557C86.0656 72.5818 90.5901 68.3523 90.8852 62.9424L92.8524 29.4998C93.2459 23.7949 88.918 18.9752 83.3114 18.5818ZM7.08194 11.1064C8.55735 9.43422 10.6229 8.45062 12.7869 8.35226L46.2295 6.4834C50.7541 6.18832 54.6885 9.72931 54.9836 14.2539L55.1803 17.008L49.9672 16.7129C44.3606 16.4178 39.4426 20.6473 39.1475 26.3523L38.5574 35.6965L29.2131 46.2211C29.0164 46.5162 28.6229 46.6146 28.2295 46.7129C27.836 46.7129 27.541 46.6146 27.2459 46.3195L19.5737 39.4342C18.1967 38.1555 16.1311 38.3523 14.8524 39.7293L6.88522 48.6801L5.11472 17.2047C4.918 14.9424 5.70489 12.7785 7.08194 11.1064ZM15.6393 58.3195C11.4098 58.5162 7.6721 55.5654 6.98358 51.3359L16.2295 41.008C16.4262 40.7129 16.8196 40.6146 17.2131 40.5162C17.6065 40.5162 17.9016 40.6146 18.1967 40.9096L25.8688 47.7949C26.5573 48.385 27.4426 48.6801 28.3278 48.6801C29.2131 48.6801 30 48.1883 30.5901 47.5982L38.2623 38.9424L37.1803 57.2375L15.6393 58.3195ZM90.8852 29.3031L88.918 62.7457C88.6229 67.2703 84.6885 70.8113 80.1639 70.5162L46.7213 68.549C42.1967 68.2539 38.6557 64.3195 38.9508 59.7949L40.918 26.3523C41.2131 22.0244 44.7541 18.5818 49.1803 18.5818H49.6721L83.1147 20.549C87.7377 20.8441 91.1803 24.7785 90.8852 29.3031Z'
      fill='black'
    />
    <path
      d='M76.918 41.4019L60.2951 30.4838C58.2295 29.1068 55.4754 29.697 54.1967 31.7625C53.8033 32.451 53.5082 33.1396 53.5082 33.9265L52.3279 53.697C52.2295 56.156 54 58.2215 56.459 58.4183H56.7541C57.4426 58.4183 58.1312 58.2215 58.7213 57.9265L76.4262 49.074C78.5902 47.992 79.4754 45.3363 78.3934 43.1724C78 42.4838 77.5082 41.8937 76.918 41.4019ZM75.541 47.3035L57.8361 56.156C57.4426 56.3527 57.0492 56.4511 56.5574 56.4511C56.1639 56.4511 55.6721 56.2543 55.3771 56.0576C54.6885 55.5658 54.1967 54.7789 54.2951 53.8937L55.4754 34.1232C55.5738 33.2379 56.0656 32.451 56.8525 32.0576C57.6394 31.6642 58.5246 31.7625 59.3115 32.156L75.7377 43.074C76.918 43.8609 77.2131 45.3363 76.4262 46.4183C76.2295 46.8117 75.9344 47.1068 75.541 47.3035Z'
      fill='black'
    />
  </svg>
)
export const LikeInstaIconSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <path
      d='M7 22V11M2 13V20C2 21.1046 2.89543 22 4 22H17.4262C18.907 22 20.1662 20.9197 20.3914 19.4562L21.4683 12.4562C21.7479 10.6389 20.3418 9 18.5032 9H15C14.4477 9 14 8.55228 14 8V4.46584C14 3.10399 12.896 2 11.5342 2C11.2093 2 10.915 2.1913 10.7831 2.48812L7.26394 10.4061C7.10344 10.7673 6.74532 11 6.35013 11H4C2.89543 11 2 11.8954 2 13Z'
      stroke='#0C0C0C'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export const CommentInstaIconSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <path
      d='M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z'
      stroke='#0C0C0C'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export const VerifyInstaIconSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <g clipPath='url(#clip0_12273_16041)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.79678 1.05107C8.39663 0.52334 7.60327 0.523341 7.20311 1.05107L6.21039 2.3603C5.99205 2.64825 5.63535 2.796 5.27735 2.74678L3.64963 2.52298C2.99351 2.43277 2.43252 2.99375 2.52273 3.64987L2.74653 5.27759C2.79576 5.63559 2.648 5.9923 2.36005 6.21064L1.05083 7.20336C0.523096 7.60351 0.523097 8.39687 1.05083 8.79702L2.36005 9.78975C2.64801 10.0081 2.79576 10.3648 2.74653 10.7228L2.52273 12.3505C2.43252 13.0066 2.99351 13.5676 3.64963 13.4774L5.27735 13.2536C5.63535 13.2044 5.99205 13.3521 6.21039 13.6401L7.20311 14.9493C7.60327 15.477 8.39663 15.477 8.79678 14.9493L9.7895 13.6401C10.0078 13.3521 10.3645 13.2044 10.7225 13.2536L12.3503 13.4774C13.0064 13.5676 13.5674 13.0066 13.4772 12.3505L13.2534 10.7228C13.2041 10.3648 13.3519 10.0081 13.6398 9.78975L14.9491 8.79702C15.4768 8.39687 15.4768 7.60351 14.9491 7.20336L13.6398 6.21064C13.3519 5.9923 13.2041 5.63559 13.2534 5.27759L13.4772 3.64987C13.5674 2.99375 13.0064 2.43277 12.3503 2.52298L10.7225 2.74678C10.3645 2.796 10.0078 2.64825 9.7895 2.3603L8.79678 1.05107ZM11.0964 6.69719C11.3893 6.40429 11.3893 5.92942 11.0964 5.63653C10.8035 5.34363 10.3287 5.34364 10.0358 5.63653L7.26609 8.4062L6.32976 7.46986C6.03686 7.17697 5.56199 7.17697 5.2691 7.46986C4.9762 7.76275 4.9762 8.23763 5.2691 8.53052L6.73576 9.99719C6.87642 10.1378 7.06718 10.2169 7.26609 10.2169C7.46501 10.2169 7.65577 10.1378 7.79642 9.99719L11.0964 6.69719Z'
        fill='#000464'
      />
    </g>
    <defs>
      <clipPath id='clip0_12273_16041'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const YoutubeSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100'
    height='71'
    viewBox='0 0 100 71'
    fill='none'
  >
    <g clipPath='url(#clip0_13355_14650)'>
      <path
        d='M97.7915 10.9668C97.2179 8.84709 96.099 6.91463 94.5463 5.36183C92.9936 3.80903 91.0612 2.69008 88.9415 2.11641C81.1813 0 49.9493 0 49.9493 0C49.9493 0 18.7157 0.0640623 10.9555 2.18047C8.83582 2.75418 6.90337 3.87319 5.35064 5.42606C3.7979 6.97893 2.67907 8.91147 2.10554 11.0312C-0.241722 24.8195 -1.15227 45.8297 2.17 59.0664C2.74358 61.1861 3.86244 63.1186 5.41517 64.6714C6.9679 66.2242 8.90032 67.3431 11.02 67.9168C18.7802 70.0332 50.013 70.0332 50.013 70.0332C50.013 70.0332 81.2454 70.0332 89.0052 67.9168C91.1249 67.3432 93.0574 66.2243 94.6102 64.6715C96.163 63.1187 97.2819 61.1862 97.8555 59.0664C100.331 45.2586 101.094 24.2613 97.7915 10.9668Z'
        fill='#FF0000'
      />
      <path
        d='M40.0082 50.0234L65.9176 35.0164L40.0082 20.0094V50.0234Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_13355_14650'>
        <rect width='100' height='70.3125' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export const OutlinedYoutubeSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='97'
    height='97'
    viewBox='0 0 97 97'
    fill='none'
  >
    <path
      d='M86.724 29.2881C86.2693 27.5941 85.3771 26.0495 84.1369 24.8092C82.8966 23.569 81.352 22.6768 79.658 22.2221C75.03 20.4341 30.194 19.5581 17.186 22.2721C15.4918 22.727 13.947 23.6195 12.7067 24.8601C11.4664 26.1007 10.5744 27.6458 10.12 29.3401C8.03 38.5061 7.872 58.3221 10.172 67.6941C10.6267 69.3881 11.5189 70.9328 12.7591 72.173C13.9994 73.4133 15.544 74.3055 17.238 74.7601C26.404 76.8701 69.98 77.1661 79.71 74.7601C81.404 74.3055 82.9486 73.4133 84.1889 72.173C85.4291 70.9328 86.3213 69.3881 86.776 67.6941C89.004 57.7081 89.162 39.1201 86.724 29.2881Z'
      stroke='#494949'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M61.6342 48.4898L40.7402 36.5078V60.4718L61.6342 48.4898Z'
      stroke='#494949'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
