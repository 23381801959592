export const addPickerStyle = {
  mainBox: {
    display: 'flex',
    height: 'fit-content',
    width: '100%',
    background: '',
    alignItems: 'center',
    justifyContent: 'space-between',
    mt: 'auto',
    position: 'relative',
  },
  text_Style: (theme) => ({
    color: theme.palette.border.dark,
    lineHeight: '18px',
    fontSize: theme?.typography.paragraph.fontSize,
    fontWeight: theme?.typography.h5.fontWeight,
    fontFamily: theme?.typography.h2.fontFamily,
  }),
  emojipickerStyle: {
    zIndex: '999',
    top: '300px',
    right: '290px',
    position: 'absolute',
  },
}
export const captionEmojiAndOptions = {
  main_Box: {
    width: '100%',
    background: '',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '20px',
  },
  desc_Text: (theme) => ({
    lineHeight: '24px',
    fontSize: theme?.typography.h4.fontSize,
    fontWeight: theme?.typography.h4.fontWeight,
    fontFamily: theme?.typography.h2.fontFamily,
  }),
  textField_MainBox: (theme) => ({
    width: '100%',
    height: '200px',
    boxSizing: 'border-box',
    padding: '16px',
    mt: '4px',
    display: 'flex',
    flexDirection: 'column',
    border: ` 1px solid ${theme?.palette.gray.light}`,
    borderRadius: '10px',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
  }),
  caption_Box: {
    display: 'flex',
    gap: '8px',
    height: 'fit-content',
    width: 'fit-content',
    alignItems: 'center',
  },
  writeCaption_TextStyle: (theme) => ({
    lineHeight: '20px',
    fontSize: theme?.typography.h5.fontSize,
    fontWeight: theme?.typography.h1.fontWeight,
    fontFamily: theme?.typography.h2.fontFamily,
    color: theme.palette.border.main,
  }),
  generateButton: (theme) => ({
    boxSizing: 'border-box',
    padding: '8px 12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: ` 1px solid ${theme?.palette.border.main}`,
    textTransform: 'capitalize',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    borderRadius: '8px',
    color: theme.palette.border.main,
  }),
  textField_Style: {
    background: '',
    width: '100%',
    overflowY: 'auto',
    '& textarea': {
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
}
