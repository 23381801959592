import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useTheme } from '@emotion/react'

const ReusableTable = ({
  width,
  columns,
  data,
  rowBackgroundColor = () => '#F9F9FF',
  getBorderColor = () => '#DADAFF',
}) => {
  const theme = useTheme()

  return (
    <div
      style={{
        maxHeight: 450,
        overflowX: 'auto',
      }}
      className='scrollbar'
    >
      <TableContainer>
        <Table
          sx={{
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: '0 10px',
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.field}
                  sx={{
                    backgroundColor: '#F9F9FF',
                    fontSize: theme.typography.h4.fontSize,
                    fontWeight: theme.typography.h2.fontWeight,
                    fontFamily: theme?.typography?.h2?.fontFamily,
                    padding: '10px 16px',
                    boxSizing: 'border-box',
                    borderLeft:
                      index === 0
                        ? `1px solid #DADAFF`
                        : '1px solid transparent',
                    borderBottom: `1px solid #DADAFF`,
                    borderRight:
                      index === columns.length - 1
                        ? `1px solid #DADAFF`
                        : '1px solid transparent',
                    borderTop: `1px solid #DADAFF`,
                    borderTopLeftRadius: index === 0 ? '11px' : '0',
                    borderBottomLeftRadius: index === 0 ? '11px' : '0',
                    borderTopRightRadius:
                      index === columns.length - 1 ? '11px' : '0',
                    borderBottomRightRadius:
                      index === columns.length - 1 ? '11px' : '0',
                  }}
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                sx={{
                  backgroundColor: rowBackgroundColor(row.role),
                }}
              >
                {columns.map((column, colIndex) => (
                  <TableCell
                    key={column.field}
                    sx={{
                      width: width || '24%',
                      boxSizing: 'border-box',
                      padding: '10px 16px',
                      borderLeft:
                        colIndex === 0
                          ? `1px solid ${getBorderColor(row.role)}`
                          : '1px solid transparent',
                      borderBottom: `1px solid ${getBorderColor(row.role)}`,
                      borderRight:
                        colIndex === columns.length - 1
                          ? `1px solid ${getBorderColor(row.role)}`
                          : '1px solid transparent',
                      borderTop: `1px solid ${getBorderColor(row.role)}`,
                      borderTopLeftRadius: colIndex === 0 ? '11px' : '0',
                      borderBottomLeftRadius: colIndex === 0 ? '11px' : '0',
                      borderTopRightRadius:
                        colIndex === columns.length - 1 ? '11px' : '0',
                      borderBottomRightRadius:
                        colIndex === columns.length - 1 ? '11px' : '0',
                    }}
                  >
                    {column.renderCell
                      ? column.renderCell(row)
                      : row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ReusableTable
