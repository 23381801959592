// PostMenu.js
import React from 'react'
import { Box, Menu, MenuItem, Typography } from '@mui/material'
import { homePage } from '../homePageStyle'
import { useTheme } from '@emotion/react'

const PostMenu = ({ anchorEl, activePostId, post, handleMenuClose }) => {
  const theme = useTheme()

  if (!post?.followerList) {
    return null
  }

  return (
    <Menu
      autoFocus={false}
      anchorEl={anchorEl}
      open={Boolean(anchorEl) && activePostId === post.id}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 35,
        horizontal: 'left',
      }}
      PaperProps={{
        style: homePage.postMenuList(theme),
      }}
    >
      {post.followerList.map((follower, index) => (
        <MenuItem key={index} sx={homePage.postMenuListItem(theme)}>
          <img
            src={follower.icon}
            alt={`${follower.name} icon`}
            style={{
              width: '18px',
              height: '18px',
            }}
          />

          {follower.name}
        </MenuItem>
      ))}
    </Menu>
  )
}

export default PostMenu
