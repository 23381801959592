import { ThemeProvider, createTheme } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import UserContext from '../../Context/userContext'
import ThemeContext from './themeContext'

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#241BFF',
      light: '#2563EB',
      dark: '#000464',
      aiColor: 'linear-gradient(90deg, #4950FF 0%, #6268FD 52%)',
    },
    button: {
      main: '#241BFF',
      light: '#2563EB',
      dark: '#000464',
      gray: '#F8F9FF',
      text: '#fff',
      aiColor: 'linear-gradient(90deg, #4950FF 0%, #6268FD 52%)',
    },
    text: {
      light: '#818089',
      // light: '#B5B5B5',

      hover: '#6B7280',
      main: '#000',
      // main: '#f2f2f2',
    },

    background: {
      default: '#fff',
      // default: '#0E0E10',
    },

    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
    },
    error: {
      main: '#EF4444',
      light: '#ef5350',
      dark: '#E34935',
      extraLight: '#FFEDEB',
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
    },

    info: {
      main: '#0288d1',
      light: '#03a9f4',
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#44b700',
    },
    black: {
      main: '#000',
      light: '#494949',
      extraLight: '#52575D',
      hover: '#6B7280',
    },
    sky: {
      main: '#6C66FF',
      light: '#DADAFF',
      // light: '#28282A',

      extraLight: '#E5E4FF',
      dark: '#CCE0FF',
    },
    customWhite: {
      main: '#FFF',
      light: '#EAEAEA',
      extraLight: '#F9F9FF',
      dark: '#FDFDFF',
      hover: '#F2F2F2',
      opacity50: 'rgba(255, 255, 255, 0.50)',
    },
    gray: {
      main: '#F8F9FF',
      // main: '#18181A',

      light: '#E5E7EB',
      extraLight: '#DBEAFE',
      dark: '#FAFBFC',
      hover: '#F3F4F6',
    },
    border: {
      main: '#C2C2C2',
      // main: '#28282A',
      star: '',
      // star: '#B5B5B5',
      dark: '#858585',
      extralight: '#E5E5E5',
      hover: '#C6C6CE',
    },
  },
  typography: {
    h1: {
      fontSize: '40px',
      fontWeight: '700',
      fontFamily: 'Sora',
    },
    h2: {
      fontSize: '24px',
      fontWeight: '600',
      fontFamily: 'Sora',
    },
    h3: {
      fontSize: '18px',
      fontWeight: '600',
      fontFamily: 'Sora',
    },
    h4: {
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'Sora',
    },
    h5: {
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Sora',
    },
    menuItems: {
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'Sora',
    },
    paragraph: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: 'Sora',
    },
    menualText: {
      fontSize: '32px',
      fontWeight: '700',
      fontFamily: 'Sora',
    },
    extraLarge: {
      fontSize: '38px',
      fontWeight: '800',
    },
    large: {
      fontSize: '30px',
    },
    mediumText: {
      fontSize: '20px',
    },
    headingOne: {
      fontSize: '36px',
    },
    headingTwo: {
      fontSize: '28px',
    },
    headingThree: {
      fontSize: '25px',
    },
  },
})

const darkTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#241BFF',
      light: '#2563EB',
      dark: '#000464',
      aiColor: 'linear-gradient(90deg, #4950FF 0%, #6268FD 52%)',
    },
    button: {
      main: '#241BFF',
      light: '#2563EB',
      dark: '#000464',
      gray: '#F8F9FF',
      text: '#fff',
      aiColor: 'linear-gradient(90deg, #4950FF 0%, #6268FD 52%)',
    },
    text: {
      light: '#818089',
      // light: '#B5B5B5',

      hover: '#6B7280',
      // main: '#000',
      main: '#f2f2f2',
    },

    background: {
      // default: '#fff',
      default: '#0E0E10',
    },

    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
    },
    error: {
      main: '#EF4444',
      light: '#ef5350',
      dark: '#E34935',
      extraLight: '#FFEDEB',
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
    },

    info: {
      main: '#0288d1',
      light: '#03a9f4',
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#44b700',
    },
    black: {
      main: '#000',
      light: '#494949',
      extraLight: '#52575D',
      hover: '#6B7280',
    },
    sky: {
      main: '#6C66FF',
      // light: '#DADAFF',
      light: '#28282A',

      extraLight: '#E5E4FF',
      dark: '#CCE0FF',
    },
    customWhite: {
      main: '#FFF',
      light: '#EAEAEA',
      extraLight: '#F9F9FF',
      dark: '#FDFDFF',
      hover: '#F2F2F2',
      opacity50: 'rgba(255, 255, 255, 0.50)',
    },
    gray: {
      // main: '#F8F9FF',
      main: '#18181A',

      light: '#E5E7EB',
      extraLight: '#DBEAFE',
      dark: '#FAFBFC',
      hover: '#F3F4F6',
    },
    border: {
      // main: '#C2C2C2',
      main: '#28282A',
      // star: '',
      star: '#B5B5B5',
      dark: '#858585',
      extralight: '#E5E5E5',
      hover: '#C6C6CE',
    },
  },

  typography: {
    h1: {
      fontSize: '40px',
      fontWeight: '700',
      fontFamily: 'Sora',
    },
    h2: {
      fontSize: '24px',
      fontWeight: '600',
      fontFamily: 'Sora',
    },
    h3: {
      fontSize: '18px',
      fontWeight: '600',
      fontFamily: 'Sora',
    },
    h4: {
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'Sora',
    },
    h5: {
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Sora',
    },
    menuItems: {
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'Sora',
    },
    paragraph: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: 'Sora',
    },
    menualText: {
      fontSize: '32px',
      fontWeight: '700',
      fontFamily: 'Sora',
    },
    extraLarge: {
      fontSize: '38px',
      fontWeight: '800',
    },
    large: {
      fontSize: '30px',
    },
    mediumText: {
      fontSize: '20px',
    },
    headingOne: {
      fontSize: '36px',
    },
    headingTwo: {
      fontSize: '28px',
    },
    headingThree: {
      fontSize: '25px',
    },
  },
})
const ThemeContextProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(
    JSON.parse(window.localStorage.getItem('user'))?.darkMode
  )
  console.log('isDarkMode', isDarkMode)


  return (
    <ThemeContext.Provider value={{isDarkMode,setIsDarkMode}}>
       <ThemeProvider theme={isDarkMode ? darkTheme : theme}>
                {children}
            </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider
