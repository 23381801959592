import './App.css'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import UserContextProvider from './Context/userContextProvider'
import Dashboard from './Components/Dashboard'
import WorkSpaceSocialPage from './Components/Dashboard/WorkSpaceSocialPage/workSpaceSocialPage'
import UserEditProfile from './Components/Dashboard/User/UserEditProfile'
import GooglService from './Components/Dashboard/PlatForms/Google'
import FacebookServices from './Components/Dashboard/PlatForms/Facebook'
import ArchivedSpacesSection from './Components/Dashboard/ArchivedSpacesSection'
import WorkspaceProfilePage from './Components/Dashboard/Workspace/WorkspaceProfilePage'
import ActiveSectionPage from './Components/Dashboard/PlatForms/Facebook/ActiveSection'
import ThemeApply from './Components/ThemeProvider'
import AllPlatForms from './Components/Dashboard/PlatForms/AllPaltForms'
import CreateRolePage from './Components/Dashboard/Workspace/WorkspaceProfilePage/WorkspaceRoleAndAdmin/createRolePage'
// import NewLandingPage from './Components/NewLandingPage'
import AllRolesTable from './Components/Dashboard/Workspace/WorkspaceProfilePage/WorkspaceRoleAndAdmin/allRolesTable'
import ManageRole from './Components/Dashboard/Workspace/WorkspaceProfilePage/WorkspaceRoleAndAdmin/manageRole'
import InviteLandingPage from './Components/InviteLandingPage/inviteLandingPage'
import PrivateRouter from './Components/Routes/Private Router'
import Instagram from './Components/Dashboard/PlatForms/Instagram'
import Pricing from './Components/Pricing'
import SigninPage from './Components/SigninPage'
import SignupPage from './Components/SignupPage'
import WelcomePage from './Components/WelcomePage'
import WorkspaceForm from './Components/NewWorkSpaceFoam'
import ChooseWorkspace from './Components/ChooseWorkspace'
import NewEditWorkspaceForm from './Components/Dashboard/Workspace/NewEditWorkspaceForm/newEditWorkspaceForm'
import PaymentSuccessfull from './Components/PaymentSuccessfull'
import ProfileView from './Components/Dashboard/User/NewUserProfile'
import Home from './Components/Dashboard/Home'
import Youtube from './Components/Dashboard/PlatForms/Youtube'
import DashboardMainSection from './Components/Dashboard/DashboardMainSection'
import InstagramPosts from './Components/Dashboard/PlatForms/Instagram/InstagramPosts'
import NewLandingPage from './Components/NewLandingPage/src'
import YoutubePost from './Components/Dashboard/PlatForms/Youtube/YoutubePost'
import ThemeContextProvider from './Components/ThemeProvider'

function App() {
  return (
    <ThemeContextProvider>
      <div className='App'>
        <BrowserRouter>
          <Routes>
            <Route element={<UserContextProvider />}>
              <Route path='/' element={<NewLandingPage />} />
              <Route path='/signin' element={<SigninPage />} />
              <Route path='/signup' element={<SignupPage />} />
              <Route path='/welcome' element={<WelcomePage />} />
              <Route element={<PrivateRouter />}>
                <Route element={<Dashboard />}>
                  <Route
                    path='/dashboard'
                    index
                    element={<DashboardMainSection />}
                  />
                  {/* <Route
                  path='/dashboard'
                  index
                  element={<DashboardMainSection />}
                /> */}

                  <Route
                    path='/dashboard/workspace'
                    element={<WorkspaceProfilePage />}
                  />
                  <Route path='/dashboard/home' element={<Home />} />
                  {/* <Route path='/dashboard/home/:id' element={<Chat />} /> */}

                  

                  <Route path='/dashboard/roles' element={<AllRolesTable />} />
                  <Route
                    path='/dashboard/Edit-role'
                    element={<Navigate to={'/dashboard/roles'} />}
                  />

                  <Route
                    path='/dashboard/create-role'
                    element={<CreateRolePage />}
                  />
                  <Route
                    path='/dashboard/edit-role/:roleId'
                    element={<CreateRolePage />}
                  />
                  <Route
                    path='/dashboard/manage-role'
                    element={<ManageRole />}
                  />
                  {/* <Route
                    path='/dashboard/userprofile'
                    element={<UserProfile />}
                  /> */}
                  <Route
                    path='/dashboard/userprofile'
                    element={<ProfileView />}
                  />
                  <Route
                    path='/dashboard/platforms'
                    element={<AllPlatForms />}
                  />
                  <Route
                    path='/dashboard/archived-spaces'
                    element={<ArchivedSpacesSection />}
                  />
                  <Route path='/dashboard/google' element={<GooglService />} />
                  <Route
                    path='/dashboard/facebook/:id'
                    element={<FacebookServices />}
                  />
                  <Route
                    path='/dashboard/facebook'
                    element={<Navigate to={'/dashboard/facebook/:id'} />}
                  />
                  <Route
                    path='/dashboard/facebook/posts/:id'
                    element={<ActiveSectionPage />}
                  />
                  <Route
                    path='/dashboard/instagram-profiles/:id'
                    element={<Instagram />}
                  />
                  <Route
                    path='/dashboard/instagram/:pageId/:igUserId/posts/:id'
                    element={<InstagramPosts />}
                  />
                  <Route
                    path='/dashboard/instagram'
                    element={
                      <Navigate to={'/dashboard/instagram-profiles/:id'} />
                    }
                  />
                   <Route
                    path='/dashboard/youtube'
                    element={
                      <Navigate to={'/dashboard/youtube-channels/:id'} />
                    }
                  />
                  <Route path='/dashboard/youtube-channels/:id' element={<Youtube />} />
                  <Route path='/dashboard/youtube/:channelId/:id' element={<YoutubePost />} />
                  <Route
                    path='/dashboard/workspace'
                    element={<WorkspaceProfilePage />}
                  />
                  <Route path='/dashboard/roles' element={<AllRolesTable />} />
                  <Route
                    path='/dashboard/Edit-role'
                    element={<Navigate to={'/dashboard/roles'} />}
                  />
                  <Route
                    path='/dashboard/create-role'
                    element={<CreateRolePage />}
                  />
                  <Route
                    path='/dashboard/edit-role/:roleId'
                    element={<CreateRolePage />}
                  />
                  <Route
                    path='/dashboard/manage-role'
                    element={<ManageRole />}
                  />
                  {/* <Route
                    path='/dashboard/userprofile'
                    element={<UserProfile />}
                  /> */}
                  <Route
                    path='/dashboard/platforms'
                    element={<AllPlatForms />}
                  />
                  <Route
                    path='/dashboard/archived-spaces'
                    element={<ArchivedSpacesSection />}
                  />
                  <Route path='/dashboard/google' element={<GooglService />} />
                  <Route
                    path='/dashboard/facebook/:id'
                    element={<FacebookServices />}
                  />
                  <Route
                    path='/dashboard/facebook'
                    element={<Navigate to={'/dashboard/facebook/:id'} />}
                  />
                  <Route
                    path='/dashboard/facebook/posts/:id'
                    element={<ActiveSectionPage />}
                  />
                </Route>

                {/* ******************************************************************** */}

                <Route
                  path='/dashboard/editprofile'
                  element={<UserEditProfile />}
                />
                <Route path='/invitation' element={<InviteLandingPage />} />
                <Route
                  path='/create-new-workspace'
                  element={<WorkspaceForm />}
                />
                {/* <Route path='edit-workspace' element={<EditWorkspaceFoam />} /> */}
                <Route
                  path='edit-workspace'
                  element={<NewEditWorkspaceForm />}
                />

                <Route
                  path='/social-connections'
                  element={<WorkSpaceSocialPage />}
                />
                <Route
                  path='/subscription-successfull'
                  element={<PaymentSuccessfull />}
                />
                <Route path='/choose-plan' element={<Pricing />} />
                <Route path='/choose-workspace' element={<ChooseWorkspace />} />
              </Route>
              {/* <Route path='/table' element={<CustomTable />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeContextProvider>
  )
}

export default App
