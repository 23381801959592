const style = {
  box: (theme) => ({
    // paddingLeft: "16px",
    color: theme.palette.text.main,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  link: (theme) => ({
    color: theme.palette.text.main,
    textDecoration: 'none',
    fontSize: theme.typography.mediumText.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    fontFamily: theme.typography.h2.fontFamily,
    lineHeight: '30px',
  }),
}

export default style
