import { useTheme } from '@emotion/react'
import { Menu, MenuItem } from '@mui/material'
import React from 'react'

const CustomMenu = ({
  anchorEl,
  open = false,
  onClose,
  color,
  menuItems = [],
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
}) => {
  const theme = useTheme()
  return (
    <Menu
      autoFocus={false}
      anchorEl={anchorEl}
      open={Boolean(open)}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={1}
      color={color}
      PaperProps={{
        sx: {
          marginTop: '5px',
          borderRadius: '12px',
          background: theme.palette.background.default || '#ffffff',
          padding:
            'var(--Overlays-Dropdowns-List-Spacing-Y, 0px) var(--Overlays-Dropdowns-List-Spacing-X, 8px) ',
          border: `1px solid ${theme?.palette?.gray?.light || '#d3d3d3'}`,
          boxShadow:
            ' 0px 10px 8px 0px rgba(0, 0, 0, 0.04), 0px 4px 3px 0px rgba(0, 0, 0, 0.10)',
        },
      }}
    >
      {menuItems.map((item, index) => (
        <MenuItem
          key={index}
          onClick={item.onClick}
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            lineHeight: '20px',
            letterSpacing: '0.07px',
            fontSize: theme?.typography?.menuItems?.fontSize || '',
            fontWeight: theme?.typography?.menuItems?.fontWeight || 'normal',
            color: item.color || theme?.palette?.black?.main,
            fontFamily: theme?.typography?.menuItems?.fontFamily,
            '&:hover': {
              backgroundColor: theme?.palette?.gray?.hover || '#f5f5f5',
              borderRadius:
                'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)',
            },
          }}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  )
}

export default CustomMenu
