import React, { useContext, useEffect, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { Circle, MoreVertRounded } from '@mui/icons-material'
import { useTheme } from '@emotion/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import UserContext from '../../../../../Context/userContext'
import {
  formatCount,
  formatYouTubeDuration,
  youtubeGetAllPostsApi,
} from '../../../../../API/PlatformsAPIs/YoutubeApi'
import { timeAgo } from '../../../../../API/PlatformsAPIs/InstagramApi'
import YoutubePostHeader from './youtubePostHeader'
import YoutubePostSkeleton from './youtubePostSkeleton'
import youtubeStyle from '../youtubeStyle'

const YoutubePost = () => {
  const { currentUser, userAuth, stateCheck } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { id, channelId } = useParams()
  const location = useLocation()
  const currentPath = location.pathname
  const basePath = currentPath.split('/').slice(0, 3).join('/')
  console.log('basePath', basePath)
  const [ytData, setYtData] = useState([])

  const getAllYtVideos = async (userAuth, spaceId, channelId) => {
    try {
      setLoading(true)
      const resp = await youtubeGetAllPostsApi(userAuth, spaceId, channelId)
      console.log('resp', resp)
      setYtData(resp)
    } catch (error) {
      console.log('error', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    const spaceId = window.sessionStorage.getItem('spaceId')
    if (id !== spaceId) {
      navigate(`${basePath}/${spaceId}`)
    }
    const fetch = async () => {
      if (userAuth) {
        getAllYtVideos(userAuth, spaceId, channelId)
      }
    }
    fetch()
  }, [])

  const theme = useTheme()
  return (
    <>
      <YoutubePostHeader />
      {loading && <YoutubePostSkeleton />}
      <Box sx={youtubeStyle.ytPostCardParentBox(theme)}>
        {ytData?.map((val, ind) => (
          <Box key={ind} sx={youtubeStyle.ytPostCard(theme, val)}>
            <Box sx={youtubeStyle.ytPostCardContentBox()}>
              <Box sx={youtubeStyle.ytPostCardContentTitleAndIconBtnBox(theme)}>
                <Typography
                  sx={youtubeStyle.ytPostCardContentTitleTypography(theme)}
                >
                  {val.title}
                </Typography>
                <IconButton>
                  <MoreVertRounded sx={{ color: '#FFF', fontSize: '24px' }} />
                </IconButton>
              </Box>
              <Box
                sx={youtubeStyle.ytPostCardContentEngagementsParentBox(theme)}
              >
                <Box
                  sx={youtubeStyle.ytPostCardContentEngagementsFirstChildBox(
                    theme
                  )}
                >
                  <Typography
                    sx={youtubeStyle.ytPostCardContentEngagementsFirstChildPostTime(
                      theme
                    )}
                  >
                    {timeAgo(val.publishedAt)}
                  </Typography>
                  <Circle
                    sx={youtubeStyle.ytPostCardContentEngagementsCircle(theme)}
                  />
                  <Typography
                    sx={youtubeStyle.ytPostCardContentEngagementsFirstChildViews(
                      theme
                    )}
                  >
                    views {formatCount(val.viewCount)}
                  </Typography>
                  <Circle
                    sx={youtubeStyle.ytPostCardContentEngagementsCircle(theme)}
                  />
                  <Typography
                    sx={youtubeStyle.ytPostCardContentEngagementsFirstChildLikes(
                      theme
                    )}
                  >
                    likes {formatCount(val.likeCount)}
                  </Typography>
                  <Circle
                    sx={youtubeStyle.ytPostCardContentEngagementsCircle(theme)}
                  />
                  <Typography
                    sx={youtubeStyle.ytPostCardContentEngagementsFirstChildComments(
                      theme
                    )}
                  >
                    comments {formatCount(val.commentCount)}
                  </Typography>
                </Box>
                <Box
                  sx={youtubeStyle.ytPostCardContentEngagementsSecondChild(
                    theme
                  )}
                >
                  <Typography
                    sx={youtubeStyle.ytPostCardContentEngagementsSecondChildVideoTime(
                      theme
                    )}
                  >
                    {formatYouTubeDuration(val.duration)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  )
}

export default YoutubePost
