import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import avatar1 from '../../../../Assets/Images/avatar1.png'
import avatar2 from '../../../../Assets/Images/avatar2.png'
import avatar3 from '../../../../Assets/Images/avatar3.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import 'swiper/css/bundle'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { YoutubeSvg } from '../../../PasswordStartAdornment/svgFile'
import youtubeStyle from './youtubeStyle'

const EmptyScreenYoutube = () => {
  const navigate = useNavigate()
  const slidesData = [
    {
      avatar: avatar1,
      message:
        'After using AI-scheduled posts, I saw my follower count grow by 25% in a month!',
      name: 'John Doe',
      profession: 'Fitness Influencer',
    },
    {
      avatar: avatar2,
      message: 'AI insights helped me increase engagement with minimal effort!',
      name: 'Jane Smith',
      profession: 'Content Creator',
    },
    {
      avatar: avatar3,
      message:
        'This tool saved me so much time scheduling posts and tracking metrics!',
      name: 'Emily Clark',
      profession: 'Entrepreneur',
    },
    {
      avatar: avatar2,
      message: 'AI insights helped me increase engagement with minimal effort!',
      name: 'Jane Smith',
      profession: 'Content Creator',
    },
  ]
  const theme = useTheme()
  return (
    <Box sx={youtubeStyle.parent_Box(theme)}>
      <Box sx={youtubeStyle.topSection_Box}>
        <YoutubeSvg />
        <Typography sx={youtubeStyle.headngText_Style(theme)}>
          Get started by linking your Youtube account.
        </Typography>
        <Typography sx={youtubeStyle.desc_Text_Style(theme)}>
          By linking your account, you'll be able to create, schedule, and
          enhance your posts with AI-driven insights!
        </Typography>
        <Button
          onClick={() => navigate('/social-connections')}
          sx={youtubeStyle.connectInsta_Button(theme)}
        >
          Connect Youtube
        </Button>
      </Box>
      <Box sx={youtubeStyle.bottomSection_Box}>
        <Typography sx={youtubeStyle.footer_HeadngText(theme)}>
          See how others are benefiting!
        </Typography>
        <Swiper
          spaceBetween={16}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          slidesPerView={3}
          modules={[Autoplay]}
          fadeEffect={{ crossFade: false }}
          className='fbSwiper'
          style={youtubeStyle.swiper_Style}
        >
          {slidesData.map((slide, index) => (
            <SwiperSlide
              key={index}
              style={youtubeStyle.swiperSlide_Style(theme)}
            >
              <img
                src={slide.avatar}
                alt={slide.name}
                style={youtubeStyle.img_Style}
              />
              <Typography
                sx={youtubeStyle.message_Text_Style(theme)}
              >
                {slide.message}
              </Typography>
              <span style={youtubeStyle.spanStyle}>
                <Typography sx={youtubeStyle.name_Text_Style(theme)}>
                  {slide.name}
                </Typography>
                -
                <Typography
                  sx={youtubeStyle.profession_Text_Style(theme)}
                >
                  {slide.profession}
                </Typography>
              </span>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default EmptyScreenYoutube
