import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useRef } from 'react'
import AddPicker from './addEmoji'
import { useTheme } from '@emotion/react'
import { captionEmojiAndOptions } from './captionEmojiStyle'
import { AiBlueIconSvg } from '../../../../../../PasswordStartAdornment/svgFile'

const CaptionEmojiAndOptions = ({
  setAiModalOpen,
  mediaType,
  caption,
  setCaption,
  loading,
}) => {
  const textRef = useRef(null)

  const handleEmojiClick = (emojiObject) => {
    console.log('emojiObject', emojiObject.emoji)
    if (emojiObject && emojiObject.emoji) {
      const cursor = textRef.current.selectionStart
      const text =
        caption.slice(0, cursor) + emojiObject.emoji + caption.slice(cursor)
      setCaption(text)
      // Update cursor position
      const newCursor = cursor + emojiObject.emoji.length
      setTimeout(
        () => textRef.current.setSelectionRange(newCursor, newCursor),
        0
      )
    }
  }
  const theme = useTheme()
  return (
    <Box sx={captionEmojiAndOptions.main_Box}>
      <Typography sx={captionEmojiAndOptions.desc_Text(theme)}>
        What do you want to write about?
      </Typography>
      <Box sx={captionEmojiAndOptions.textField_MainBox(theme)}>
        <Box sx={captionEmojiAndOptions.caption_Box}>
          <Typography sx={captionEmojiAndOptions.writeCaption_TextStyle(theme)}>
            Write a caption...
          </Typography>
          <Button
            disabled={loading}
            onClick={() => setAiModalOpen(true)}
            startIcon={<AiBlueIconSvg />}
            sx={captionEmojiAndOptions.generateButton(theme)}
          >
            Generate
          </Button>
        </Box>
        <TextField
          disabled={mediaType === 'story' || loading}
          value={caption}
          inputRef={textRef}
          onChange={(e) => setCaption(e.target.value)}
          variant='standard'
          fullWidth
          sx={captionEmojiAndOptions.textField_Style}
          InputProps={{
            rows: 4,
            multiline: true,
            inputComponent: 'textarea',
            disableUnderline: true,
          }}
          // {...register('aiPrompt', aiPromptValidator)}
        />
        <AddPicker
          caption={caption}
          handleEmojiClick={handleEmojiClick}
          mediaType={mediaType}
          loading={loading}
        />
      </Box>
    </Box>
  )
}

export default CaptionEmojiAndOptions
