import React from 'react'
import { Box } from '@mui/material'
import EmptyPage from './EmptyPage'

function Home() {
  return (
    <Box>
      <EmptyPage />
    </Box>
  )
}

export default Home
