import {
  Circle,
  MoreVertRounded,
  WarningAmberRounded,
} from '@mui/icons-material'
import { Box, Menu, MenuItem, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { instagramPost } from '../../instagramStyle'
import { useTheme } from '@emotion/react'
import { timeAgo } from '../../../../../../API/PlatformsAPIs/InstagramApi'
import CommomPostViewModal from '../../../../../ReusableComponents/CommomPostViewModal'
import CustomDialog from '../../../../../ReusableComponents/customDialoge'
import StoryCard from '../StoryCard'
import image from './../../../../../../Assets/Images/youtubeThumNail.png'
import { formatDate } from '../../../../../../API'
import UserContext from '../../../../../../Context/userContext'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'

const Postcard = ({
  val,
  anchorEl,
  open,
  handleClick,
  handleClose,
  postAndStoryOption,
  setInstaModal,
  filterBtns,
}) => {
  const {setPostData,setButtonStatus,buttonStatus,setScheduleTime,postData } = useContext(UserContext)
  // console.log('buttonStatus',buttonStatus)
  let items = []
  const postedItems = ['View', 'Repost', 'Cancel']
  const otherItems = ['View', 'Publish', 'Edit', 'Cancel']
  if (filterBtns === 'Posted') {
    items = postedItems
  }
  if (filterBtns !== 'Posted') {
    items = otherItems
  }
  const text = 'Delete'
  const theme = useTheme()
  const [openViewModel, setOpenViewModel] = useState(false)
  // console.log('val in card', val)
  const handleOpenViewModel = () => {
    handleClose()
    setOpenViewModel(true)
  }

  const handleCloseViewModel = () => {
    setOpenViewModel(false)
  }

  const clickAble = (e, obj) => {
    // console.log('first obj ', obj)
    setPostData(obj)
    handleClick(e)
  }

  const itemStatus = (item) => {
    if (item === 'View') {
      handleOpenViewModel()
    } else if (item === 'Repost') {
      setButtonStatus('Repost')
      handleClose()
      setInstaModal(true)
    } else if (item === 'Publish') {
      setButtonStatus('Publish')
      handleClose()
      setInstaModal(true)
    } else if (item === 'Edit') {
      setButtonStatus('Edit')
      setScheduleTime(postData?.scheduledAt)
      handleClose()
      setInstaModal(true)
    } else {
      handleClose()
    }
  }
  return (
    <>
      {val.contentType === 'post' ? (
        <Box
          sx={{
            backgroundImage:
              val.status === 'posted'
                ? `url(${val.thumbnailUrl})`
                : `url(${image})`,
            objectFit: 'cover',
            backgroundPosition: 'center',
            ...instagramPost.main,
          }}
        >
          <Box sx={instagramPost.cardDetail}>
            <Box sx={instagramPost.detailBox}>
              <Typography sx={instagramPost.postCaption(theme)}>
                {val.caption}
              </Typography>
              <MoreVertRounded
                sx={{
                  color: theme.palette.customWhite.main,
                  cursor: 'pointer',
                  fontSize: '20px',
                }}
                onClick={(e) => {
                  clickAble(e, val)
                }}
              />
            </Box>
            <Box sx={instagramPost.postDetail}>
              <Typography sx={instagramPost.postTypo(theme)}>
                {val.status === 'scheduled'
                  ? `Scheduled for ${formatDate(val.scheduledAt)}`
                  : timeAgo(val.createdAt)}
              </Typography>
              <Box
                sx={{
                  display:
                    val.status === 'scheduled'
                      ? 'none'
                      : val.status === 'drafted'
                      ? 'none'
                      : 'flex',
                  background: '',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Circle sx={{ fontSize: '6px' }} />
                <Typography sx={instagramPost.postTypo(theme)}>
                  Likes {val.engagements.likesCount}
                </Typography>
              </Box>
              <Box
                sx={{
                  display:
                    val.status === 'scheduled'
                      ? 'none'
                      : val.status === 'drafted'
                      ? 'none'
                      : 'flex',
                  background: '',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Circle sx={{ fontSize: '6px' }} />
                <Typography sx={instagramPost.postTypo(theme)}>
                  Comments {val.engagements.commentsCount}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <StoryCard
          val={val}
          handleClickRepost={handleClick}
          setInstaModal={setInstaModal}
          filterBtns={filterBtns}
        />
      )}

      {openViewModel && (
        <CommomPostViewModal
          open={openViewModel}
          onCancel={handleCloseViewModel}
          showReactions={true}
          showComments={true}
          postAndStoryOption={postAndStoryOption}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(open)}
        onClose={handleClose}
        PaperProps={{
          sx: instagramPost.menu_Style(theme),
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            sx={instagramPost.menuitem_Style(theme, text, item)}
            onClick={() => {
              itemStatus(item)
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default Postcard
