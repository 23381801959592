import { useTheme } from '@emotion/react'
import { Box, Button, MenuItem, Select, Typography } from '@mui/material'
import React, { useState } from 'react'

const AiTonesPack = ({ register, setTone, tone }) => {
  const [more, setMore] = useState(false)
  const [activeButton, setActiveButton] = useState('Friendly')
  const options = [
    'Friendly',
    'Informational',
    'Formal',
    'Casual',
    'Professional',
    'Creative',
    'Enthusiastic',
    'Story',
  ]
  const moreOptions = [
    'Movies',
    'Theator',
    'Drama',
    'Fantasy',
    'Cultural',
    'Festival',
  ]

  const chooseOptions = (options, state) => {
    console.log('options of tones', options)
    setMore(state)
    setTone(options)
  }
  const theme = useTheme()
  return (
    <Box
      sx={{
        background: '',
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        mt: '20px',
        gap: '5px',
      }}
    >
      <Typography
        sx={{
          background: '',
          fontSize: theme?.typography.h5.fontSize,
          fontWeight: theme?.typography.h1.fontWeight,
          fontFamily: theme?.typography.h2.fontFamily,
          fontStyle: 'normal',
          lineHeight: '20px',
        }}
      >
        Tone of voice
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
        {options.map((val, ind) => (
          <Button
            onClick={() => chooseOptions(val, false)}
            key={ind}
            sx={{
              padding: '0px',
              height: '35px',
              paddingX: '6px',
              borderRadius: '6px',
              border: `1px solid ${theme.palette.button.dark}`,
              backgroundColor: tone === val ? theme.palette.button.dark : '',
              color:
                tone === val
                  ? theme?.palette.customWhite.main
                  : theme.palette.button.dark,
              fontSize: theme?.typography.paragraph.fontSize,
              fontWeight: theme?.typography.h4.fontWeight,
              fontFamily: theme?.typography.h2.fontFamily,
              lineHeight: '16px',
              letterSpacing: '0.06px',
              textTransform: 'capitalize',
              ':hover': {
                backgroundColor: theme.palette.button.dark,
                color: theme?.palette.customWhite.main,
              },
            }}
          >
            {val}
          </Button>
        ))}

        <Select
          defaultValue='More'
          sx={{
            color: more
              ? theme?.palette.customWhite.main
              : theme.palette.button.dark,
            height: '35px',
            border: `1px solid ${theme.palette.button.dark}`,
            borderRadius: '6px',
            fontSize: '12px',
            backgroundColor: more ? theme.palette.button.dark : '',
            ':hover': {
              backgroundColor: theme.palette.button.dark,
              color: theme?.palette.customWhite.main,

              '.MuiSelect-icon': { color: 'white' },
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
            '.MuiSelect-icon': {
              color: more
                ? theme?.palette.customWhite.main
                : theme.palette.button.dark,
            },
          }}
        >
          <MenuItem
            sx={{
              color: theme.palette.button.dark,
              fontSize: theme?.typography.paragraph.fontSize,
              fontWeight: theme?.typography.h4.fontWeight,
              fontFamily: theme?.typography.h2.fontFamily,
              lineHeight: '16px',
              letterSpacing: '0.06px',
            }}
            value='More'
          >
            {'More'}
          </MenuItem>
          {moreOptions.map((val, ind) => (
            <MenuItem
              onClick={() => chooseOptions(val, true)}
              key={ind}
              sx={{
                color: theme.palette.button.dark,
                fontSize: theme?.typography.paragraph.fontSize,
                fontWeight: theme?.typography.h4.fontWeight,
                fontFamily: theme?.typography.h2.fontFamily,
                lineHeight: '16px',
                letterSpacing: '0.06px',
              }}
              value={val}
            >
              {val}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  )
}

export default AiTonesPack
