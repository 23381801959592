import {
  Box,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import appLogo from '../../Assets/Images/aiLatics-Logo.png'
import { useTheme } from '@emotion/react'
import image from './../../Assets/Images/signupImage.png'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import frameOne from './../../Assets/Images/frameOne.png'
import frameTwo from './../../Assets/Images/frameTwo.png'
import frameThree from './../../Assets/Images/frameThree.png'
import engments from './../../Assets/Images/engagments.png'
import totalSales from './../../Assets/Images/totalsales.png'
import growth from './../../Assets/Images/growth.png'
import testemonial from './../../Assets/Images/testemonial.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import signinPageStyles, { latics_Text_Style } from './signinPageStyles'
import SigninForm from './SigninForm/signinForm'
import { useNavigate } from 'react-router-dom'
const SigninPage = () => {
  const [showPassword, setShowPassword] = useState(false)
  const images = [engments, totalSales, growth, testemonial]
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, touchedFields },
  } = useForm()
  const onSubmit = (formData) => {
    console.log('Form Data:', formData)
  }

  const theme = useTheme()

  return (
    <Box sx={signinPageStyles.parent_Box(theme)}>
      <Box sx={signinPageStyles.main_Box}>
        <Box sx={signinPageStyles.formMainBox}>
          <Box onClick={() => navigate('/')} sx={signinPageStyles.logo_MainBox}>
            <img
              src={appLogo}
              alt='img'
              style={{ height: '33px', width: '33px' }}
            />
            <Typography sx={latics_Text_Style(theme)}>Latics</Typography>
          </Box>
          <SigninForm />
        </Box>
      </Box>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '14px',
          display: 'flex',
          justifyContent: 'center',
          objectFit: 'cover',
        }}
      >
        <Swiper
          direction='vertical'
          loop
          spaceBetween={400}
          speed={1500}
          autoplay={{ delay: 3500, disableOnInteraction: false }}
          modules={[Pagination, Navigation, Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 2.5,
              centeredSlides: true,
              slideToClickedSlide: true,
            },
            768: {
              slidesPerView: 2.5,
              centeredSlides: true,
              slideToClickedSlide: true,
            },
          }}
          className='mySwiper'
          style={signinPageStyles.swiper_Style}
        >
          {images.map((value, index) => (
            <SwiperSlide key={index} style={signinPageStyles.swiperSlide_Style}>
              <img
                src={value}
                alt=''
                style={signinPageStyles.swiperSlide_Img}
              ></img>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default SigninPage
