import { MoreVertRounded, WarningAmberRounded } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useTheme } from '@emotion/react'
import CommomPostViewModal from '../../../../../ReusableComponents/CommomPostViewModal'
import { timeAgo } from '../../../../../../API/PlatformsAPIs/InstagramApi'
import image from './../../../../../../Assets/Images/youtubeThumNail.png'
import UserContext from '../../../../../../Context/userContext'
import storyCardStyle from './storyCardStyle'


const StoryCard = ({ val,setInstaModal,filterBtns }) => {
  const { postData, setPostData,setButtonStatus,setScheduleTime } = useContext(UserContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  let items = []
  const postedItems = ['View', 'Repost', 'Cancel']
  const otherItems = ['View', 'Publish', 'Edit', 'Cancel']
  const [viewModel, setViewModel] = useState(false)
  if (filterBtns === 'Posted') {
    items = postedItems
  }
  if (filterBtns !== 'Posted') {
    items = otherItems
  }

  // console.log('val in card', val)
  const handleOpenModel = () => {
    setViewModel(true)
  }

  const handleCloseModel = () => {
    setViewModel(false)
  }
  const handleClick = (event, val) => {
    setPostData(val)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(false)
  }

  const handleMenuCLick = (item) => {
    if (item === 'View') {
      handleOpenModel()
    }
    if (item === 'Publish') {
      setButtonStatus('Publish')
      setInstaModal(true)
    }
    if (item === 'Repost') {
      setButtonStatus('Repost')
      setInstaModal(true)
    }
    if (item === 'Edit') {
      setButtonStatus('Edit')
      setScheduleTime(postData?.scheduledAt)
      setInstaModal(true)
    }

    handleClose()
  }

  // console.log('val data in storyCard', val)
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          ...storyCardStyle.parent_Box,
          backgroundImage:
            val.status === 'posted'
              ? `url(${val.thumbnailUrl})`
              : `url(${image})`,
        }}
      >
        <Box sx={storyCardStyle.main_Box}>
          <Box sx={storyCardStyle.iconBox}>
            <Typography sx={storyCardStyle.timeText_Style(theme)}>
              {timeAgo(val.createdAt)}
            </Typography>
            <IconButton onClick={(e) => handleClick(e, val)}>
              <MoreVertRounded
                sx={storyCardStyle.moreVertRounded_Style(theme)}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(open)}
        onClose={handleClose}
        PaperProps={{
          sx: storyCardStyle.menu_Style,
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            sx={storyCardStyle.menuItem_Style(item, theme)}
            onClick={() => handleMenuCLick(item)}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
      {viewModel && (
        <CommomPostViewModal
          open={viewModel}
          onCancel={handleCloseModel}
          val={val}
        />
      )}
      {/* <CustomDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmAction}
        dialogTitle='Delete Story'
        dialogDescription='Are you sure you want to delete this Story?'
        confirmButtonText='Delete'
        specialMessage={
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            Your
            <FavoriteIcon
              sx={{ color: 'red', height: '18px', width: '18px' }}
            />
            <span>
              <b>{val.engagements.likesCount}</b> likes and
            </span>
            <ChatBubbleOutlineIcon
              sx={{ color: 'blue', height: '18px', width: '18px' }}
            />
            <span>
              <b>{val.engagements.commentsCount}</b> comments in this post
            </span>
          </div>
        }
        icon={
          <WarningAmberRounded
            sx={{ color: 'red', height: '30px', width: '30px' }}
          />
        }
      /> */}
    </>
  )
}

export default StoryCard
