import { Menu, MenuItem } from '@mui/material'
import React from 'react'
import { useTheme } from '@emotion/react'

const ArchiveMenuOptions = ({ anchorEl, selectMenu, open, id }) => {
  const moveEditWorkspacePage = (unarchive) => {
    //   console.log('id of unarchive',id);
    selectMenu(unarchive)
  }
  const theme = useTheme()
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={selectMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      elevation={1}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          background: theme.palette.background.default,
          paddingY: { xs: '2px', sm: '4px' },
          paddingX: { xs: '8px', sm: '8px' },
          border: `1px solid ${theme.palette.gray.light}`,
          boxShadow:
            '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
        },
      }}
    >
      <MenuItem
        onClick={() => moveEditWorkspacePage('unarchive')}
        sx={{
          fontSize: theme.typography.menuItems.fontSize,
          fontWeight: theme?.typography.menuItems.fontWeight,
          color: theme?.palette.black.main,
          fontFamily: theme?.typography.menuItems.fontFamily,
          '&:hover': {
            backgroundColor: theme?.palette?.gray.hover,
            borderRadius:
              'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)',
          },
        }}
      >
        Unarchive
      </MenuItem>
    </Menu>
  )
}

export default ArchiveMenuOptions
