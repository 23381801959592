import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import { Pagination } from 'swiper/modules'
import { imageSwiperSectionStyle } from './imageSectionStyle'
import UserContext from '../../../../../../../Context/userContext'

const ImageSwiperSection = ({
  media,
  setMedia,
  setImageSrc,
  setEditimageIndex,
  setEditModal,
}) => {
  const [swiper, setSwiper] = useState(null)
  const { postData } = useContext(UserContext)

  const handleDelete = (index) => {
    setMedia((prevMedia) => prevMedia.filter((_, i) => i !== index))
  }
  const handleEdit = (file, ind) => {
    // console.log('edit file', file, 'index', ind)
    setImageSrc(file)
    setEditimageIndex(ind)
    setEditModal(true)
  }
  // console.warn('new med',media)
  return (
    <Box sx={imageSwiperSectionStyle.mainBox}>
      <Swiper
        spaceBetween={30}
        navigation={false}
        onSwiper={(swiperInstance) => setSwiper(swiperInstance)}
        slidesPerView={'auto'}
        grabCursor={true}
        modules={[Pagination]}
        className='fbSwiper'
        style={imageSwiperSectionStyle.swiperStyle}
      >
        {media.map((file, ind) => {
          const type = {}
          console.log('file', file)
          return (
            <SwiperSlide key={ind} style={{ width: 'auto' }}>
              <Box sx={imageSwiperSectionStyle.swiperSlide_Box}>
                {file.file.type.startsWith('video/') ? (
                  <video
                    controls
                    autoPlay={true}
                    loop={false}
                    style={imageSwiperSectionStyle.video_Style}
                    src={file.url}
                  >
                    {/* <source src={file.url} type={file.file.type} />
                Your browser does not support the video tag. */}
                  </video>
                ) : (
                  <img
                    src={file.url}
                    alt='Selected Media'
                    style={imageSwiperSectionStyle.image_Style}
                  />
                )}
                <Box
                  className='media-actions'
                  sx={imageSwiperSectionStyle.action_ButtonBox}
                >
                  {!file.file.type.startsWith('video/') && (
                    <IconButton
                      onClick={() => handleEdit(file?.url, ind)}
                      sx={imageSwiperSectionStyle.icon_Style}
                    >
                      <EditOutlined sx={{ height: '20px', width: '20px' }} />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => handleDelete(ind)}
                    sx={imageSwiperSectionStyle.icon_Style}
                  >
                    <DeleteOutline sx={{ height: '20px', width: '20px' }} />
                  </IconButton>
                </Box>
              </Box>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Box>
  )
}

export default ImageSwiperSection
