const newEditWorkspaceStyle = {
  mainBoxOfTextfield: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '4px',
    background: '',
  },
  helperText: {
    position: 'absolute',
    bottom: '-20px',
    color: 'red',
  },
  closeRoundedIcon_Box: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.10)',
    borderRadius: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '5%',
    right: '25%',
  },
  fromStyle: {
    width: '784px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 'var(--Numbers-Number-32, 64px)',
    background: '',
    padding: '16px',
    boxSizing: 'border-box',
  },
  heading_Box: {
    width: '752px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '',
  },
  avatar_Box: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    height: '250px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '14px',
  },
  field_MainBox: {
    width: '346px',
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--Numbers-Number-8, 16px)',
    marginTop: '0px',
    background: '',
  },
  iconButton_Box: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.10)',
    borderRadius: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '5%',
  },
  buttonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: '8px' },
    mr: { sm: '14px', lg: '12px' },
  },
  circularProgressBox: {
    position: 'fixed',
    color: '',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },
}
export default newEditWorkspaceStyle
export const pareantBox = (theme) => ({
  width: '100vw',
  background: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  minHeight: '100vh',
  overflowY: 'hidden',
})

export const labelTextfield = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  color: 'theme.palette.black.main',
  width: 'auto',
  marginRight: 'auto',
  lineHeight: '20px',
})
export const commonTextFieldStyle = (error) => ({
  background: '',
  display: 'flex',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    gap: '12px',
    '&:hover fieldset': {
      borderColor: '#C2C2C2',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'red' : '#241BFF',
    },
    '& fieldset': {
      borderColor: '#C2C2C2',
    },
    '& .MuiOutlinedInput-input': {
      height: '20px',

      padding: '12px 16px',
      color: '#000',
      fontSize: '16px',
    },
  },
})
export const ContinueButton = (theme) => ({
  width: '100%',
  height: '44px',
  boxSizing: 'border-box',
  padding: '12px 16px',
  fontFamily: theme.typography.h2.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  lineHeight: '24px',
  textTransform: 'capitalize',
  borderRadius: '14px',
  backgroundColor: theme.palette.button.dark,
  marginTop: '2px',
})
export const descriptionTextFieldStyle = (error) => ({
  background: '',
  // height:'90px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    gap: '12px',
    '&:hover fieldset': {
      borderColor: '#C6C6CE',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'red' : '#241BFF',
    },
    '& fieldset': {
      borderColor: '#C6C6CE',
    },
    '& .MuiOutlinedInput-input': {
      height: { xs: '15px', sm: '24px', md: '70px' },
      color: '',
    },
  },
})
export const commonSelectStyle = (error) => ({
  background: '',
  borderRadius: '14px',
  height: '44px',
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#C6C6CE',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: error ? 'red' : '#241BFF',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#C6C6CE',
  },
  '& .MuiSelect-select': {
    // height: "15px",
    color: '', // Ensure the color is set correctly or remove it
  },
  '& .MuiOutlinedInput-input': {
    // height: '20px' ,
    padding: '12px 16px',
    color: '#000',
    fontSize: '16px',
  },
})
export const image = (theme) => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  border: `2px solid ${theme.palette.sky.light}`,
  borderRadius: '14px',
})
export const heading_Text = (theme) => ({
  fontWeight: theme.typography.menualText.fontWeight,
  fontSize: {
    xs: theme.typography.h2.fontSize,
    sm: theme.typography.menualText.fontSize,
  },
  color: theme.palette.black.main,
  fontFamily: theme.typography.h1.fontFamily,
  lineHeight: '40px',
})
export const paragraph_Text = (theme) => ({
  color: theme.palette.black.light,
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h4.fontWeight,
  width: { xs: '100%', sm: '80%' },
  textAlign: 'center',
  fontSize: {
    xs: theme.typography.h5.fontSize,
    sm: theme.typography.h3.fontSize,
  },
  lineHeight: 'normal',
  letterSpacing: '-0.16px',
})
export const image_MainBox = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '14px',
  justifyContent: 'center',
  alignItems: 'center',
  background: '',
})
export const label_Style = (theme, selectedImage) => ({
  backgroundColor: selectedImage
    ? 'transparent'
    : theme.palette.customWhite.light,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '14px',
  cursor: 'pointer',
})
export const add_ImageText = (theme) => ({
  fontWeight: theme.typography.h5.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  color: theme.palette.black.light,
  fontSize: theme.typography.h5.fontSize,
  lineHeight: '24px',
})
export const avatar_Box = () => {}
export const saveChangeButton = (theme) => ({
  height: '44px',
  boxSizing: 'border-box',
  padding: '12px 16px',
  background: theme.palette.button.dark,
  color: theme.palette.customWhite.main,
  borderRadius: '14px',
  fontWeight: theme.typography.h3.fontWeight,
  lineHeight: '20px',
  fontSize: theme.typography.menuItems.fontSize,
  fontFamily: theme.typography.h1.fontFamily,
  textTransform: 'capitalize',
})
export const cancelChangeButton = (theme) => ({
  height: '44px',
  boxSizing: 'border-box',
  padding: '12px 16px',
  background: theme.palette.background.default,
  color: theme.palette.error.main,
  borderColor: theme.palette.error.main,
  borderRadius: '14px',
  fontWeight: theme.typography.h3.fontWeight,
  lineHeight: '20px',
  fontSize: theme.typography.menuItems.fontSize,
  fontFamily: theme.typography.h1.fontFamily,
  textTransform: 'capitalize',
})
