import { Box, Skeleton } from '@mui/material'
import React from 'react'

const YoutubePostSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '16px',
        ml:'20px',
        mt:'20px',
      }}
    >
      {Array.from({ length: 2 }, (_, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            width: '428px',
            height: '240px',
            boxSizing: 'border-box',
            padding: '4px',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            gap: '8px',
            cursor: 'pointer',
            border: '1px solid #DADAFF',
            borderRadius: '18px',
            backgroundColor: '#F8F9FF',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              boxSizing: 'border-box',
              padding: '8px',
              flexDirection: 'column',
              gap: '8px',
              borderRadius: '16px',
              border: '1px solid #DADAFF',
              width:'100%',
              height:'88px',
              justifyContent:'start'
            }}
          >
            <Skeleton
              variant='text'
              width='100%'
              animation={'wave'}
              sx={{height:'40px'}}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width:'100%',
                height:'40px',
                boxSizing: 'border-box',
              padding: '0px',
              }}
            >
              <Skeleton
                variant='text'
                width='70%'
                animation={'wave'}
              />
              <Skeleton
                variant='text'
                width='20%'
                animation={'wave'}
              />
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default YoutubePostSkeleton
