import { Box, Divider, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { sidebarMainBox, upperDivider } from './sidebarStyle'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAllSpacesAPI } from '../../API'
import UserContext from '../../Context/userContext'
import SideBarHeader from './sideBarHeader'
import ActiveSpaceSection from './ActiveSpace'
import ActiveSpaceMenu from './ActiveSpace/activeSpaceMenu'
import SideBarFooter from './SideBarFooter'
import SideBarAllWorkSpaces from './sideBarAllWorkSpaces'
import ArchivedSpaces from './archivedSpaces'
import SidebarPlatformMenu from './SidebarPlatforms/sidebarPlatformMenu'
import SidebarPlatforms from './SidebarPlatforms'
import { useTheme } from '@emotion/react'
import SidebarlinerProgress from './sidebarlinerProgress'
import fb from './../../Assets/Images/facebook.png'
import instagram from './../../Assets/Images/instagram.png'
import youtube from './../../Assets/Images/youtube.png'
import PlatformsAccordion from './platformsAccordion'
import SideBarHome from './SidebarHome'

const Sidebar = ({ handlemoreCreditsAndSeatsPopup }) => {
  const {
    userAuth,
    currentUser,
    favourite,
    stateCheck,
    setStateCheck,
    activeSidebarOption,
    setActiveSidebarOption,
  } = useContext(UserContext)
  const [drawer, setDrawer] = useState(true)
  const [activeButton, setActiveButton] = useState('all')
  const [spaces, setSpaces] = useState([])
  const [allSpaces, setAllSpaces] = useState([])
  const [platformAnchorEl, setPlatformAnchorEl] = useState(null)
  const [spaceAnchorEl, setSpaceAnchorEl] = useState(null)
  const location = useLocation()
  const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  console.log('currentUser in side bar', currentUser)
  const sessId = window.sessionStorage.getItem('spaceId')
  const platformMenuOpen = (event) => {
    setPlatformAnchorEl(event.currentTarget)
  }

  const platformMenuClose = () => {
    setPlatformAnchorEl(false)
  }

  const spaceMenuOpen = (event) => {
    setSpaceAnchorEl(event.currentTarget)
  }

  const spaceMenuClose = () => {
    setSpaceAnchorEl(false)
  }

  const pageNavigate = (url) => {
    navigate(url)
  }

  const platforms = [
    // { name: 'google', src: googleImage },
    { name: 'facebook', src: fb },
    { name: 'instagram', src: instagram },
    { name: 'youtube', src: youtube },
  ]

  useEffect(() => {
    const url = location.pathname
    setActiveSidebarOption(url)
    const fetchData = async () => {
      if (userAuth && currentUser) {
        const respOfGetAllSpacesInSidebar = await getAllSpacesAPI(
          userAuth,
          currentUser
        )
        console.log('respOfGetAllSpacesInSidebar', respOfGetAllSpacesInSidebar)
        setAllSpaces(respOfGetAllSpacesInSidebar)
        setSpaces(respOfGetAllSpacesInSidebar)
      }
      setActiveButton('all')
    }

    fetchData()
    setDrawer(isLg)
  }, [userAuth, favourite, currentUser, stateCheck, activeSidebarOption, isLg])

  const navigate = useNavigate()

  const createNewWorkspace = () => {
    navigate('/create-new-workspace')
  }

  const sideBarState = () => {
    setDrawer(!drawer)
    // func(drawer)
  }

  const getAllSpaces = async (val) => {
    setActiveButton(val)

    if (userAuth) {
      setSpaces(allSpaces)
      return allSpaces
    } else {
      console.error('userAuth is undefined')
      return null
    }
  }

  const navigation = (url) => {
    navigate(url)
  }

  const getFavoriteSpaces = (val) => {
    setActiveButton(val)
    const fv = allSpaces.filter((space) => space.isFavourite === true)
    setSpaces(fv)
  }
  const theme = useTheme()
  return (
    <Box className='custom-scrollbar' sx={sidebarMainBox(drawer, theme)}>
      <SideBarHeader drawer={drawer} sideBarState={sideBarState} />
      <ActiveSpaceSection
        drawer={drawer}
        spaceMenuOpen={spaceMenuOpen}
        activeSpace={allSpaces.filter((val) => val._id === sessId)}
      />
      <ActiveSpaceMenu
        drawer={drawer}
        spaceMenuClose={spaceMenuClose}
        navigation={navigation}
        activeButton={activeButton}
        getAllSpaces={getAllSpaces}
        getFavoriteSpaces={getFavoriteSpaces}
        createNewWorkspace={createNewWorkspace}
        spaces={spaces}
        spaceAnchorEl={spaceAnchorEl}
      />
      <Divider sx={upperDivider()} />
      <SideBarHome drawer={drawer} navigation={navigation} />
      <SideBarAllWorkSpaces drawer={drawer} navigation={navigation} />
      <ArchivedSpaces drawer={drawer} navigation={navigation} />
      {drawer && isLg ? (
        <PlatformsAccordion
          drawer={drawer}
          allSpaces={allSpaces}
          navigation={navigation}
          sessId={sessId}
          platformMenuClose={platformMenuClose}
          platformAnchorEl={platformAnchorEl}
          platformMenuOpen={platformMenuOpen}
          platforms={platforms}
          activeSpace={allSpaces.filter((val) => val._id === sessId)}
        />
      ) : (
        <>
          <SidebarPlatforms
            allSpaces={allSpaces}
            drawer={drawer}
            platformMenuOpen={platformMenuOpen}
            navigation={navigation}
          />
          <SidebarPlatformMenu
            drawer={drawer}
            platformAnchorEl={platformAnchorEl}
            platformMenuClose={platformMenuClose}
            platforms={platforms}
            navigation={navigation}
            sessId={sessId}
          />
        </>
      )}
      {/* <Divider sx={dividerLower(drawer)} /> */}
      {/* <SideBarNotification drawer={drawer} /> */}
      <SidebarlinerProgress drawer={drawer} currentUser={currentUser} />
      <SideBarFooter
        drawer={drawer}
        handlemoreCreditsAndSeatsPopup={handlemoreCreditsAndSeatsPopup}
      />
    </Box>
  )
}

export default Sidebar
