import { Box, Menu, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import {
  menuLogo,
  menuLogoBox,
  menuTypography,
  platformMenu,
  platformMenuMap,
} from '../sidebarStyle'
import { useTheme } from '@emotion/react'
import UserContext from '../../../Context/userContext'

const SidebarPlatformMenu = ({
  drawer,
  navigation,
  platformAnchorEl,
  platformMenuClose,
  platforms,
  sessId,
}) => {
  const { activeSidebarOption, setActiveSidebarOption } =
    useContext(UserContext)
  const [checkName, setCheckName] = useState()
  console.log('sessId in platform', sessId)

  const handlePlatforms = (index, name) => {
    setCheckName(name)
    if (name === 'google') {
      setActiveSidebarOption('/dashboard/google')
      navigation('/dashboard/google')
    }
    if (name === 'facebook') {
      setActiveSidebarOption(`/dashboard/facebook/${sessId}`)
      navigation(`/dashboard/facebook/${sessId}`)
    }
    if (name === 'instagram') {
      setActiveSidebarOption(`/dashboard/instagram-profiles/${sessId}`)
      navigation(`/dashboard/instagram-profiles/${sessId}`)
    }
    if (name === 'youtube') {
      setActiveSidebarOption(`/dashboard/youtube-channels/${sessId}`)
      navigation(`/dashboard/youtube-channels/${sessId}`)
    }
  }

  // console.log('platforms', platforms)
  const theme = useTheme()
  return (
    <Menu
      id='basic-menu'
      anchorEl={platformAnchorEl}
      open={Boolean(platformAnchorEl)}
      onClose={platformMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      elevation={1}
      PaperProps={{
        sx: platformMenu(drawer),
      }}
    >
      {platforms?.map((val, index) => (
        <Box
          key={index}
          onClick={() => handlePlatforms(index, val.name)}
          sx={platformMenuMap(theme, activeSidebarOption, val.name)}
        >
          <Box sx={menuLogoBox(drawer)}>
            <img src={val.src} alt={`platform-logo`} style={menuLogo(drawer)} />
          </Box>
          <Typography sx={menuTypography(drawer, theme)}>{val.name}</Typography>
        </Box>
      ))}

      {/* <Button
        onClick={() => navigation('/dashboard/platforms')}
        variant='text'
        sx={seeAllBtn(drawer)}
      >
        See all Platforms
      </Button> */}
    </Menu>
  )
}

export default SidebarPlatformMenu
