import { Box } from '@mui/material'
import { Modal } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import UserContext from '../../../../../Context/userContext'
import AiPostModal from '../../../../ReusableComponents/ReusableAiPostModal/aiPostModal'
import ManualPostForm from './ManualPostForm'
import { dataType64toFile } from '../../../../../API/PlatformsAPIs/Facebook'
import instaFormSyle from './ManualPostForm/instaFormStyle'
import { hostedLinksToFiles } from '../../../../../API/PlatformsAPIs/InstagramApi'

const InstagramPostModal = ({ instaModal, handleClose, getInstaPosts,filterBtns,setFilterBtns }) => {
  const { aiModalOpen, postData } = useContext(UserContext)
  // console.log('postData in instaModal', postData)
  const [media, setMedia] = useState([])
  const [caption, setCaption] = useState('')
  const textRef = useRef(null)
  // console.log('media ', media)
  const [loading, setLoading] = useState(false)

  const handleInsert = (data) => {
    const cursor = textRef?.current?.selectionStart || 0
    const updatedText =
      caption.slice(0, cursor) + data.text + caption.slice(cursor)
    setCaption(updatedText)

    const newCursor = cursor + (data.text?.length || 0)
    setTimeout(() => {
      textRef?.current?.setSelectionRange(newCursor, newCursor)
    }, 0)

    if (data.image) {
      const res = dataType64toFile(data.image)
      const fileUrl = URL.createObjectURL(res)
      setMedia((prevMedia) => [...prevMedia, { file: res, url: fileUrl }])
    }
  }

  useEffect(() => {
    const fetchMedia = async () => {
      if (postData && postData.mediaItems) {
        setCaption(postData.caption || '')
        const mediaFiles = await Promise.all(
          postData.mediaItems.map(async (item) => {
            const result = await hostedLinksToFiles(item.mediaUrl)
            return result // Add error handling if needed
          })
        )

        setMedia(mediaFiles.filter((media) => media !== null)) // Filter out any null results
      }
    }

    fetchMedia()
  }, [postData])

  return (
    <Modal
      open={instaModal}
      onCancel={handleClose}
      footer={null}
      centered
      width='fit-content'
      maskClosable={false}
      closable={false}
      style={{ backgroundColor: '' }}
    >
      <Box sx={instaFormSyle.instagramModalsParentBox}>
        {aiModalOpen && <AiPostModal handleInsert={handleInsert} />}
        <ManualPostForm
          handleClose={handleClose}
          loading={loading}
          setLoading={setLoading}
          media={media}
          setMedia={setMedia}
          caption={caption}
          setCaption={setCaption}
          getInstaPosts={getInstaPosts}
          filterBtns={filterBtns}
          setFilterBtns={setFilterBtns}
        />
      </Box>
    </Modal>
  )
}

export default InstagramPostModal
