import { IconButton, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import allPlatformsStyles from './allPlatformsStyle'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

const PlatformMenu = ({ icon, index }) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleServices = () => {
    console.log('index of services', index)
    if (index === 0) {
      navigate('/dashboard/all-platforms/google-services')
    }
    if (index === 1) {
      navigate('/dashboard/all-platforms/facebook-services')
    }
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const theme = useTheme()
  return (
    <>
      {' '}
      <IconButton onClick={handleClick} sx={{ height: '28px', width: '28px' }}>
        {icon}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        PaperProps={{
          sx: allPlatformsStyles.MenuTag_Parent(theme),
        }}
      >
        <MenuItem
          sx={allPlatformsStyles.menuItem_services_disconnect(theme)}
          onClick={handleClose}
        >
          Disconnect
        </MenuItem>
        <MenuItem
          sx={allPlatformsStyles.menuItem_services_disconnect(theme)}
          onClick={() => handleServices()}
        >
          Services
        </MenuItem>
      </Menu>
    </>
  )
}

export default PlatformMenu
