const profileStyle = {
  parent_Box: (theme) => ({
    borderRadius: '24px',
    border: `1px solid ${theme.palette.gray.light}`,
    display: 'flex',
    width: '100%',
    maxWidth: '750px',
    height: 'fit-content',
    boxSizing: 'border-box',
    padding: '24px',
    justifyContent: 'space-between',
    alignItems: 'start',
    background: theme.palette.background.default,
  }),
  main_Box: {
    display: 'flex',
    alignItems: 'start',
    gap: '32px',
    width: '100%',
  },
  image_Box: {
    width: '200px',
    height: '200px',
    borderRadius: '200px',
  },

  firstWord_Box: (theme) => ({
    width: '200px',
    height: '200px',
    borderRadius: '200px',
    background: 'purple',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.customWhite.main,
    fontSize: '50px',
  }),
  infoormation_MainBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex',
    gap: '16px',
  },
  userName_Box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  userName_TextStyle: (theme) => ({
    fontSize: theme.typography.large.fontSize,
    color: theme?.palette.black.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    letterSpacing: '-1.2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  category_TextStyle: (theme) => ({
    color: theme.palette.black.main,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    lineHeight: '24px',
  }),
  middle_Box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '12px',
  },
  boxStyle: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  textStyle: (theme) => ({
    color: theme.palette.black.main,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    lineHeight: '24px',
  }),
  email_TextStyle: (theme) => ({
    color: theme.palette.black.main,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    lineHeight: '24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
}
export default profileStyle
