const userEditProfileStyle = {
  loaderBox: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },

  editUser_Form: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    boxSizing: 'border-box',
    background: '',
  },
  main_Box_Of_Textfield: {
    display: 'flex',
    flexDirection: 'column',
    width: { xs: '100%', sm: '47%', md: '48%' },
    gap: { sm: '10px' },
    boxSizing: 'border-box',
  },

  email_Box: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: { sm: '4px' },
  },

  helperText: {
    position: 'absolute',
    bottom: '-20px',
    color: 'red',
  },
  button_Box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    mt: '10px',
    mr: { sm: '14px', lg: '12px' },
  },
}
export default userEditProfileStyle

export const parentBox = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  minHeight: '100vh',
  gap: { xs: '20px', sm: '20px', md: '30px', xl: '30px' },
  fontFamily: 'Montserrat',
  padding: { xs: '5px', sm: '10px' },
  position: 'relative',
  boxSizing: 'border-box',
  background: theme.palette.background.default,
})
export const userFirstWord_Text = (theme) => ({
  width: '100%',
  height: '100%',
  border: `2px solid ${theme.palette.sky.light}`,
  borderRadius: '14px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'purple',
  color: theme.palette.customWhite.main,
  fontSize: '50px',
})

export const main_Box_Of_companyTextfield = (currentUser) => ({
  display: currentUser?.accountType === 'user' ? 'flex' : 'none',
  flexDirection: 'column',
  width: { xs: '100%', sm: '47%', md: '48%' },
  gap: { sm: '10px' },
  boxSizing: 'border-box',
})
export const main_Box_Of_phoneTextfield = (currentUser) => ({
  display: 'flex',
  flexDirection: 'column',
  width: {
    xs: '100%',
    sm: '47%',
    md: currentUser?.accountType === 'user' ? '48%' : '100%',
  },
  gap: { sm: '10px' },
  boxSizing: 'border-box',
})
export const labelOfTextfield = (theme) => ({
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  color: theme.palette.black.main,
  width: 'auto',
  marginRight: 'auto',
})
export const disable_GenrateButton = (theme) => ({
  height: '38px',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: '9px var(--Components-Button-Spacing-X-SM, 12px)',
  fontSize: theme?.typography.menuItems.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: 'linear-gradient(90deg, #4950FF 0%, #6268FD 52%)',
  fontStyle: 'normal',
  lineHeight: '20px',
  width: 'fit-content',
  textTransform: 'capitalize',
  color: theme.palette.customWhite.main,
  // '&.Mui-disabled': {
  //   color: 'white', // Text color for disabled state
  // },
  ':hover': {
    background: 'linear-gradient(90deg, #4950FF 0%, #6268FD 52%)',
  },
  '&.Mui-disabled': {
    background: 'linear-gradient(90deg, #4950FF 0%, #6268FD 52%)',
    color: theme?.palette.customWhite.main,
    opacity: 1,
  },
})

export const saveChangeButton = (theme) => ({
  height: '44px',
  boxSizing: 'border-box',
  padding: '12px 16px',
  background: theme.palette.button.dark,
  textTransform: 'capitalize',
  color: theme.palette.customWhite.main,
  borderRadius: '14px',
  fontWeight: theme.typography.h3.fontWeight,
  lineHeight: '20px',
  fontSize: theme.typography.menuItems.fontSize,
  fontFamily: theme.typography.h1.fontFamily,
})
export const cancelChangeButton = (theme) => ({
  height: '44px',
  boxSizing: 'border-box',
  padding: '12px 16px',
  background: theme.palette.background.default,
  textTransform: 'capitalize',
  color: theme.palette.error.main,
  borderColor: theme.palette.error.main,
  borderRadius: '14px',
  fontWeight: theme.typography.h3.fontWeight,
  lineHeight: '20px',
  fontSize: theme.typography.menuItems.fontSize,
  fontFamily: theme.typography.h1.fontFamily,
})
export const editButton = (theme) => ({
  padding: '0px',
  paddingX: '4px',
  textTransform: 'capitalize',
  color: 'white',
  background: theme.palette.button.dark,
  '&:hover': {
    background: theme.palette.button.dark,
    transition: 'background-color 0.2s ease-in-out',
    backgroundColor: theme.palette.button.dark,
    color: 'white',
  },
})
export const commonTextFieldStyle = (error) => ({
  background: '',
  display: 'flex',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    gap: '12px',
    '&:hover fieldset': {
      borderColor: '#C2C2C2',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'red' : '#241BFF',
    },
    '& fieldset': {
      borderColor: '#C2C2C2',
    },
    '& .MuiOutlinedInput-input': {
      height: '20px',
      padding: '12px 16px',
      color: '#000',
      fontSize: '16px',
    },
  },
})

export const emailFieldStyles = (error) => ({
  background: '#F9F9FF',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',

    gap: '12px',
    '&:hover fieldset': {
      borderColor: '#C6C6CE',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'red' : '#241BFF',
    },
    '& fieldset': {
      borderColor: '#C6C6CE',
    },
    '& .MuiOutlinedInput-input': {
      height: { xs: '15px', sm: '24px', md: '20px' },
    },
  },
})

export const commonSelectStyle = (error) => ({
  background: '',
  borderRadius: '14px',
  height: '44px',
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#C6C6CE',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: error ? 'red' : '#241BFF',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#C6C6CE',
  },
  '& .MuiSelect-select': {
    // height: "15px",
    color: '', // Ensure the color is set correctly or remove it
  },
  '& .MuiOutlinedInput-input': {
    // height: '20px' ,

    padding: '12px 16px',
    color: '#000',
    fontSize: '16px',
  },
})
export const descriptionTextFieldStyle = (error) => ({
  background: '',
  // height:'90px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    gap: '12px',
    '&:hover fieldset': {
      borderColor: '#C6C6CE',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'red' : '#241BFF',
    },
    '& fieldset': {
      borderColor: '#C6C6CE',
    },
    '& .MuiOutlinedInput-input': {
      height: { xs: '15px', sm: '24px', md: '70px' },
      color: '',
    },
  },
})
