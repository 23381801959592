import makeHeader from "../../Headers"

export function formatCount(count) {
  if (count >= 1000000) {
    return (count / 1000000).toFixed(count % 1000000 === 0 ? 0 : 1) + 'M' // For millions
  } else if (count >= 1000) {
    return (count / 1000).toFixed(count % 1000 === 0 ? 0 : 1) + 'K' // For thousands
  } else {
    return count?.toString() // For values less than 1000
  }
}

export function formatYouTubeDuration(duration) {
  // Parse the duration using a regular expression
  const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
  const matches = duration.match(regex);

  const hours = parseInt(matches[1] || 0, 10);
  const minutes = parseInt(matches[2] || 0, 10);
  const seconds = parseInt(matches[3] || 0, 10);

  // Format the duration as YouTube-like style
  if (hours > 0) {
    return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  } else if (minutes > 0) {
    return `${minutes}:${String(seconds).padStart(2, '0')}`;
  } else {
    return `${seconds}s`;
  }
}

export const getYoutubeChannelApi = async (
    userAuth,
    workspaceId,
  ) => {
    console.log('workspaceId', workspaceId)
  
    try {
      const idToken = await userAuth.getIdToken(true)
      const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/youtube/channels?workspaceId=${workspaceId}`
  
      const response = await fetch(apiUrl, makeHeader('GET', idToken))
      const responseData = await response.json()
  
      return responseData 
    } catch (error) {
      console.error('Error in getYoutubeChannelApi:', error)
      throw error
    }
  }

export const youtubeGetAllPostsApi = async (
    userAuth,
    workspaceId,
    channelId,
  ) => {
    console.log('workspaceId', workspaceId, 'channelId', channelId)
  
    try {
      const idToken = await userAuth.getIdToken(true)
      const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/youtube/videos?channelId=${channelId}&workspaceId=${workspaceId}`
  
      const response = await fetch(apiUrl, makeHeader('GET', idToken))
      const responseData = await response.json()
  
      return responseData 
    } catch (error) {
      console.error('Error in youtubeGetAllPostsApi:', error)
      throw error
    }
  }