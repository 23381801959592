import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import facebook from '../../../../../Assets/Images/facebook.png'
import instagram from '../../../../../Assets/Images/instagram.png'
import linkedin from '../../../../../Assets/Images/linkedin.png'
import x from '../../../../../Assets/Images/x.png'

import { useTheme } from '@emotion/react'
import { emptyScrButtonStyle, homePage } from '../../homePageStyle'

const CreatePostButton = ({ setPrompt }) => {
  const theme = useTheme()
  const buttonData = [
    {
      src: facebook,
      label: 'Create Post for Facebook',
      prompt: 'Create a post for Facebook on ',
    },
    {
      src: instagram,
      label: 'Create Post for Instagram',
      prompt: 'Create a post for Instagram on ',
    },
    {
      src: x,
      label: 'Create Post for Twitter',
      prompt: 'Create a post for Twitter on ',
    },
    {
      src: linkedin,
      label: 'Create Post for LinkedIn',
      prompt: 'Create a post for LinkedIn on ',
    },
  ]

  const [showMore, setShowMore] = useState(false)

  const handleMoreClick = () => {
    setShowMore(!showMore)
  }

  const handleClick = (label) => {
    setPrompt(label)
  }

  return (
    <Box sx={homePage.emptyScrButtonSection}>
      {buttonData.slice(0, 2).map((button, index) => (
        <Button
          key={index}
          sx={emptyScrButtonStyle(theme)}
          onClick={() => handleClick(button.prompt)}
        >
          <img
            src={button.src}
            width='20px'
            height='20px'
            alt={button.prompt}
          />
          {button.label}
        </Button>
      ))}
      {!showMore ? (
        <Button onClick={handleMoreClick} sx={emptyScrButtonStyle(theme)}>
          More
        </Button>
      ) : (
        buttonData.slice(2).map((button, index) => (
          <Button
            key={index + 2}
            sx={emptyScrButtonStyle(theme)}
            onClick={() => handleClick(button.prompt)}
          >
            <img
              src={button.src}
              width='20px'
              height='20px'
              alt={button.label}
            />
            {button.label}
          </Button>
        ))
      )}
    </Box>
  )
}

export default CreatePostButton
