const youtubeStyle = {
  parent_Box: (theme) => ({
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    padding: '20px',
    backgroundColor: theme.palette.customWhite.main,
  }),
  topSection_Box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    maxWidth: '500px',
    width: '100%',
    textAlign: 'center',
  },
  headngText_Style: (theme) => ({
    boxSizing: 'border-box',
    paddingTop: '16px',
    fontSize: {
      xs: theme.typography.h2.fontSize,
      md: theme.typography.extraLarge.fontSize,
    },
    letterSpacing: '-1.52px',
    color: theme.palette.black.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h4.fontWeight,
  }),
  desc_Text_Style: (theme) => ({
    boxSizing: 'border-box',
    paddingTop: '8px',
    lineHeight: '27px',
    fontSize: {
      xs: theme.typography.h5.fontSize,
      md: theme.typography.h3.fontSize,
    },
    color: theme.palette.black.light,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
  }),
  connectInsta_Button: (theme) => ({
    boxSizing: 'border-box',
    marginTop: '16px',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '20px',
    textTransform: 'capitalize',
    borderRadius: '8px',
    backgroundColor: theme.palette.primary.dark,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.customWhite.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h2.fontWeight,
  }),
  bottomSection_Box: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    width: '70%',
    overflow: 'hidden',
    paddingX: '2px',
    background: '',
  },
  footer_HeadngText: (theme) => ({
    lineHeight: '30px',
    fontSize: {
      xs: theme.typography.h4.fontSize,
      md: theme.typography.mediumText.fontSize,
    },
    color: theme.palette.black.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h4.fontWeight,
  }),
  swiper_Style: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  swiperSlide_Style: (theme) => ({
    background: theme.palette.customWhite.extraLight,
    width: '100%',
    maxWidth: '360px',
    boxSizing: 'border-box',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '12px',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.sky.light}`,
  }),
  img_Style: { borderRadius: '50%', height: '44px', width: '44px' },

  message_Text_Style: (theme) => ({
    lineHeight: '24px',
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.black.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
  }),
  spanStyle: { display: 'flex', gap: '4px' },

  name_Text_Style: (theme) => ({
    lineHeight: '24px',
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.black.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h2.fontWeight,
  }),

  profession_Text_Style: (theme) => ({
    lineHeight: '24px',
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.black.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
  }),
  ytChannelparentBox: (theme) => ({
    display: 'flex',
    boxSizing: 'border-box',
    height: 'calc(100vh - 81px)',
    padding: '20px',
    flexDirection: 'column',
    gap: '20px',
  }),
  ytLoaderBox: (theme) => ({
    position: 'fixed',
    color: 'red',
    top: '55%',
    left: '57%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  }),
  ytchannelHeader: (theme) => ({
    display:'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme?.palette.customWhite.main,
    width:'100%' ,
    boxSizing: 'border-box',
    paddingY:'0px',}),
  ytchannelHeaderTypography: (theme) => ({
      lineHeight: 'normal',
      letterSpacing: '-1.2px',
      fontSize: theme.typography.large.fontSize,
      fontFamily: theme.typography.h2.fontFamily,
      fontWeight: theme.typography.h1.fontWeight,
      color: theme.palette.black.main,}),
  ytchannelCardParentBox: (theme) => ({
    background: theme?.palette.customWhite.main,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',}),
  ytchannelCard: (theme) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection:'column',
    padding: '16px',
    gap: '10px',
    cursor: 'pointer',
    background: '',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.sky.light}`,
    boxShadow:
      '0px 0px 1px 0px rgba(82, 82, 137, 0.08), 0px 2px 2px 0px rgba(82, 82, 137, 0.07), 0px 4px 2px 0px rgba(82, 82, 137, 0.04), 0px 7px 3px 0px rgba(82, 82, 137, 0.01), 0px 11px 3px 0px rgba(82, 82, 137, 0.00)',
  }),
  ytchannelCardContentBox: (theme) => ({
    display:'flex',
    width:'100%',
    background:'',
    gap:'16px'}),
  ytchannelCardContentImage: (theme) => ({
    borderRadius: '50%',
    border: `1px solid ${theme.palette.sky.light}`,
    objectFit: 'cover',}),
  ytchannelCardContentNameAndUrlBox: (theme) => ({
      display: 'flex',
      flexDirection: 'column',
      background: '',
      justifyContent: 'space-between',}),
  ytchannelCardContentNameTypography: (theme) => ({
    fontSize: theme.typography.h5.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
    color: '#000',}),
  ytchannelCardContentUrlTypography: (theme) => ({
    fontSize: theme.typography.h5.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
    color: '#000',}),
  ytchannelCardSubscribersTypography: (theme) => ({
      fontSize: theme.typography.h5.fontSize,
      fontFamily: theme.typography.h2.fontFamily,
      fontWeight: theme.typography.h5.fontWeight,
      color: '#000',}),
  ytchannelCardVideosCountTypography: (theme) => ({
        fontSize: theme.typography.h5.fontSize,
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: theme.typography.h5.fontWeight,
        color: '#000',}),
  ytchannelCardViewsCountTypography: (theme) => ({
          fontSize: theme.typography.h5.fontSize,
          fontFamily: theme.typography.h2.fontFamily,
          fontWeight: theme.typography.h5.fontWeight,
          color: '#000',}),
  ytPostHeaderParentBox: (theme) => ({
    mt:'20px',
    boxSizing: 'border-box',
    paddingX:'20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme?.palette.customWhite.main,
  }),
  ytPostHeaderTypography: (theme) => ({
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-1.2px',
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: theme.palette.black.main,
  }),
  ytPostHeaderFilterParentBox: (theme) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
  }),
  ytPostHeaderFilterTextField: (theme) => ({
    flex: 1,
    border: '1px solid #E5E7EB',
    borderRadius: '8px',
    background: 'var(--Basic-Forms-Input-Background-Bg-Color, #FFF)',
    '& .MuiOutlinedInput-root': {
      height: '34px',
      '& fieldset': {
        border: 'none',
      },
      '& input::placeholder': {
        color: 'var(--Basic-Forms-Input-Placeholder-Color, #6B7280)',
        fontFamily: 'Sora',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: '0.075px',
      },
    },
  }),
  ytPostCardParentBox: (theme) => ({
    background: theme?.palette.customWhite.main,
    display: 'flex',
    gap: '16px',
    alignSelf: 'stretch',
    flexWrap: ' wrap',
    boxSizing: 'border-box',
    mt:'20px',
    paddingX:'20px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }),
  ytPostCard: (theme,val) => ({
    display: 'flex',
    width: '428px',
    height: '240px',
    boxSizing: 'border-box',
    padding: '4px',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: '8px',
    cursor: 'pointer',
    borderRadius: 'var(--Numbers-Number-9, 18px)',
    background: `url(${val?.thumbnailUrl}) lightgray 50% / cover no-repeat`,
  }),
  ytPostCardContentBox: (theme) => ({
    display: 'flex',
    padding: '8px',
    boxSizing: 'border-box',
    flexDirection: 'column',
    gap: '8px',
    borderRadius: '16px',
    border: '1px solid rgba(255, 255, 255, 0.50)',
    background: 'rgba(255, 255, 255, 0.12)',
    backdropFilter: 'blur(16px)',
  }),
  ytPostCardContentTitleAndIconBtnBox: (theme) => ({
    display: 'flex',
    background:'',
    justifyContent:'space-between',
    width:'404px',
    height:'40px'
  }),
  ytPostCardContentTitleTypography: (theme) => ({
    color: theme.palette.customWhite.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    fontStyle: 'normal',
    lineHeight: '20px',
    width:'360px',
    height:'40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  }),
  ytPostCardContentEngagementsParentBox: (theme) => ({
    display: 'flex',
    justifyContent: ' space-between',
    alignItems: 'center',
    background:'',
    width:'404px',
  }),
  ytPostCardContentEngagementsFirstChildBox: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    backgroundColor: '',
  }),
  ytPostCardContentEngagementsFirstChildPostTime: (theme) => ({
    color: theme.palette.customWhite.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.paragraph.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    fontStyle: 'normal',
    lineHeight: '18px',
  }),
  ytPostCardContentEngagementsCircle: (theme) => ({
    fontSize: '6px', 
    color: '#FFF'}),
  ytPostCardContentEngagementsFirstChildViews: (theme) => ({
    color: theme.palette.customWhite.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.paragraph.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    fontStyle: 'normal',
    lineHeight: '18px',
  }),
  ytPostCardContentEngagementsFirstChildLikes: (theme) => ({
    color: theme.palette.customWhite.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.paragraph.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    lineHeight: '18px',
    fontStyle: 'normal',
  }),
  ytPostCardContentEngagementsFirstChildComments: (theme) => ({
    color: theme.palette.customWhite.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.paragraph.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    lineHeight: '18px',
    fontStyle: 'normal',
  }),
  ytPostCardContentEngagementsSecondChild: (theme) => ({
    display: 'flex',
    height: '20px',
    boxSizing: 'border-box',
    padding: '2px 4px',
    alignItems: 'center',
    borderRadius: '8px',
    border: '1px solid rgba(255, 255, 255, 0.50)',
    background: 'rgba(255, 255, 255, 0.12)',
    backdropFilter: 'blur(16px)',
  }),
  ytPostCardContentEngagementsSecondChildVideoTime: (theme) => ({
    color: theme.palette.customWhite.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.paragraph.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    // fontSize: '13px',
    fontStyle: 'normal',
    lineHeight: '20px',
  }),
}
export default youtubeStyle
