import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { schedulePublishFooter } from './manualPostFooterStyle'
import { useTheme } from '@emotion/react'
import { CustomButton } from '../../../../../../ReusableComponents/customButtons'
import { formatDate } from '../../../../../../../API'
import { Loader } from '@infostacks/ui-components'

const SchedulePublishFooter = ({
  postData,
  handleOptionSelect,
  handleForm,
  loading,
}) => {
  const theme = useTheme()

  // Publish handler
  const handlePublish = (option) => {
    handleOptionSelect(option)
  }

  return (
    <Box sx={schedulePublishFooter.parent_Box}>
      {/* Schedule Date */}
      <Typography sx={schedulePublishFooter.schedule_Text(theme)}>
        Schedule date:{' '}
        <span style={schedulePublishFooter.scheduleDateTIme_Text(theme)}>
          {formatDate(postData?.scheduledAt)}
        </span>
      </Typography>

      {/* Button or Loader */}
      <Box sx={schedulePublishFooter.loadingBox(loading, theme)}>
        {loading ? (
          <Loader thickness={7} size={30} color='white' /> // Ensure `Loader` is imported
        ) : (
          <Button
            onClick={() => handlePublish('ScheduleToPublish')}
            sx={schedulePublishFooter.publish_Button_Style}
          >
            Publish
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default SchedulePublishFooter
