import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { scheduleEditFooter } from './manualPostFooterStyle'
import { formatDate } from '../../../../../../../API'
import { useTheme } from '@emotion/react'
import { Loader } from '@infostacks/ui-components'

const ScheduleEditFooter = ({
  postData,
  handleOptionSelect,
  handleForm,
  loading,
  setTimeModal,
  ScheduleTime,
  selectedOption,
}) => {
  const theme = useTheme()

  const handleEdit = (option) => {
    handleOptionSelect(option)
  }

  return (
    <Box sx={scheduleEditFooter.parent_Box}>
      <Box sx={scheduleEditFooter.editAndSchedule_Box}>
        <Typography sx={scheduleEditFooter.schedule_Text(theme)}>
          Schedule date:{' '}
          <span style={scheduleEditFooter.scheduleDateTIme_Text(theme)}>
            {formatDate(ScheduleTime)}
          </span>
        </Typography>
        <Button
          onClick={() => setTimeModal(true)}
          sx={scheduleEditFooter.edit_Button(loading, theme)}
        >
          Edit
        </Button>
      </Box>

      {/* Button or Loader */}
      <Box sx={scheduleEditFooter.loadingBox(loading, theme)}>
        {loading ? (
          <Loader thickness={7} size={30} color='white' /> // Ensure `Loader` is imported
        ) : (
          <Button
            onClick={() => handleEdit('ScheduleToEdit')}
            sx={scheduleEditFooter.save_Button_Style(theme)}
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default ScheduleEditFooter
