import React, { useState } from 'react'
import { AvatarGroup, Avatar, Menu, MenuItem, Typography } from '@mui/material'
import Instaicon from '../../../../Assets/Images/instagram.png'
import Fbicon from '../../../../Assets/Images/facebook.png'
import Youtubeicon from '../../../../Assets/Images/youtube.png'
import Googleicon from '../../../../Assets/Images/google.png'
import TikTokicon from '../../../../Assets/Images/tiktok.png'
import Twittericon from '../../../../Assets/Images/x.png'
import LinkedInicon from '../../../../Assets/Images/linkedin.png'
import { useTheme } from '@emotion/react'
import { homePage } from '../homePageStyle'

const masterSocialIcons = [
  { alt: 'Facebook', src: Fbicon },
  { alt: 'Twitter', src: Twittericon },
  { alt: 'LinkedIn', src: LinkedInicon },
  { alt: 'Instagram', src: Instaicon },
  { alt: 'YouTube', src: Youtubeicon },
  { alt: 'Google', src: Googleicon },
  { alt: 'TikTok', src: TikTokicon },
]

export default function PostSocialIcons({ socialIcons }) {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isMenuOpen = Boolean(anchorEl)

  const filteredSocialIcons = socialIcons
    .map((backendIcon) =>
      masterSocialIcons.find((icon) => icon.alt === backendIcon.alt)
    )
    .filter(Boolean)

  const remainingIcons = filteredSocialIcons.slice(2)

  return (
    <div>
      <AvatarGroup
        max={3}
        sx={homePage.postSocialIconAvatar(theme)}
        onClick={(event) => {
          if (event.target.classList.contains('MuiAvatarGroup-avatar')) {
            handleOpen(event)
          }
        }}
      >
        {filteredSocialIcons.map((icon, index) => (
          <Avatar key={index} alt={icon.alt} src={icon.src} />
        ))}
      </AvatarGroup>

      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 35,
          horizontal: 'left',
        }}
        PaperProps={{
          style: homePage.postSocialIconMenu(theme),
        }}
      >
        {remainingIcons.map((icon, index) => (
          <MenuItem
            key={index}
            onClick={handleClose}
            sx={homePage.postSocialIconMenuItem(theme)}
          >
            <img alt={icon.alt} src={icon.src} style={{ width: '20px' }} />

            {icon.alt}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
