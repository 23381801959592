const emptyScreenStyle = {
  parent_Box: (theme) => ({
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    padding: '20px',
    background: theme.palette.background.default,
  }),
  topSection_Box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    maxWidth: '500px',
    width: '100%',
    textAlign: 'center',
  },
  headngText_Style: (theme) => ({
    boxSizing: 'border-box',
    paddingTop: '16px',
    fontSize: {
      xs: theme.typography.h2.fontSize,
      md: theme.typography.extraLarge.fontSize,
    },
    letterSpacing: '-1.52px',
    color: theme.palette.black.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h4.fontWeight,
  }),
  desc_Text_Style: (theme) => ({
    boxSizing: 'border-box',
    paddingTop: '8px',
    lineHeight: '27px',
    fontSize: {
      xs: theme.typography.h5.fontSize,
      md: theme.typography.h3.fontSize,
    },
    color: theme.palette.black.light,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
  }),
  connectInsta_Button: (theme) => ({
    boxSizing: 'border-box',
    marginTop: '16px',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '20px',
    textTransform: 'capitalize',
    borderRadius: '8px',
    backgroundColor: theme.palette.button.dark,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.button.dark,
    },
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.customWhite.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h2.fontWeight,
  }),
  bottomSection_Box: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    width: '70%',
    overflow: 'hidden',
    paddingX: '2px',
    background: '',
  },
  footer_HeadngText: (theme) => ({
    lineHeight: '30px',
    fontSize: {
      xs: theme.typography.h4.fontSize,
      md: theme.typography.mediumText.fontSize,
    },
    color: theme.palette.black.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h4.fontWeight,
  }),
  swiper_Style: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  swiperSlide_Style: (theme) => ({
    background: theme.palette.customWhite.extraLight,
    width: '100%',
    maxWidth: '360px',
    boxSizing: 'border-box',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '12px',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.sky.light}`,
  }),
  img_Style: { borderRadius: '50%', height: '44px', width: '44px' },

  message_Text_Style: (theme) => ({
    lineHeight: '24px',
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.black.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
  }),
  spanStyle: { display: 'flex', gap: '4px' },

  name_Text_Style: (theme) => ({
    lineHeight: '24px',
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.black.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h2.fontWeight,
  }),

  profession_Text_Style: (theme) => ({
    lineHeight: '24px',
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.black.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
  }),
}
export default emptyScreenStyle
export const noPostStyle = {
  parentBox: (theme) => ({
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '90%',
    alignItems: 'center',
    padding: '10px',
    boxSizing: 'border-box',
    gap: '10px',
  }),
  shearContent_TextStyle: (theme) => ({
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: ' -0.96px',
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.black.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
  }),
  desc_Content_TextStyle: (theme) => ({
    fontStyle: 'normal',
    lineHeight: '20px',
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.black.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
  }),
}
