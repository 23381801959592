const styles = {
  pricingContainer: (theme) => ({
    width: '100%',
    display: 'flex',
    gap: '16px',
    background: theme.palette.background.default,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  pricingHeadingBox: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    alignItems: 'center',
  },
  btnBox: {
    display: 'flex',
    gap: '8px', // Remove gap between buttons
    marginTop: '16px',
    position: 'relative', // Make the container relative for positioning the bubble
    width: '320px',
    height: '50px',
    background: '#e0e0e0', // Light background to show the switch effect
    borderRadius: '100px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    padding: '4px',
  },
  bubble: (billingCycle, theme) => ({
    position: 'absolute',
    top: '0',
    left: billingCycle === 'quarterly' ? '0' : '160px', // Switch position based on state
    width: '160px',
    height: '100%',
    backgroundColor: theme.palette.button.dark,
    borderRadius: '100px',
    transition: 'left 0.3s ease', // Smooth transition
  }),

  quarterlyBtn: (billingCycle, theme) => ({
    width: '160px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1, // Ensure text is above the bubble
    color: billingCycle === 'quarterly' ? 'white' : 'Black',
    fontFamily: theme?.typography.h2.fontFamily,
    fontWeight:
      billingCycle === 'quarterly'
        ? theme?.typography?.h3.fontWeight
        : theme?.typography?.h4.fontWeight,
    cursor: 'pointer',
    userSelect: 'none',
    textTransform: 'capitalize',
    transition:
      'background-color 0.3s ease, color 0.9s ease, font-weight 0.3s ease 0.1s',
    ':hover': { background: 'transparent' },
  }),
  yearlyBtn: (billingCycle, theme) => ({
    width: '160px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1, // Ensure text is above the bubble
    color: billingCycle === 'yearly' ? 'white' : 'Black',
    cursor: 'pointer',
    fontFamily: theme?.typography.h2.fontFamily,
    fontWeight:
      billingCycle === 'yearly'
        ? theme?.typography?.h3.fontWeight
        : theme?.typography?.h4.fontWeight,
    userSelect: 'none',
    textTransform: 'capitalize',
    transition:
      'background-color 0.3s ease, color 0.9s ease, font-weight 0.3s ease 0.1s',
    ':hover': { background: 'transparent' },
  }),
  gridContainer: {
    width: 'fit-content',
    background: 'tan',
    flexShrink: 0,
    borderRadius: '30px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
  gridInnerContainer: (index, cardIndex) => ({
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    height: '500px',
    marginTop: '75px',
    gap: '32px',
    background:
      index === cardIndex
        ? 'linear-gradient(164deg, #1519DC -18.51%, #245CF3 112.37%)'
        : 'linear-gradient(164deg, rgba(21, 25, 220, 0.04) -18.51%, rgba(36, 92, 243, 0.04) 112.37%)',
    borderRadius: '30px',
    border: index === cardIndex ? '10px solid #E6ECFF' : '',
    boxSizing: 'border-box',
    paddingX: '24px',
    paddingTop: '32px',
    transform: index === cardIndex ? 'translateY(-75px)' : '',
    transition: 'transform 0.5s ease, background 0.3s ease',
    ':hover': { transform: index !== cardIndex ? 'translateY(-25px)' : '' },
  }),
  card_Header_Box: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    height: 'fit-content',
  },
  header_Text_Box: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    height: '32px',
  },
  mostPopularLabelBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    background: 'blue',
    height: '40px',
  },
  btnStyles: (index) => ({
    bgcolor: { backgroundColor: index === 2 ? '#788BFF' : '#E6ECFF' },
    ':hover': { backgroundColor: index === 2 ? '#788BFF' : '#E6ECFF' },
    borderRadius: '8px',
    width: '100%',
    fontSize: '16px',
    color: index === 2 ? 'white' : '#818089',
    textTransform: 'capitalize',
  }),
  model_Style: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon_Style: {
    height: '20px',
    width: '20px',
    ml: 'auto',
  },
  amount_Box: {
    display: 'flex',
    width: 'fit-content',
    background: '',
    gap: '8px',
    alignItems: 'center',
  },
}
export default styles
export const card_BodyStyle = {
  main_Box: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    height: 'fit-content',
    width: '100%',
  },
  plan_Features_Box: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    height: '22px',
  },
}

export const amount_Text_Style = (cardIndex, index, theme) => ({
  fontSize: theme?.typography.h1.fontSize,
  fontFamily: theme?.typography?.h2.fontFamily,
  fontWeight: theme?.typography?.extraLarge.fontWeight,
  lineHeight: 'normal',
  letterSpacing: '-1.6px',
  color:
    index === cardIndex
      ? theme.palette.customWhite.main
      : theme.palette.black.main,
})
export const plan_Style = (theme) => ({
  width: 'fit-content',
  lineHeight: '18px',
  color: theme.palette.border.main,
  fontSize: theme?.typography.paragraph.fontSize,
  fontFamily: theme?.typography.h2.fontFamily,
  fontWeight: theme?.typography.h5.fontWeight,
  height: 'fit-content',
})
export const foldingBoxStyles = (isFolding, theme) => ({
  background: theme.palette.background.default,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  paddingTop: '60px',
  paddingX: '99px',
  paddingBottom: '10px',
  alignItems: 'center',
  gap: '30px',
  animation: isFolding ? 'foldUp 1s ease forwards' : 'none',
})

export const pricingHeading = (theme) => ({
  fontSize: theme?.typography.h1.fontSize,
  color: theme?.palette.black.main,
  fontFamily: theme?.typography.h2.fontFamily,
  fontWeight: theme?.typography.h1.fontWeight,
  textAlign: 'center',
  lineHeight: 'normal',
  letterSpacing: '0.8px',
})
export const pricingSubHeading = (theme) => ({
  fontSize: theme?.typography.mediumText.fontSize,
  fontFamily: theme?.typography.h2.fontFamily,
  fontWeight: theme?.typography.h5.fontWeight,
  color: theme?.palette.black.extraLight,
  textAlign: 'center',
  lineHeight: 'normal',
  letterSpacing: '0.4px',
  mt: '16px',
})

export const countineButton = (theme) => ({
  width: '446px',
  height: '44px',
  textTransform: 'capitalize',
  display: 'flex',
  boxSizing: 'border-box',
  padding: '10px 170px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '14px',
  backgroundColor: theme.palette.button.dark,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  fontFamily: theme.typography.h2.fontFamily,
  color: theme.palette.customWhite.main,
  ':hover': { backgroundColor: theme.palette.button.dark },
})
export const decp_TextStyle = (theme) => ({
  color: theme?.palette.black.light,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h5.fontWeight,
  fontSize: theme.typography.h4.fontSize,
  lineHeight: '24px',
  mt: '20px',
})
export const main_BoxEnterpriseDialog = (theme) => ({
  height: 'fit-content',
  width: '520px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: 'var(--Overlays-Modal-Body-Spacing, 40px)',
  background: theme.palette.background.default,
  borderRadius: '16px',
  boxShadow:
    ' 0px 20px 13px 0px rgba(0, 0, 0, 0.03), 0px 8px 5px 0px rgba(0, 0, 0, 0.08)',
})
export const iconBox_Style = (theme) => ({
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  backgroundColor: theme.palette.gray.extraLight,
  width: '62px',
  height: '62px',
})
export const subTitle_TextStyle = (theme) => ({
  color: theme?.palette.black.main,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h1.fontWeight,
  fontSize: theme.typography.h2.fontSize,
  lineHeight: 'normal',
  letterSpacing: '0.96px',
  mt: '20px',
})
export const contact_Sales_Btn = (theme) => ({
  mt: '20px',
  backgroundColor: theme.palette.button.dark,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h3.fontWeight,
  fontSize: theme.typography.h5.fontSize,
  lineHeight: '20px',
  textAlign: 'center',
  textTransform: 'capitalize',
  borderRadius: '10px',
  boxSizing: 'border-box',
  padding: 'var(--Numbers-Number-4, 8px) 16px',
  color: theme.palette.customWhite.main,
  ':hover': { backgroundColor: theme.palette.button.dark },
})
export const title_TextStyle = (cardIndex, index, theme) => ({
  fontSize: theme?.typography?.headingTwo.fontSize,
  fontFamily: theme?.typography.h2.fontFamily,
  fontWeight: theme?.typography.h1.fontWeight,
  lineHeight: 'normal',
  height: 'fit-content',
  width: 'fit-content',
  color:
    index === cardIndex
      ? theme.palette.customWhite.main
      : theme.palette.black.main,
})
export const activeCardStyle = (index, cardIndex, theme) => ({
  boxSizing: 'border-box',
  padding: '6px 8px',
  background:
    index === cardIndex
      ? theme.palette.customWhite.main
      : 'linear-gradient(164deg, #1519DC -18.51%, #245CF3 112.37%)',
  color:
    index === cardIndex
      ? theme.palette.black.main
      : theme.palette.customWhite.main,

  fontSize: theme?.typography.paragraph.fontSize,
  fontFamily: theme?.typography.h2.fontFamily,
  fontWeight: theme?.typography.h2.fontWeight,
  borderRadius: '8px',
  display: index === 2 ? 'flex' : 'none',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'capitalize',
  ':hover': {
    background:
      index === cardIndex
        ? theme.palette.customWhite.main
        : 'linear-gradient(164deg, #1519DC -18.51%, #245CF3 112.37%)',
  },
})
export const checkCircle_Style = (index, cardIndex) => ({
  color: index === cardIndex ? '#9282FF' : 'lightgrey',
  height: '16px',
  width: '16px',
})
export const removeIcon_Style = (teamUsers, theme) => ({
  height: '24px',
  width: '24px',
  backgroundColor: theme.palette.sky.light,
  color: theme.palette.primary.main,
  boxSizing: 'border-box',
  padding: '4px',
  borderRadius: '5px',
  cursor: teamUsers === 2 ? 'not-allowed' : 'pointer',
  ':hover': {
    backgroundColor: theme.palette.sky.light,
  },
})
export const addIcon_Style = (theme) => ({
  height: '24px',
  width: '24px',
  backgroundColor: theme.palette.sky.light,
  color: theme.palette.primary.main,
  boxSizing: 'border-box',
  padding: '4px',
  borderRadius: '5px',
  ':hover': {
    backgroundColor: theme.palette.sky.light,
  },
})

export const teamUser_TextStyle = (theme) => ({
  width: 'fit-content',
  borderBottom: `1px solid ${theme.palette.customWhite.main}`,
  fontSize: theme?.typography.h5.fontSize,
  fontFamily: theme?.typography.h2.fontFamily,
  fontWeight: theme?.typography.h5.fontWeight,
  fontStyle: 'normal',
  lineHeight: '20px',
  color: theme.palette.customWhite.main,
})

export const bodyText_Style = (index, cardIndex, theme) => ({
  fontSize: theme?.typography.h5.fontSize,
  fontFamily: theme?.typography.h2.fontFamily,
  fontWeight: theme?.typography.h4.fontWeight,
  lineHeight: '20px',
  display: 'flex',
  color:
    index === cardIndex
      ? theme.palette.customWhite.main
      : theme.palette.black.main,
})
