import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import SendRoundedIcon from '../../../../../Assets/Images/sendIcon.png'
import { useTheme } from '@emotion/react'
import {
  emptyScrHoverText,
  emptyScrSeachHover,
  emptyScrSearchSendicon,
  emptyScrSearchUploadicon,
  emptyScrTextField,
  homePage,
} from '../../homePageStyle'

const SearchBar = ({ setValue, setShowPost, prompt }) => {
  const theme = useTheme()
  const [inputValue, setInputValue] = useState(prompt || '')
  const [isHovered, setIsHovered] = useState(false)

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleNextPage = () => {
    if (inputValue.trim() !== '') {
      setValue(inputValue)
      setShowPost(true)
      setInputValue('')
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleNextPage()
    }
  }
  useEffect(() => {
    if (prompt && prompt !== inputValue) {
      setInputValue(prompt)
    }
  }, [prompt])

  return (
    <Box sx={homePage.emptySrcSearch}>
      <Box sx={emptyScrSeachHover(isHovered)}>
        <Typography sx={emptyScrHoverText(theme)}>coming soon</Typography>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='14'
          height='20'
          viewBox='0 0 14 20'
          fill='none'
          style={homePage.emptyScrHoverImg}
        >
          <path
            d='M12.9706 9.20759C13.4906 9.6079 13.4906 10.3921 12.9706 10.7924L2.10999 19.1529C1.45243 19.6591 0.500001 19.1904 0.500001 18.3605L0.500002 1.63947C0.500002 0.809636 1.45243 0.340872 2.11 0.847069L12.9706 9.20759Z'
            fill='#000'
          />
        </svg>
      </Box>
      <TextField
        multiline
        fullWidth
        autoFocus
        maxRows={3}
        sx={homePage.searchBarTextField(theme)}
        placeholder='Type your post idea or trending topic...'
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        InputProps={{
          startAdornment: (
            <Box
              sx={emptyScrSearchUploadicon(theme)}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18.5'
                height='21.75'
                viewBox='0 0 20 22'
                fill='none'
              >
                <path
                  d='M14.5635 0.477747C13.5812 0.374992 12.2935 0.374985 10.7524 0.375C10.2127 0.375 9.77512 0.812565 9.77512 1.35231C9.77512 1.89205 10.2127 2.32959 10.7524 2.32959C12.361 2.32959 13.4922 2.33084 14.3616 2.42179C15.2155 2.51112 15.6994 2.67788 16.0573 2.93092C16.2987 3.10167 16.5111 3.30276 16.6889 3.52711C16.9445 3.84961 17.1139 4.2824 17.2061 5.06919C17.3011 5.87895 17.3026 6.93628 17.3026 8.45973L17.3027 11.3599C17.3027 11.6269 17.3026 12.2655 17.022 12.7377C16.849 13.0286 16.6276 13.2717 16.4002 13.3956C16.0336 13.5951 15.6135 13.7085 15.1668 13.7085L14.1264 13.672C13.7463 13.6641 13.3028 13.6761 12.8746 13.7908C12.0407 14.0143 11.3893 14.6657 11.1658 15.4996C11.0511 15.9278 11.0391 16.3713 11.047 16.7514L11.0835 17.7918C11.0835 18.2595 10.9591 18.6666 10.7417 19.045C10.615 19.2656 10.3943 19.4675 10.0895 19.6448C9.62738 19.9137 9.07398 19.9162 8.73583 19.9177C8.39767 19.9193 8.04089 19.9204 7.74284 19.9204C5.90872 19.9204 5.24159 19.9065 4.73823 19.7291C3.8656 19.4217 3.1999 18.78 2.88981 17.9947C2.81217 17.798 2.75733 17.5396 2.72789 17.0917C2.69788 16.635 2.69739 16.052 2.69739 15.2118V11.0987C2.69739 10.5609 2.26145 10.125 1.72369 10.125C1.18594 10.125 0.75 10.5609 0.75 11.0987V15.245C0.749994 16.0441 0.749991 16.6917 0.784725 17.2202C0.820517 17.7649 0.896134 18.2505 1.0794 18.7147C1.60821 20.054 2.71664 21.0883 4.0932 21.5733C4.952 21.8759 6.42408 21.8755 7.97909 21.8749C10.8187 21.8753 12.5054 21.8755 13.8878 21.3884C16.1078 20.6063 17.8815 18.9435 18.7249 16.8075C19.006 16.0955 19.1306 15.3308 19.1908 14.4139C19.25 13.5132 19.25 12.4006 19.25 10.9823V8.39882C19.25 6.95073 19.25 5.77808 19.1402 4.84075C19.026 3.86735 18.7828 3.02947 18.213 2.3104C17.9178 1.93792 17.5692 1.60914 17.1789 1.33309C16.4341 0.806433 15.5729 0.583343 14.5635 0.477747Z'
                  fill='#0C0C0C'
                />
                <path
                  d='M6.00586 9.125C6.00586 9.67728 5.55814 10.125 5.00586 10.125C4.45357 10.125 4.00586 9.67728 4.00586 9.125L4.00586 4.62496L3.4116 4.62498C3.23596 4.62511 3.02031 4.62526 2.84387 4.60319L2.84053 4.60277C2.71408 4.587 2.13804 4.51517 1.86368 3.94957C1.58872 3.38275 1.89065 2.88261 1.95597 2.7744L2.29511 2.30592C2.58975 1.93009 2.99545 1.41563 3.37588 1.02462C3.56571 0.829514 3.78297 0.628277 4.01388 0.469429C4.21914 0.328226 4.56933 0.124962 5 0.124962C5.43067 0.124962 5.78086 0.328226 5.98612 0.469429C6.21703 0.628277 6.43429 0.829514 6.62412 1.02462C7.00455 1.41563 7.41025 1.93008 7.70488 2.30591L8.04403 2.77441C8.10935 2.88261 8.41128 3.38275 8.13632 3.94957C7.86196 4.51517 7.28592 4.58701 7.15947 4.60277L7.15613 4.60319C6.97969 4.62526 6.76403 4.62511 6.5884 4.62498L6.00586 4.62496L6.00586 9.125Z'
                  fill='#0C0C0C'
                />
              </svg>
            </Box>
          ),
          endAdornment: (
            <Button
              sx={emptyScrSearchSendicon(theme, inputValue)}
              onClick={handleNextPage}
            >
              <img
                src={SendRoundedIcon}
                alt='Send'
                style={homePage.emptyScrSendIconImg}
              />
            </Button>
          ),
          sx: emptyScrTextField(theme),
        }}
      />
    </Box>
  )
}

export default SearchBar
