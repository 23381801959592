import { CloseRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'

import { useForm } from 'react-hook-form'
import { roleValidetor } from '../../../../../Validators/authValidator'
import { updateSpecificMemberRoleAPI } from '../../../../../API'
import UserContext from '../../../../../Context/userContext'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { Loader } from '@infostacks/ui-components'
import {
  changeRole_Text,
  changeRoleModelStyle,
  commonSelectStyle,
  discardBtn,
  labelTextfield,
  roleText_Text,
  saveChangeBtn,
  textfield,
} from './workSpaceMemberModalStyle'

const ChangeRoleModal = ({
  roleChangeModel,
  handleChangeRoleModel,
  currentRowData,
  roles,
  setRender,
  render,
}) => {
  const { userAuth } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const memberId = currentRowData?.memberId
  console.log('currentRowData', currentRowData)
  console.log('roles', roles)

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    setLoading(true)
    console.log('Data', data)
    const found = roles.filter((item) => item.roleName === data.role)
    console.log('found', found)
    data.roleId = found[0]?._id
    delete data.role
    console.log('final Data', data)
    console.log('memberId', memberId)
    const resOfupdateSpecificMemberRoleAPI = await updateSpecificMemberRoleAPI(
      userAuth,
      memberId,
      data
    )
    // console.log('resOfupdateSpecificMemberRoleAPI',resOfupdateSpecificMemberRoleAPI);
    handleChangeRoleModel(false)
    setRender(!render)
  }
  return (
    <Modal open={roleChangeModel} sx={changeRoleModelStyle.modal_Style(theme)}>
      {loading ? (
        <Box sx={changeRoleModelStyle.loader_Box}>
          <Loader thickness={6} sx={{ color: 'blue' }} />
        </Box>
      ) : (
        <Box sx={changeRoleModelStyle.changRole_MainBox}>
          <Box sx={changeRoleModelStyle.icon_Box}>
            <Typography sx={changeRole_Text(theme)}>Change Role</Typography>
            <IconButton
              sx={{ height: '30px', width: '30px' }}
              onClick={() => handleChangeRoleModel(false)}
            >
              <CloseRounded />
            </IconButton>
          </Box>
          <Typography sx={roleText_Text(theme)}>
            Changing the user's role will update their permissions to match the
            new role. Review the new role's permissions before proceeding.
          </Typography>
          <Box sx={changeRoleModelStyle.formBox}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={changeRoleModelStyle.form_Style}
            >
              <Box sx={changeRoleModelStyle.textField_Main_Box}>
                <label
                  className='textfield-label'
                  style={labelTextfield(theme)}
                >
                  Name
                </label>
                <div style={{ position: 'relative' }}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    autoComplete='off'
                    disabled
                    placeholder={currentRowData?.fullName}
                    sx={textfield()}
                  />
                </div>
              </Box>
              <Box sx={changeRoleModelStyle.textField_Main_Box}>
                <label
                  className='textfield-label'
                  style={changeRoleModelStyle.label_Style}
                >
                  Email
                </label>
                <div style={{ position: 'relative' }}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    autoComplete='off'
                    disabled
                    placeholder={currentRowData?.email}
                    sx={textfield()}
                  />
                </div>
              </Box>
              <Box sx={changeRoleModelStyle.textField_Main_Box}>
                <label
                  className='textfield-label'
                  style={labelTextfield(theme)}
                >
                  Role
                </label>
                <div style={{ position: 'relative' }}>
                  <Select
                    variant='outlined'
                    fullWidth
                    defaultValue={currentRowData?.roleName}
                    // value={watch('role') || ''}
                    {...register('role', roleValidetor)}
                    sx={{
                      ...commonSelectStyle(errors.role),
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          padding: '6px',
                          borderRadius: '12px',
                          boxSizing: 'border-box',
                        },
                      },
                    }}
                  >
                    {roles?.map(
                      (val, ind) =>
                        val.roleName !== 'Owner' && (
                          <MenuItem
                            sx={changeRoleModelStyle.menuitem_Style}
                            key={ind}
                            value={val.roleName}
                          >
                            {val.roleName}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </div>
              </Box>

              <Box sx={changeRoleModelStyle.button_Box}>
                <Button
                  onClick={() => handleChangeRoleModel(false)}
                  sx={discardBtn(theme)}
                >
                  Discard
                </Button>
                <Button type='submit' sx={saveChangeBtn(theme)}>
                  Save Changes
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      )}
    </Modal>
  )
}

export default ChangeRoleModal
