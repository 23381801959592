import { WarningAmberRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material'

import React from 'react'
import {
  archive_Workspaces_Text,
  button_style,
  dialog_Parent,
  dialogStyle,
  paragraoh_Text,
} from '../../Dashboard/DashboardMainSection/dashboardMainSectionStyle'
import { useTheme } from '@emotion/react'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const ArchiveDialog = ({ open, close, UnarchiveSpace, id }) => {
  const confirmUnrchive = () => {
    UnarchiveSpace(id, { isDeleted: false }, 'unarchive')
  }
  const theme = useTheme()
  return (
    <Dialog
      PaperProps={{
        sx: dialog_Parent(theme),
      }}
      open={open}
      onClose={() => close(!open)}
      TransitionComponent={Transition}
    >
      <Box sx={dialogStyle.Icon_Box}>
        <WarningAmberRounded sx={dialogStyle.warningAmberRounded_Style} />
      </Box>
      <DialogTitle sx={archive_Workspaces_Text(theme)}>
        Unarchive Workspace
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={paragraoh_Text(theme)}>
          Are you sure you want to Unarchive this workspace?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ gap: '10px' }}>
        <Button onClick={() => close(!open)} sx={button_style(theme)}>
          Cancel
        </Button>
        <Button onClick={confirmUnrchive} sx={button_style(theme)}>
          {' '}
          Unarchive
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ArchiveDialog
