import React, { useState } from 'react'
import { CustomButton } from '../../../../ReusableComponents/customButtons'

import {
  Box,
  TextField,
  Button,
  InputAdornment,
  Typography,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import youtubeStyle from '../youtubeStyle'

const YoutubePostHeader = ({ setYoutubeModal }) => {
  const theme = useTheme()

  return (
    <Box
      sx={youtubeStyle.ytPostHeaderParentBox(theme)}
    >
      <Typography
        sx={youtubeStyle.ytPostHeaderTypography(theme)}
      >
        Youtube
      </Typography>
      <Box
        sx={youtubeStyle.ytPostHeaderFilterParentBox(theme)}
      >
        <TextField
          variant='outlined'
          placeholder='Search posts'
          size='small'
          sx={youtubeStyle.ytPostHeaderFilterTextField(theme)}
          InputProps={{
            style: {
              marginRight: '-15px',
            },
            endAdornment: (
              <InputAdornment position='end'>
                <Button
                  variant='contained'
                  sx={{
                    height: '36px',
                    background: theme.palette.button.dark,
                    borderRadius:
                      'var(--Basic-Forms-Input-Border-Radius, 0px) var(--Numbers-Number, 8px) var(--Number, 8px) var(--Basic-Forms-Input-Border-Radius, 0px)',
                    boxShadow: 'none',
                    fontSize: {
                      xs: theme.typography.paragraph.fontSize,
                      sm: theme.typography.h5.fontSize,
                    },
                    fontWeight: theme?.typography.h2.fontWeight,
                    fontFamily: theme?.typography.h4.fontFamily,
                    textTransform: 'none',
                  }}
                >
                  Search
                </Button>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: 'none' }}>
          <CustomButton buttonText={'Filters'} />
        </Box>
        <CustomButton
          buttonText={'Upload Video'}
          onClick={() => setYoutubeModal(true)}
        />
      </Box>
    </Box>
  )
}

export default YoutubePostHeader
//dddddddddd
