export const facebookServiceStyle = {
  parentContainer: (theme) => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.default,
    padding: '20px',
    boxSizing: 'border-box',
    gap: '20px',
  }),
  facebookServicesHeader: (theme) => ({
    display: { xs: 'flex' },
    justifyContent: 'space-between',
    background: theme.palette.background.default,
    boxSizing: 'border-box',
    width: { xs: '100%' },
  }),
  header: (theme) => ({
    display: 'flex',
    width: '100%',
    justifyContent: '',
    gap: '20px',
    alignItems: 'center',
    background: theme.palette.background.default,
  }),
  facebook_Map_Container: (theme) => ({
    background: theme.palette.background.default,
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    mb: 'auto',
  }),
  menu_Style: {
    display: 'flex',
    borderRadius: '12px',
    backgroundColor: '#F8F9FF',
    paddingY: '6px',
    paddingX: '10px',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
  },
  unarchive_Space_Text: {
    borderRadius: '4px',
    padding: '6px',
    boxSizing: 'border-box',
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#DADAFF' },
  },
  card_footer: {
    display: 'flex',
    alignItems: 'end',
    gap: '10px',
    justifyContent: 'space-between',
  },
}
export const facebookServicesHeaderText = (theme) => ({
  fontSize: {
    xs: theme.typography.h3.fontSize,
    sm: theme.typography.large.fontSize,
  },
  fontWeight: {
    xs: theme.typography.h2.fontWeight,
    sm: theme?.typography.menualText.fontWeight,
  },
  fontFamily: theme?.typography.menualText.fontFamily,
  color: theme?.palette.black.main,
  lineHeight: 'normal',
  letterSpacing: '-1.2px',
})
export const card_Main = (theme) => ({
  width: {
    xs: 'auto',
    sm: '360px',
  },
  display: 'flex',
  flexDirection: 'column',
  padding: ' var(--Numbers-Number-9, 18px) 24px',
  gap: { xs: '16px' },
  borderRadius: '16px',
  border: `1px solid ${theme.palette.sky.light}`,
  backgroundColor: theme?.palette?.gray.main,
  boxSizing: 'border-box',
  boxShadow:
    '0px 0px 1px 0px rgba(82, 82, 137, 0.08), 0px 2px 2px 0px rgba(82, 82, 137, 0.07), 0px 4px 2px 0px rgba(82, 82, 137, 0.04), 0px 7px 3px 0px rgba(82, 82, 137, 0.01), 0px 11px 3px 0px rgba(82, 82, 137, 0.00)',
})
export const name = (theme) => ({
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  color: theme?.palette.black.main,
  lineHeight: '27px',
  fontStyle: 'normal',
})
export const description = (theme) => ({
  width: '100%',
  height: '70px',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  background: '',
  alignItems: 'center',
  gap: 'var(--Components-Badge-Placeholder-Gap-Between, 7px)',
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  fontSize: theme.typography.h4.fontSize,
  color: theme?.palette.black.light,
  lineHeight: '24px',
  fontStyle: 'normal',
})
export const footerText = (theme) => ({
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  fontSize: theme.typography.h4.fontSize,
  color: theme?.palette.black.light,
  lineHeight: '24px',
  fontStyle: 'normal',
})
export const addPost_Button_Style = (
  theme,
  postedAndScheduledCount,
  currentUser
) => ({
  lineHeight: '20px',
  boxSizing: 'border-box',
  textTransform: 'capitalize',
  width: 'fit-content',
  borderRadius: ' var(--Numbers-Number-2, 4px)',
  padding:
    ' var(--Components-Button-Spacing-Y-SM, 10px) var(--Components-Button-Spacing-X-SM, 12px)',
  fontWeight: theme?.typography.h2.fontWeight,
  fontSize: theme?.typography.h5.fontSize,
  background: theme.palette.button.dark,
  color: 'white',
  ':hover': { backgroundColor: theme.palette.button.dark },
})
export const dialogStyle = {
  warningAmberRounded_Style: {
    background: '',
    color: 'red',
    height: '30px',
    width: '30px',
  },
  Icon_Box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'var(--Components-Static-Icons-Icon-Height-LG, 62px)',
    height: 'var(--Components-Static-Icons-Icon-Height-LG, 62px)',
    borderRadius:
      'var(--Components-Static-Icons-Circular-Border-Radius, 999px)',
    border:
      '7px solid var(--Components-Static-Icons-Soft-Outlined-Yellow-Border-Color, #FEFCE8)',
    background:
      'var(--Components-Static-Icons-Soft-Outlined-Yellow-Bg-Color, #FEF9C3)',
  },
}
export const dialog_Parent = (theme) => ({
  borderRadius: '16px',
  background: theme.palette.background.default,
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: {
    xs: '12px 10px',
    sm: 'var(--Components-Static-Icons-Icon-Height-LG, 20px) 50px',
  },
})
export const archive_Workspaces_Text = (theme) => ({
  color: theme?.palette.black.main,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: {
    xs: theme?.typography.h4.fontWeight,
    sm: theme?.typography.h3.fontWeight,
  },
  fontSize: {
    xs: theme.typography.mediumText.fontSize,
    sm: theme.typography.h2.fontSize,
  },
  lineHeight: 'normal',
})
export const paragraoh_Text = (theme) => ({
  color: theme?.palette.black.light,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: {
    xs: theme?.typography.h5.fontWeight,
    sm: theme?.typography.h4.fontWeight,
  },
  fontSize: {
    xs: theme.typography.h5.fontSize,
    sm: theme.typography.h4.fontSize,
  },
  lineHeight: 'normal',
  textAlign: 'center',
})
export const button_style = (theme) => ({
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h4.fontWeight,
  fontSize: theme.typography.h5.fontSize,
  width: '80px',
  textTransform: 'capitalize',
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: 'var(--Components-Button-Border-Radius, 8px)',
  '&:hover': {
    backgroundColor: theme?.palette.primary.light,
    color: theme?.palette.customWhite.main,
  },
})
export const facebookPostModalStyle = {
  form_Style: {
    width: '100%',
    background: '',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '50px',
    marginTop: '50px',
  },
  slectPage_Box: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    gap: { sm: '5px' },
  },
  custom_Select_PageField_Box: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: '',
    gap: { sm: '5px' },
  },
  custom_Field_Style: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  description_Text_Field_Style: {
    borderRadius: '6px',
    overflowY: 'auto',
    '& textarea': {
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  form_Helper_Text: {
    position: 'absolute',
    bottom: '-20px',
    color: 'red',
  },
  textField_Style_Box: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },

  reactImageUploading_Main_Box: {
    background: '',
    border: '1px solid #bdbdbd',
    borderRadius: '6px',
    width: '100%',
    display: 'flex',
    gap: '20px',
    boxSizing: 'border-box',
    padding: '10px',
  },
  button_Main_Box: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
    height: '200px',
    width: 'fit-content',
  },
  remove_All_Button: {
    textTransform: 'capitalize',
    width: 'fit-content',
    paddingX: '12px',
    paddingY: '4px',
    borderRadius: '6px',
  },
  map_Container: {
    width: '120px',
    height: '100px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  imageList_div_Style: {
    background: '',
    width: '120px',
    height: '100px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  custim_Icon_Style: {
    height: '25px',
    width: '25px',
    background: 'lightgray',
    '&:hover': { backgroundColor: '#DADAFF' },
  },
  footer_Button_Box: {
    display: 'flex',
    gap: '20px',
    width: 'fit-content',
    background: '',
    ml: 'auto',
  },
  fotter_modalBtns: {
    textTransform: 'capitalize',
    borderRadius: '6px',
    fontWeight: '700',
    backgroundColor: '#241BFF',
    '&:hover': {
      backgroundColor: '#241BFF',
      color: 'white',
    },
  },
  hover_Icon_Size: {
    height: '20px',
    width: '20px',
  },
  FormHelperText: {
    position: 'absolute',
    bottom: '-20px',
    color: 'red',
    ml: '20px',
  },
  clickDrop_main_Box: {
    width: '95%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    boxSizing: 'border-box',
    height: 'fit-content',
  },
  footer_Main_Button_Box: {
    display: 'flex',
    gap: '20px',
    width: '100%',
    background: '',
    alignItems: 'center',
  },
  draftButton_Box: {
    ml: 'auto',
    display: 'flex',
    gap: '20px',
  },
}
export default facebookPostModalStyle
export const field_Main_Box = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  background: '',
  borderRadius: '10px',
  border: `1px solid ${theme.palette.gray.light}`,
  gap: '20px',
  boxSizing: 'border-box',
  paddingBottom: '10px',
})
export const textField_Style = (theme) => ({
  backgroundColor: theme?.palette?.gray?.dark,
  width: '95%',
  mx: 'auto',
  overflowY: 'auto',
  '& textarea': {
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
})
export const form_Style = (theme) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '20px',
  marginTop: '50px',
  background: theme.palette.background.default,
})
export const clickDrop_dotted_Button = (isDragging, theme) => ({
  textTransform: 'capitalize',
  color: isDragging ? theme.palette.error.main : null,
  height: '100px',
  width: '120px',
  borderRadius: '8px',
  padding: '0px',
  border: `1px solid ${theme.palette.gray.light}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  '&:hover': {
    border: `2px dotted ${theme.palette.primary.main}`,
  },
})

export const click_Or_Drop_Here_Text = (theme) => ({
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h5.fontWeight,
  fontSize: theme.typography.paragraph.fontSize,
  color: theme.palette.button.dark,
  lineHeight: '16px',
  letterSpacing: '0.86px',
})
export const custom_Label_Style = (theme) => ({
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h2.fontWeight,
  fontSize: theme.typography.h5.fontSize,
  color: theme?.palette.black.main,
  width: 'auto',
  marginRight: 'auto',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '0.075px',
})
export const clickOrDropButton = (isDragging) => ({
  textTransform: 'capitalize',
  color: isDragging ? 'red' : null,
  height: '100px',
  width: '120px',
  padding: '0px',
  borderRadius: '8px',
})
export const postSelectStyles = (error) => ({
  borderRadius: '6px',
  width: '100%',
  fontFamily: 'Sora',
  height: { xs: '48px', sm: '57px', md: '53px' },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E5E7EB',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: error ? 'red' : 'primary',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E5E7EB',
  },
  '& .MuiSelect-select': {
    // height: "15px",
    color: '', // Ensure the color is set correctly or remove it
  },
})

export const hoverImageBoxStyle = (hoverStates, index) => ({
  display: hoverStates[index] ? 'flex' : 'none',
  justifyContent: 'space-evenly',
  width: '100%',
  position: 'absolute',
  background: '',
  zIndex: 2,
})
export const fotter_Draft_Button = (theme) => ({
  textTransform: 'capitalize',
  borderRadius: '6px',
  fontFamily: theme?.typography.h5.fontFamily,
  fontWeight: theme?.typography.h2.fontWeight,
  backgroundColor: theme?.palette.primary.main,
  '&:hover': {
    backgroundColor: theme?.palette.primary.main,
    color: theme?.palette.customWhite.main,
  },
})
export const publish_Schedule_Button = (theme) => ({
  fontFamily: theme?.typography.h5.fontFamily,
  fontWeight: theme?.typography.h2.fontWeight,
  color: theme?.palette.customWhite.main,
  textTransform: 'capitalize',
  height: '100%',
  width: '100px',
  mr: '0px',
})
export const imageStyle = (hoverStates, index) => ({
  height: '100%',
  width: '100%',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'fill',
  filter: hoverStates[index] ? 'brightness(70%)' : 'brightness(100%)',
  transition: 'filter 0.3s ease-in-out',
})
export const custom_Icon_Style = (theme) => ({
  height: '25px',
  width: '25px',
  backgroundColor: theme?.palette.customWhite.light,
  '&:hover': { backgroundColor: theme?.palette.sky.light },
})
export const hover_Main_Box_Style = (hoverStates, index) => ({
  display: hoverStates[index] ? 'flex' : 'none',
  justifyContent: 'space-evenly',
  width: '100%',
  position: 'absolute',
  background: '',
  zIndex: 2,
})
export const Schedule_Box = (scheduleTime) => ({
  display: scheduleTime ? 'flex' : 'none',
  alignItems: 'center',
})
export const Schedule_Button = (theme) => ({
  textTransform: 'capitalize',
  color: theme?.palette.black.main,
  fontFamily: theme?.typography.h5.fontFamily,
  fontSize: theme?.typography.paragraph.fontSize,
  fontWeight: theme?.typography.h5.fontWeight,
  ml: '20px',
})
export const submit_Main_Button = (theme) => ({
  display: 'flex',
  backgroundColor: theme?.palette.primary.main,
  alignItems: 'center',
  gap: '0px',
  borderRadius: '6px',
})
export const postHeader_Box = (theme) => ({
  width: '100%',
  height: '50px',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  borderBottom: `1px solid ${theme.palette.gray.light}`,
  boxSizing: 'border-box',
  paddingLeft: '10px',
  display: 'flex',
  alignItems: 'center',
  background: '',
})
export const emojiPicker = () => ({
  // position: 'absolute',
  zIndex: '999',
  top: '250px',
  right: '60px',
})
