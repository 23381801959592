import { useTheme } from '@emotion/react'
import { Box, Button, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import inviteLandingPageStyles, {
  absoluteBox,
  acceptButton,
  cancelButton,
  categoryText,
  desc_Typography,
  invitedTypography,
  mainCard_Style,
  workspaceNameTypo,
} from './inviteLandingPageStyle'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import NavBar from '../NavBar/navBar'
import CustomDialog from '../ReusableComponents/customDialoge'
import UserContext from '../../Context/userContext'
import { SentimentDissatisfiedRounded } from '@mui/icons-material'
import {
  handleAcceptInviteMemberAPI,
  handleInviteMemberAPI,
  handleRejectInviteMemberAPI,
  invitedWorkspaceDataAPI,
} from '../../API'
import { Loader } from '@infostacks/ui-components'
import CreateMemberForm from './CreateMemberForm/createMemberForm'
import { loginInAuth, signUpAuth } from '../../Firebase/auths'
import { sendEmailVerification, updateProfile } from 'firebase/auth'
import { auth } from '../../Firebase/firebaseConfig'
import LoginMemberForm from './LoginMember/loginMemberForm'
import { loginAPI } from '../../API/AuthAPIs'

const InviteLandingPage = () => {
  const { userAuth, checkEmailExistContext, setCurrentUser, getLatestRoute } =
    useContext(UserContext)
  const [memberId, setMemberId] = useState()
  const [loading, setLoading] = useState(true)
  const [btnLoader, setBtnLoader] = useState(false)
  const [invitedWorkspaceData, setInvitedWorkspaceData] = useState()
  const [open, setOpen] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  const [apiError, setApiError] = useState()
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  console.log('location', location.pathname + location.search)
  let newRoute
  const handleReject = (state) => {
    setOpen(state)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const onSubmit = async (formData) => {
    formData.email = invitedWorkspaceData?.email
    console.log('formData', formData)
    setBtnLoader(true)
    await signUpAuth(formData.email, formData.password)
      .then(async (UserCredentials) => {
        console.log('UserCredentials', UserCredentials)
        await updateProfile(auth.currentUser, {
          displayName: formData.fullname,
        })
        await handleAcceptInviteMemberAPI(auth.currentUser, memberId, {
          action: 'accept',
          phoneNumber: formData?.phoneNumber,
        }).then(async (response) => {
          console.log('handleAccept response', response)
          setCurrentUser(response)
          window.localStorage.setItem('user', JSON.stringify(response))
          if (response.workspacesCount > 1) {
            newRoute = '/choose-workspace'
          } else {
            newRoute = getLatestRoute()
          }

          console.log('newRoute', newRoute)
          await sendEmailVerification(auth.currentUser, {
            url: `${process.env.REACT_APP_CLIENT_URL}${newRoute}`,
            handleCodeInApp: true,
          })
        })
        setBtnLoader(false)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  const loginOnSubmit = async (formData) => {
    console.log('formData', formData)
    setBtnLoader(true)
    try {
      const userCredentials = await loginInAuth(
        formData.email,
        formData.password
      )
      const response = await loginAPI(userCredentials)
      console.log('responseAfterLogin', response)
      setCurrentUser(response)
      window.localStorage.setItem('user', JSON.stringify(response))

      if (userCredentials.user.emailVerified) {
        setBtnLoader(false)
        setLoginModal(false)
      } else {
        await sendEmailVerification(auth.currentUser, {
          url: `${process.env.REACT_APP_CLIENT_URL}${
            location.pathname + location.search
          }`,
          handleCodeInApp: true,
        })
        navigate('/welcome')
      }
    } catch (error) {
      console.log('error', error.message)
      setApiError('Invalid credentials')
      setBtnLoader(false)
    }
  }

  const handleConfirmButtonOfDialog = async (body) => {
    console.log('body', body)
    setLoading(true)
    try {
      const res = await handleRejectInviteMemberAPI(memberId, body)
      console.log('res', res)
      if (res.message === 'Invitation rejected successfully' && !userAuth) {
        navigate(
          '/'
          //  { state: { action: 'rejected' } }
        )
      }
      if (
        res.message === 'Invitation rejected successfully' &&
        userAuth.emailVerified
      ) {
        window.sessionStorage.setItem(
          'action',
          'Invitation rejected successfully'
        )
        navigate(
          '/dashboard'
          //  { state: { action: 'rejected' } }
        )
      }
    } catch (error) {
      console.log('error', error)
    }
    setOpen(false)
  }

  const handleAccept = async (body, email) => {
    console.log('body', body, 'email', email)
    setLoading(true)
    try {
      const check_email = await checkEmailExistContext({ email: email })
      console.log('check_email', check_email)
      if (
        check_email.message ===
          'User does not exist, proceed with account creation' &&
        !userAuth
      ) {
        setLoading(false)
        setCreateModal(true)
      }
      if (check_email === 'User Already have an Account' && !userAuth) {
        setLoading(false)
        setLoginModal(true)
      }
      if (
        check_email === 'User Already have an Account' &&
        userAuth &&
        userAuth.emailVerified
      ) {
        const response = await handleAcceptInviteMemberAPI(userAuth, memberId, {
          action: 'accept',
        })
        console.log('response', response)
        if (response.workspacesCount > 1) {
          navigate('/choose-workspace')
        } else {
          navigate(getLatestRoute(), { state: { action: 'accepted' } })
        }
      }
    } catch (error) {
      console.log('error', error)
    }
    setOpen(false)
  }

  const handleInvitedWorkspaceData = async (memberId, workspaceId) => {
    setLoading(true)
    try {
      const resp = await invitedWorkspaceDataAPI(memberId, workspaceId)
      console.log('memberId', memberId, 'workspaceId', workspaceId)
      if (resp?.roleStatus === 'accepted') {
        navigate('/dashboard', { state: { action: 'already accepted' } })
      } else if (resp?.roleStatus === 'rejected') {
        navigate('/', { state: { action: 'already rejected' } })
      } else if (resp?.roleStatus === 'expired') {
        window.sessionStorage.setItem('action', 'expired')
        navigate('/dashboard', { state: { action: 'expired' } })
      }
      setInvitedWorkspaceData(resp)
    } catch (error) {
      console.log('error', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    // if (!userAuth && !userAuth?.emailVerified) {
    //   return <Navigate to='/signup' replace={true} />
    // }
    const fetchData = async () => {
      const searchParams = new URLSearchParams(location.search)
      const memberId = searchParams.get('inviteId')
      const workspaceId = searchParams.get('workspaceId')
      if (memberId && workspaceId) {
        console.log('memberId', memberId, 'workspaceId', workspaceId)
        setMemberId(memberId)
        handleInvitedWorkspaceData(memberId, workspaceId)
      }
    }
    fetchData()
  }, [location.search])

  return (
    <Box sx={inviteLandingPageStyles.parentBox}>
      <NavBar />
      <Box sx={absoluteBox(theme)}></Box>

      {loading ? (
        <Box sx={inviteLandingPageStyles.circularProgress}>
          <Loader thickness={6} sx={{ color: 'blue' }} />
        </Box>
      ) : (
        <Box sx={mainCard_Style(theme)}>
          <img
            src={invitedWorkspaceData?.imageUrl}
            alt='workspace'
            height={100}
            width={100}
            style={{ borderRadius: '50%' }}
          />
          <Typography sx={workspaceNameTypo(theme)}>
            {invitedWorkspaceData?.name}
          </Typography>
          <Typography sx={categoryText(theme)}>
            {invitedWorkspaceData?.websiteLink} -{' '}
            {invitedWorkspaceData?.category}
          </Typography>
          <Typography sx={invitedTypography(theme)}>
            You have been invited to join the {invitedWorkspaceData?.name}{' '}
            workspace
          </Typography>
          <Typography sx={desc_Typography(theme)}>
            Welcome! You have been invited to join the {''}
            <b>{invitedWorkspaceData?.name}</b> Workspace as {''}
            <b>{invitedWorkspaceData?.roleName}</b> . Here, you will lead our
            social media management efforts, collaborating seamlessly with the
            team to enhance our online presence and engagement.
          </Typography>
          <Box sx={inviteLandingPageStyles.buttonParentBox}>
            <Button
              onClick={() => handleReject(true)}
              variant='outlined'
              sx={cancelButton(theme)}
            >
              Reject
            </Button>
            <Button
              onClick={() =>
                handleAccept({ action: 'accept' }, invitedWorkspaceData?.email)
              }
              variant='contained'
              sx={acceptButton(theme)}
            >
              Accept Invite
            </Button>
          </Box>
        </Box>
      )}
      <CustomDialog
        open={open}
        dialogTitle={'Reject Invitation'}
        dialogDescription={'Are you sure you want to reject this invitation?'}
        confirmButtonText={'confirm'}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmButtonOfDialog}
        icon=<SentimentDissatisfiedRounded
          sx={{ color: 'red', height: '30px', width: '30px' }}
        />
      />
      <CreateMemberForm
        open={createModal}
        onClose={() => setCreateModal(false)}
        onSubmit={onSubmit}
        invitedWorkspaceData={invitedWorkspaceData}
        btnLoader={btnLoader}
      />
      <LoginMemberForm
        open={loginModal}
        loginOnSubmit={loginOnSubmit}
        apiError={apiError}
        setApiError={setApiError}
        btnLoader={btnLoader}
      />
    </Box>
  )
}

export default InviteLandingPage
