import { WarningAmberRounded } from '@mui/icons-material'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Slide,
  Box,
  Typography,
} from '@mui/material'
import React from 'react'
import { useTheme } from '@emotion/react'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const CustomDialog = ({
  open,
  onClose,
  onConfirm,
  dialogTitle,
  dialogDescription,
  confirmButtonText,
  specialMessage,
  icon = (
    <WarningAmberRounded sx={{ color: 'red', height: '30px', width: '30px' }} />
  ),
}) => {
  const theme = useTheme()

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: '16px',
          background: theme.palette.background.default,
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          boxSizing: 'border-box',
          padding: {
            xs: '12px 10px',
            sm: '20px 50px',
          },
        },
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
      }}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '62px',
          height: '62px',
          borderRadius: '999px',
          border: '7px solid #FEFCE8',
          background: '#FEF9C3',
        }}
      >
        {icon}
      </Box>
      <DialogTitle
        sx={{
          color: theme?.palette.black.main,
          fontFamily: theme?.typography.h4.fontFamily,
          fontWeight: {
            xs: theme?.typography.h4.fontWeight,
            sm: theme?.typography.h3.fontWeight,
          },
          fontSize: {
            xs: theme.typography.mediumText.fontSize,
            sm: theme.typography.h2.fontSize,
          },
          lineHeight: 'normal',
        }}
      >
        {dialogTitle || 'Archive Workspace'}
      </DialogTitle>
      {specialMessage && (
        <Box
          sx={{
            color: theme?.palette.black.light,
            fontFamily: theme?.typography.h4.fontFamily,
            fontWeight: theme?.typography.h5.fontWeight,
            fontSize: theme.typography.h5.fontSize,
            lineHeight: 'normal',
            textAlign: 'center',
          }}
        >
          {specialMessage}
        </Box>
      )}
      <DialogContent>
        <DialogContentText
          sx={{
            color: theme?.palette.black.light,
            fontFamily: theme?.typography.h4.fontFamily,
            fontWeight: {
              xs: theme?.typography.h5.fontWeight,
              sm: theme?.typography.h4.fontWeight,
            },
            fontSize: {
              xs: theme.typography.h5.fontSize,
              sm: theme.typography.h4.fontSize,
            },
            lineHeight: 'normal',
            textAlign: 'center',
          }}
        >
          {dialogDescription ||
            'Are you sure you want to archive this workspace?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '0px',
          gap: '10px',
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            fontFamily: theme?.typography.h4.fontFamily,
            fontWeight: theme?.typography.h4.fontWeight,
            fontSize: theme.typography.h5.fontSize,
            width: '80px',
            textTransform: 'capitalize',
            border: `1px solid ${theme.palette.border.main}`,
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: theme.palette.button.dark,
              color: theme?.palette.customWhite.main,
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm({ action: 'reject' })}
          sx={{
            fontFamily: theme?.typography.h4.fontFamily,
            fontWeight: theme?.typography.h4.fontWeight,
            fontSize: theme.typography.h5.fontSize,
            width: '80px',
            textTransform: 'capitalize',
            border: `1px solid ${theme.palette.border.main}`,
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: theme.palette.button.dark,
              color: theme?.palette.customWhite.main,
            },
          }}
        >
          {confirmButtonText || 'Archive'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomDialog
