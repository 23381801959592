import {
  Box,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import signupPageStyles, {
  button_Style,
  commonSelectStyle,
  ContinueBtn,
  desc_Text,
  footer_Text,
  heading_Text,
  or_Text_Style,
  signin_Button,
} from '../signupPageStyles'
import {
  commonTextFieldStyle,
  ContinueButton,
  labelTextfield,
} from '../../SigninPage/signinPageStyles'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  companyResolver,
  companyValidator,
  emailResolver,
  emailValidator,
  firstnameResolver,
  firstnameValidator,
  lastnameResolver,
  lastnameValidator,
  passwordResolver,
  passwordValidator,
  phoneResolver,
  phoneValidator,
} from '../../../Validators/authValidator'
import fb from './../../../Assets/Images/facebook.png'
import google from './../../../Assets/Images/google.png'
import linkdin from './../../../Assets/Images/linkedin.png'
import { useNavigate } from 'react-router-dom'
import { Loader } from '@infostacks/ui-components'
import UserContext from '../../../Context/userContext'

const SignupForms = ({
  step,
  theme,
  register,
  handleSubmit,
  onSubmit,
  errors,
  touchedFields,
  handleChange,
  selectedValue,
  setSelectedValue,
  watch,
  loading,
  setLoading,
  apiError,
}) => {
  const { logInWithGoogleContext } = useContext(UserContext)

  const [phoneNumber, setPhoneNumber] = useState('')
  const [company, setCompany] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const password = watch('password')
  const confirmPassword = watch('confirmPassword')
  const navigate = useNavigate()
  return (
    <>
      {step === 1 ? (
        <form
          className='signupForm'
          onSubmit={handleSubmit(onSubmit)}
          style={signupPageStyles.stepOne_Form}
        >
          <Typography sx={heading_Text(theme)}>Create an account</Typography>
          <Typography sx={desc_Text(theme)}>
            Please provide your name and email
          </Typography>
          <Box
            sx={{
              ...signupPageStyles.field_Box,
              mt: { xs: '25px', sm: '50px' },
            }}
          >
            <label className='textfield-label' style={labelTextfield(theme)}>
              First Name
            </label>
            <div style={{ position: 'relative' }}>
              <TextField
                variant='outlined'
                placeholder='First Name'
                fullWidth
                autoComplete='off'
                {...register('firstname', firstnameValidator)}
                sx={commonTextFieldStyle(errors.firstname)}
              />
              <FormHelperText sx={signupPageStyles.helperText}>
                {errors.firstname && touchedFields.firstname
                  ? firstnameResolver[errors.firstname.type]
                  : ''}
              </FormHelperText>
            </div>
          </Box>
          <Box sx={signupPageStyles.field_Box}>
            <label className='textfield-label' style={labelTextfield(theme)}>
              Last Name
            </label>
            <div style={{ position: 'relative' }}>
              <TextField
                variant='outlined'
                placeholder='Last Name'
                fullWidth
                autoComplete='off'
                sx={commonTextFieldStyle(errors.lastname)}
                {...register('lastname', lastnameValidator)}
              />
              <FormHelperText sx={signupPageStyles.helperText}>
                {errors.lastname && touchedFields.lastname
                  ? lastnameResolver[errors.lastname.type]
                  : ''}
              </FormHelperText>
            </div>
          </Box>
          <Box sx={signupPageStyles.field_Box}>
            <label className='textfield-label' style={labelTextfield(theme)}>
              Email
            </label>
            <div style={{ position: 'relative' }}>
              <TextField
                fullWidth
                autoComplete='off'
                variant='outlined'
                placeholder='Email'
                {...register('email', emailValidator)}
                sx={commonTextFieldStyle(errors.email)}
              />
              <FormHelperText sx={signupPageStyles.helperText}>
                {errors.email && touchedFields.email
                  ? emailResolver[errors.email.type]
                  : apiError === 'User Already have an Account'
                  ? 'Email already in use'
                  : ''}
              </FormHelperText>
            </div>
          </Box>
          <Box sx={signupPageStyles.devider_MainBox}>
            <Divider
              orientation='horizontal'
              sx={signupPageStyles.devider_Style(theme)}
            />
            <Typography sx={or_Text_Style(theme)}>or</Typography>
            <Divider
              orientation='horizontal'
              sx={signupPageStyles.devider_Style(theme)}
            />
          </Box>
          <Box sx={signupPageStyles.socialBtn_Box}>
            <Button
              onClick={logInWithGoogleContext}
              variant='outlined'
              sx={button_Style(theme)}
              startIcon={
                <img
                  src={google}
                  alt='google'
                  style={signupPageStyles.image_Style}
                />
              }
            >
              Google
            </Button>
            {/* <Button
                  variant='outlined'
                  startIcon={
                    <img
                      src={fb}
                      alt='facebook'
                      style={signupPageStyles.image_Style}
                    />
                  }
              sx={button_Style(theme)}
                >
                  Facebook
                </Button>
                <Button
                  variant='outlined'
                  startIcon={
                    <img
                      src={linkdin}
                      alt='linkedin'
                      style={signupPageStyles.image_Style}
                    />
                  }
              sx={button_Style(theme)}
                >
                  LinkedIn
                </Button> */}
          </Box>
          <Button
            type='submit'
            variant='contained'
            sx={ContinueButton(theme)}
            onClick={handleSubmit(onSubmit)}
          >
            {loading ? <Loader thickness={7} size={30} /> : 'Continue'}
          </Button>
          <Box sx={signupPageStyles.countinue_MainBox}>
            <Typography sx={footer_Text(theme)}>
              Already Have an Account
            </Typography>
            <Button
              onClick={() => navigate('/signin')}
              variant='text'
              color='black'
              sx={signin_Button(theme)}
            >
              Sign in here
            </Button>
          </Box>
        </form>
      ) : step === 2 ? (
        <>
          <form
            className='signupForm'
            onSubmit={handleSubmit(onSubmit)}
            style={signupPageStyles.stepOne_Form}
          >
            <Typography sx={heading_Text(theme)}>Add Details</Typography>
            <Typography sx={desc_Text(theme)}>
              Please Provide your company name and other info{' '}
            </Typography>
            <Box sx={{ ...signupPageStyles.field_Box, mt: '50px' }}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Company Name
              </label>
              <div style={{ position: 'relative' }}>
                <TextField
                  sx={commonTextFieldStyle(errors.company)}
                  fullWidth
                  variant='outlined'
                  placeholder='Company'
                  onChange={(e) => setCompany(e.target.value)}
                  autoComplete='off'
                  {...register('company', companyValidator)}
                />
                <FormHelperText sx={signupPageStyles.helperText}>
                  {errors.company && touchedFields.company
                    ? companyResolver[errors.company?.type]
                    : ''}
                </FormHelperText>
              </div>
            </Box>
            <Box sx={signupPageStyles.field_Box}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Country Name
              </label>
              <div style={signupPageStyles.select_divStyle}>
                <Select
                  sx={commonSelectStyle(errors.company)}
                  fullWidth
                  value={selectedValue}
                  onChange={handleChange}
                >
                  <MenuItem value={'Pakistan'}>Pakistan</MenuItem>
                  <MenuItem value={'USA'}>USA</MenuItem>
                  <MenuItem value={'Canada'}>Canada</MenuItem>
                </Select>
              </div>
            </Box>
            <Box sx={signupPageStyles.field_Box}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Phone Number
              </label>
              <div style={{ position: 'relative' }}>
                <TextField
                  {...register('phoneNumber', phoneValidator)}
                  sx={commonTextFieldStyle(errors.phoneNumber)}
                  fullWidth
                  value={phoneNumber}
                  type='tel'
                  autoComplete='off'
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  variant='outlined'
                  placeholder='Write a number'
                />
                <FormHelperText sx={signupPageStyles.helperText}>
                  {errors.phoneNumber && touchedFields.phoneNumber
                    ? phoneResolver[errors.phoneNumber?.type]
                    : ''}
                </FormHelperText>
              </div>
            </Box>
            <Button
              type='submit'
              variant='contained'
              sx={ContinueBtn(theme)}
              onClick={handleSubmit(onSubmit)}
            >
              Continue
            </Button>
          </form>
        </>
      ) : step === 3 ? (
        <>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='signupForm'
            style={signupPageStyles.stepOne_Form}
          >
            <Typography sx={heading_Text(theme)}>Choose a Password</Typography>
            <Typography sx={desc_Text(theme)}>
              Password must be at least 8 characters
            </Typography>
            <Box sx={{ ...signupPageStyles.field_Box, mt: '50px' }}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Password
              </label>
              <div style={{ position: 'relative' }}>
                <TextField
                  sx={commonTextFieldStyle(errors.password)}
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  variant='outlined'
                  placeholder='Password'
                  autoComplete='off'
                  {...register('password', passwordValidator)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge='end'
                          style={{
                            fontSize: '0.6rem',
                            marginRight: 'px',
                          }}
                        >
                          {showPassword ? (
                            <Visibility fontSize='small' />
                          ) : (
                            <VisibilityOff fontSize='small' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText sx={signupPageStyles.helperText}>
                  {errors.password && touchedFields.password
                    ? passwordResolver[errors.password?.type]
                    : ''}
                </FormHelperText>
              </div>
            </Box>

            <Box sx={signupPageStyles.field_Box}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Confirm password
              </label>
              <div style={{ position: 'relative' }}>
                <TextField
                  sx={commonTextFieldStyle(errors.confirmPassword)}
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  variant='outlined'
                  placeholder='Confirm password'
                  autoComplete='off'
                  {...register('confirmPassword', {
                    required: 'Confirm Password is required',
                    validate: (value) =>
                      value === password || 'Passwords do not match',
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge='end'
                          style={{
                            fontSize: '0.6rem',
                            marginRight: 'px',
                          }}
                        >
                          {showPassword ? (
                            <Visibility fontSize='small' />
                          ) : (
                            <VisibilityOff fontSize='small' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText sx={signupPageStyles.helperText}>
                  {errors.confirmPassword && touchedFields.confirmPassword
                    ? errors.confirmPassword.message
                    : ''}
                </FormHelperText>
              </div>
            </Box>
            <Button
              type='submit'
              variant='contained'
              sx={ContinueBtn(theme)}
              onClick={handleSubmit(onSubmit)}
            >
              {loading ? <Loader thickness={7} size={30} /> : 'Continue'}
            </Button>
          </form>
        </>
      ) : null}
    </>
  )
}

export default SignupForms
