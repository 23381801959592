import {
  Box,
  Button,
  Card,
  Pagination,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import activeSectionStyle from './activeSectionStyle'
import { FlagCircleOutlined } from '@mui/icons-material'
import FacebookPostModal from '../FacebookPostModal/facebookPostModal'
import UserContext from '../../../../../Context/userContext'
import SpaceSkeleten from '../../../DashboardMainSection/spaceSkeleten'
import { useTheme } from '@emotion/react'
import {
  addPost_Button_Style,
  card_Main,
  description,
  footerText,
  name,
} from '../facebookServicesStyle'

const PageSection = ({
  handleModalOpen,
  openModal,
  pagesNames,
  pagesData,
  spaceId,
  postedAndScheduledCount,
}) => {
  const { setSpecificPages, isSkelton, setButtonStatus, currentUser } =
    useContext(UserContext)
  console.log('pagesData in page section', pagesData)
  console.log('pagesNames in page section', pagesNames)
  const isXs = useMediaQuery('(max-width:600px)')
  const isSm = useMediaQuery('(min-width:601px) and (max-width:899px)')
  const isMd = useMediaQuery('(min-width:900px) and (max-width:1200px)')
  const isLg = useMediaQuery('(min-width:1201px) and (max-width:1535px)')
  const isXl = useMediaQuery('(min-width:1536px)')

  const cardsPerPage = isXs || isSm ? 1 : isMd ? 2 : isLg ? 2 : isXl ? 3 : null

  const [page, setPage] = useState(1)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const getName = (name) => {
    setSpecificPages(name)
  }

  const startIndex = (page - 1) * cardsPerPage
  const endIndex = startIndex + cardsPerPage
  const theme = useTheme()
  return (
    <>
      {isSkelton ? (
        <Box sx={activeSectionStyle.MapContainerStyle(theme)}>
          {Array.from({ length: cardsPerPage }).map((_, index) => (
            <SpaceSkeleten key={index} flexDirection={'row'} />
          ))}
        </Box>
      ) : (
        <Box sx={activeSectionStyle.MapContainerStyle(theme)}>
          {pagesData?.slice(startIndex, endIndex).map((value, index) => (
            <Card key={index} sx={card_Main(theme)}>
              <Box sx={activeSectionStyle.img_Box(theme)}>
                <img
                  src={value.picture.data.url || <FlagCircleOutlined />}
                  alt=''
                  height={40}
                  width={40}
                  style={{ borderRadius: '50%' }}
                />
                <Typography sx={name(theme)}>{value.name}</Typography>
              </Box>
              <Typography sx={description(theme)}>
                {value.description || 'Your page description show here'}
              </Typography>
              <Box sx={activeSectionStyle.cardFooter}>
                <Typography sx={footerText(theme)}>
                  {value.followers_count || '0'} Followers
                </Typography>
                <Button
                  disabled={
                    postedAndScheduledCount >=
                      currentUser?.Subscription?.posts &&
                    currentUser?.Subscription?.planType === 'basic'
                  }
                  onClick={() => {
                    handleModalOpen(true)
                    getName(value.name)
                    setButtonStatus('Publish')
                  }}
                  variant='contained'
                  sx={addPost_Button_Style(
                    theme,
                    postedAndScheduledCount,
                    currentUser
                  )}
                >
                  Create post
                </Button>
              </Box>
            </Card>
          ))}
        </Box>
      )}
      {pagesData.length > cardsPerPage ? (
        <Box sx={activeSectionStyle.pagination_Box}>
          <Pagination
            color='primary'
            count={Math.ceil(pagesData.length / cardsPerPage)}
            page={page}
            onChange={handleChangePage}
            sx={activeSectionStyle.pagination_Style}
          />
        </Box>
      ) : null}
      {openModal && (
        <FacebookPostModal
          handleModalOpen={handleModalOpen}
          pagesNames={pagesNames}
          open={openModal}
          pagesData={pagesData}
          spaceId={spaceId}
        />
      )}
    </>
  )
}

export default PageSection
