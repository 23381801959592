import * as React from 'react'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker'
import { Box, Button } from '@mui/material'
import { useTheme } from '@emotion/react'

const CustomCalendar = ({ confirmDate }) => {
  const [selectedDate, setSelectedDate] = React.useState(dayjs(new Date()))
  console.log('selectedDate when calendar appear', selectedDate)

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handleApplyClick = () => {
    const formattedDate = selectedDate.second(0).millisecond(0).toISOString()
    console.log('Selected date & Time:', formattedDate)
    confirmDate(formattedDate, false)
  }
  const theme = useTheme()
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{
        calendarWeekNumberHeaderLabel: 'd',
        cancelButtonLabel: '',
        okButtonLabel: '',
        dateRangePickerToolbarTitle: 'ddddd',
      }}
    >
      <Box
        sx={{
          height: '400px',
          width: '340px',
          background: '',
          mx: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <StaticDateTimePicker
          slotProps={{
            toolbar: { hidden: true },
            actionBar: { actions: ['cancel', 'accept'] },
            tabs: { hidden: false },
          }}
          defaultValue={dayjs(new Date())}
          onChange={handleDateChange}
          ampmInClock
          disablePast
        />
        <style>{`
            .MuiDialogActions-root {
              display: none; 
            },
          `}</style>
        <Button
          variant='contained'
          sx={{
            ml: 'auto',
            textTransform: 'capitalize',
            borderRadius: '8px',
            fontWeight: theme.typography.menualText.fontWeight,
            fontFamily: theme.typography.menualText.fontFamily,
            backgroundColor: theme.palette.button.dark,
            '&:hover': {
              backgroundColor: theme.palette.button.dark,
              color: theme.palette.customWhite.main,
            },
          }}
          onClick={handleApplyClick}
        >
          Apply
        </Button>
      </Box>
    </LocalizationProvider>
  )
}

export default CustomCalendar
