const paymentSuccessfullStyle = {
  parent_Box: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    background: theme.palette.background.default,
  }),
  middle_Box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '459px',
    height: 'fit-content',
    gap: '32px',
    background: '',
  },
  main_Box: {
    height: '130px',
    width: '130px',
    backgroundColor: '#CCFBF1',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '24px',
    boxSizing: 'border-box',
  },
  span_Style: {
    width: '459px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    background: '',
    gap: '8px',
  },
  svgBox: {
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: '#99F6E4',
    width: '94px',
    height: '94px',
    boxSizing: 'border-box',
    padding: '23px 29px',
  },
}

export default paymentSuccessfullStyle
export const welcome_Style = (theme) => ({
  height: '48px',
  background: theme.palette.background.default,
  color: theme?.palette.black.main,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h1.fontWeight,
  fontSize: '40px',
  lineHeight: 'normal',
  letterSpacing: '-1.6px',
  fontStyle: 'normal',
  textAlign: 'center',
})
export const congratulations_Style = (theme) => ({
  color: theme?.palette.black.light,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h4.fontWeight,
  fontSize: theme.typography.h3.fontSize,
  lineHeight: '27px',
  background: theme.palette.background.default,
  fontStyle: 'normal',
  textAlign: 'center',
  letterSpacing: '-0.1px',
})
export const countinueButton = (theme) => ({
  backgroundColor: theme.palette.button.dark,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h3.fontWeight,
  fontSize: theme.typography.h4.fontSize,
  lineHeight: '24px',
  textAlign: 'center',
  textTransform: 'capitalize',
  borderRadius: '14px',
  padding: ' 8px 42px',
  color: theme.palette.customWhite.main,
  ':hover': { backgroundColor: theme.palette.button.dark },
})
