import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table'
import like from '../../Assets/Images/like.png'
import comment from '../../Assets/Images/comment.png'
import share from '../../Assets/Images/share.png'
import { useTheme } from '@emotion/react'
import { PostableButtons } from '../ReusableComponents/customButtons'
import {
  Box,
  Checkbox,
  Chip,
  Pagination,
  TextField,
  Typography,
} from '@mui/material'
import {
  deleteFacebookPostApi,
  getFacebookEngagements,
  getFeedsForAllPages,
} from '../../API/PlatformsAPIs/Facebook'
import UserContext from '../../Context/userContext'
import CustomToolTip from '../ReusableComponents/customToolTip'
import {
  AutorenewRounded,
  DeleteOutline,
  EditOutlined,
  RemoveRedEyeOutlined,
  Replay,
} from '@mui/icons-material'
import TableDialog from '../Dashboard/PlatForms/Facebook/ActiveSection/dialog'
import TableSkeleten from './tableSkelton'
import PostViewModal from '../Dashboard/PlatForms/Facebook/ActiveSection/postViewModal'
const CustomTableTanstack = ({
  pagesData,
  openModel,
  handleModalOpen,
  spaceId,
  basePath,
  postedAndScheduledCount,
  setPostedAndScheduledCount,
}) => {
  const {
    userAuth,
    setPostData,
    isSkelton,
    setIsSkelton,
    setButtonStatus,
    setSpecificPages,
    stateCheck,
  } = useContext(UserContext)
  const [sorting, setSorting] = useState([])
  const [filter, setFilter] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [feedDataForDialog, setFeedDataForDialog] = useState()
  const [apiData, setApiData] = useState([])

  const [activeButton, setActiveButton] = useState('all')

  const sessId = sessionStorage.getItem('spaceId')

  const cardsPerPage = 5
  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage - 1)
  }

  const deletePost = async (pageId, postId, workspaceId) => {
    console.log('delete id', workspaceId)
    setOpenDialog(false)
    if (userAuth) {
      try {
        const response = await deleteFacebookPostApi(
          userAuth,
          pageId,
          postId,
          workspaceId
        )
        console.log('delete response', response)
        // const spaceId = localStorage.getItem('spaceId')
        await allTableFeed(workspaceId)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
  }

  const getEngagements = async (pageId, postId, workspaceId) => {
    if (userAuth) {
      try {
        const response = await getFacebookEngagements(
          userAuth,
          pageId,
          postId,
          workspaceId
        )
        // const spaceId = localStorage.getItem('spaceId')
        await allTableFeed(workspaceId)
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  const repost = (data, status) => {
    handleModalOpen(true)
    setButtonStatus(status)
    setSpecificPages(data.pageName)
    if (data.link === null) {
      data.link = ''
      setPostData(data)
    }
    setPostData(data)
  }

  const editPost = (data, status) => {
    setButtonStatus(status)
    setSpecificPages(data.pageName)
    if (data.link === null) {
      data.link = ''
      setPostData(data)
    }
    setPostData(data)
    handleModalOpen(true)
  }

  const theme = useTheme()

  const handlePostViewModal = (state) => {
    setIsModalOpen(state)
  }

  const view = (data, state) => {
    handlePostViewModal(state)
    setPostData(data)
  }

  const allTableFeed = async (spaceId, status) => {
    setIsSkelton(true)
    try {
      const response = await getFeedsForAllPages(userAuth, spaceId)
      console.log('response', response)
      setPostedAndScheduledCount(
        response.filter(
          (obj) =>
            obj.status === 'posted' ||
            obj.status === 'scheduled' ||
            obj.status === 'deleted'
        ).length
      )

      if (Array.isArray(response)) {
        const transformedRows = response
          .filter((item) => !status || item.status === status)
          .map((data, index) => ({
            id: data._id,
            name: data.pageName,
            post: data.message ? data.message : 'no post description',
            profileImage: data.profilePic,
            status: data.status,
            date: formatDate(data.createdAt),
            time: formatTime(data.createdAt),
            link: data.link,
            engagements: (
              <Box
                sx={{
                  background: theme.palette.background.default,
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '150px',
                }}
              >
                <Box
                  sx={{
                    background: theme.palette.background.default,
                    display: 'flex',
                    height: '90%',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <img src={like} alt='like' height={25} width={25} />
                  <Typography sx={{ fontSize: '10px' }}>
                    {data.engagements.reactionsCount}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    background: theme.palette.background.default,
                    display: 'flex',
                    height: '90%',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <img src={comment} alt='comment' />
                  <Typography sx={{ fontSize: '10px' }}>
                    {data.engagements.commentsCount}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    background: theme.palette.background.default,
                    display: 'flex',
                    height: '90%',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <img src={share} alt='share' />
                  <Typography sx={{ fontSize: '10px' }}>
                    {data.engagements.sharesCount}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: '90%',
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'end',
                    background: '',
                  }}
                >
                  <CustomToolTip
                    onClick={() =>
                      getEngagements(data.pageId, data._id, data.workspaceId)
                    }
                    disabled={[
                      'deleted',
                      'scheduled',
                      'drafted',
                      'failed',
                    ].includes(data.status)}
                    title={
                      ['deleted', 'scheduled', 'drafted', 'failed'].includes(
                        data.status
                      )
                        ? ''
                        : 'Refresh Engagements'
                    }
                    icon={
                      <Replay
                        sx={{
                          color: [
                            'deleted',
                            'scheduled',
                            'drafted',
                            'failed',
                          ].includes(data.status)
                            ? ''
                            : 'grey',
                        }}
                      />
                    }
                  />
                </Box>
              </Box>
            ),

            actions: (
              <Box
                sx={{
                  display: 'flex',
                  background: theme.palette.background.default,
                  width: '170px',
                }}
              >
                <CustomToolTip
                  disabled={data.status === 'failed'}
                  title={
                    ['failed'].includes(data.status) ? "Can't view" : 'View'
                  }
                  icon={
                    <RemoveRedEyeOutlined
                      sx={{
                        color: ['failed'].includes(data.status) ? '' : 'grey',
                      }}
                    />
                  }
                  onClick={() => view(data, true)}
                />
                <CustomToolTip
                  title={
                    ['scheduled', 'drafted'].includes(data.status)
                      ? "Can't repost"
                      : 'Repost'
                  }
                  icon={
                    <AutorenewRounded
                      sx={{
                        color: ['failed', 'scheduled', 'drafted'].includes(
                          data.status
                        )
                          ? ''
                          : 'grey',
                      }}
                    />
                  }
                  disabled={
                    ['scheduled', 'drafted'].includes(data.status)
                      ? true
                      : false
                  }
                  onClick={() => repost(data, 'Repost')}
                />
                <CustomToolTip
                  title={
                    ['deleted', 'failed'].includes(data.status)
                      ? "Can't delete"
                      : 'Delete'
                  }
                  icon={
                    <DeleteOutline
                      sx={{
                        color: ['failed', 'deleted'].includes(data.status)
                          ? ''
                          : 'grey',
                      }}
                    />
                  }
                  disabled={
                    ['deleted', 'failed'].includes(data.status) ? true : false
                  }
                  onClick={() => {
                    setOpenDialog(true)
                    setFeedDataForDialog(data)
                  }}
                />
                <CustomToolTip
                  title={
                    ['deleted', 'failed'].includes(data.status)
                      ? "Can't edit"
                      : data.link && data.status === 'posted'
                      ? "Can't edit"
                      : 'Edit'
                  }
                  icon={
                    <EditOutlined
                      sx={{
                        color: ['deleted', 'failed'].includes(data.status)
                          ? ''
                          : data && data.status === 'posted'
                          ? ''
                          : 'grey',
                      }}
                    />
                  }
                  onClick={() => editPost(data, 'Edit')}
                  disabled={
                    ['deleted', 'failed'].includes(data.status)
                      ? true
                      : data && data === 'posted'
                      ? true
                      : false
                  }
                />
              </Box>
            ),
          }))

        setApiData(transformedRows)
        setIsSkelton(false)
      } else {
        console.error('Unexpected response format:', response)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  const fetchData = async (status) => {
    if (userAuth && sessId === spaceId) {
      const allPosts = await allTableFeed(spaceId, status)
    }
  }
  useEffect(() => {
    fetchData()
  }, [userAuth, stateCheck, basePath, spaceId])
  console.log(
    'pageData',
    pagesData.map((val) => val.id)
  )

  const buttonData = [
    {
      label: 'All',
      color: '#000',
      hoverColor: '#77DD77',
      hoverTextColor: 'white',
      filter: 'all',
    },
    {
      label: 'Posted',
      color: '#115E59',
      hoverColor: '#CCFBF1',
      hoverTextColor: '#115E59',
      filter: 'posted',
    },
    {
      label: 'Scheduled',
      color: '#2563EB',
      hoverColor: '#60A5FA',
      hoverTextColor: '#2563EB',
      filter: 'scheduled',
    },
    {
      label: 'Draft',
      color: '#6B7280',
      hoverColor: '#E5E7EB',
      hoverTextColor: '#6B7280',
      filter: 'drafted',
    },
    {
      label: 'Deleted',
      color: '#991B1B',
      hoverColor: '#FEE2E2',
      hoverTextColor: '#991B1B',
      filter: 'deleted',
    },
    {
      label: 'Failed',
      color: '#EF4444',
      hoverColor: '#F87171',
      hoverTextColor: '#EF4444',
      filter: 'failed',
    },
  ]
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Page',
        cell: (info) => (
          <Box
            sx={{
              background: theme.palette.background.default,
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              width: '160px',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {/* <Checkbox sx={{ height: '20px', width: '20px' }} /> */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // ml: '10px',
              }}
            >
              <img
                src={info.row.original.profileImage}
                alt='Avatar'
                style={{
                  width: 30,
                  height: 30,
                  marginRight: 10,
                  borderRadius: '50%',
                }}
              />
              <Typography
                sx={{
                  fontFamily: 'Sora',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '20px',
                }}
              >
                {info.getValue()}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'post',
        header: 'Post',
        cell: (info) => (
          <Typography
            sx={{
              width: '170px',
              background: theme.palette.background.default,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontFamily: 'Sora',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            }}
          >
            {info.getValue()}
          </Typography>
        ),
      },
      {
        accessorKey: 'date',
        header: 'Date',
        cell: (info) => (
          <Typography
            sx={{
              width: '90px',
              background: theme.palette.background.default,
              fontFamily: 'Sora',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            }}
          >
            {info.getValue()}
          </Typography>
        ),
      },
      {
        accessorKey: 'time',
        header: 'Time',
        cell: (info) => (
          <Typography
            sx={{
              width: '70px',
              background: theme.palette.background.default,
              fontFamily: 'Sora',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            }}
          >
            {info.getValue()}
          </Typography>
        ),
      },
      {
        accessorKey: 'engagements',
        header: 'Engagements',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: (info) => (
          <Chip
            sx={{
              width: '100px',
              fontFamily: 'Sora',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '14px',
              backgroundColor:
                info.getValue() === 'posted'
                  ? '#CCFBF1'
                  : info.getValue() === 'drafted'
                  ? '#F3F4F6'
                  : info.getValue() === 'scheduled'
                  ? '#DBEAFE'
                  : info.getValue() === 'deleted'
                  ? '#FEE2E2'
                  : info.getValue() === 'failed'
                  ? '#FEE2E2'
                  : null,
              color:
                info.getValue() === 'posted'
                  ? '#115E59'
                  : info.getValue() === 'drafted'
                  ? '#1F2937'
                  : info.getValue() === 'scheduled'
                  ? '#1E40AF'
                  : info.getValue() === 'deleted'
                  ? '#991B1B'
                  : info.getValue() === 'failed'
                  ? '#991B1B'
                  : null,
            }}
            label={info.getValue()}
          />
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Action',
        width: '150px',
        cell: (info) => info.getValue(),
      },
    ],
    []
  )
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const dateFormet = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    })
    const timeFormet = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    return dateFormet.format(date)
  }

  const formatTime = (dateString) => {
    const date = new Date(dateString)
    const dateFormet = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    })
    const timeFormet = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    return timeFormet.format(date)
  }

  const table = useReactTable({
    data: apiData,
    columns,
    state: {
      sorting,
      globalFilter: filter,
      pagination: {
        pageIndex,
        pageSize: cardsPerPage,
      },
    },
    pageCount: Math.ceil(apiData.length / cardsPerPage),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setFilter,
  })

  const handleStatusFilter = (status) => {
    console.log('status', status)
    if (status === 'all') {
      fetchData()
      setActiveButton(status)
      setFilter('')
    } else {
      fetchData(status)
      setActiveButton(status)
      setFilter(status)
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          flexDirection: 'column',
          width: '90%',
        }}
      >
        <Typography
          sx={{
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: 'normal',
            fontFamily: 'Sora',
            color: '#000',
            letterSpacing: '-0.96px',
          }}
        >
          Recent's Post
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', gap: '8px' }}>
            {buttonData.map((button, index) => (
              <PostableButtons
                style={{
                  backgroundColor:
                    activeButton === button.filter ? button.hoverColor : '',
                  color:
                    activeButton === button.filter
                      ? button.hoverTextColor
                      : button.color,
                  border:
                    activeButton === button.filter
                      ? `1px solid ${button.hoverTextColor}`
                      : '',
                }}
                key={index}
                variant='outlined'
                isActive={activeButton === button.filter}
                color={button.color}
                hoverColor={button.hoverColor}
                hoverTextColor={button.hoverTextColor}
                onClick={() => handleStatusFilter(button.filter)}
              >
                {button.label}
              </PostableButtons>
            ))}
          </Box>
          <Box
            sx={{
              width: '40%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TextField
              fullWidth
              variant='outlined'
              placeholder='Search Here'
              onChange={(e) => setFilter(e.target.value)}
              sx={{
                background: '#F9F9FF',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '&:hover fieldset': {
                    borderColor: '#C6C6CE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#241BFF',
                  },
                  '& fieldset': {
                    borderColor: '#C6C6CE',
                  },
                  '& .MuiOutlinedInput-input': {
                    height: { xs: '15px', sm: '8px' },
                  },
                },
              }}
            />
          </Box>
        </Box>
        <Box
          className='tanstackscrollbar'
          sx={{
            overflowX: 'auto',
            border: '1px solid #DADAFF',
            borderRadius: '11px',
            marginTop: '16px',
            backgroundColor: '#CCE0FF',
            width: '100%',
          }}
        >
          <table
            style={{
              width: '100%',
              borderCollapse: 'separate',
              borderSpacing: '0px',
              overflowX: 'auto',
            }}
          >
            <thead
              style={{
                width: '100%',
                backgroundColor: '#CCE0FF',
                fontSize: theme.typography.h4.fontSize,
                fontWeight: theme.typography.h2.fontWeight,
                fontFamily: theme.typography.h4.fontFamily,
                textAlign: 'left',
                lineHeight: '24px',
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <td
                      key={header.id}
                      style={{
                        padding: '0 8px',
                        boxSizing: 'border-box',
                        borderBottom: '1px solid #DADAFF',
                        height: '50px',
                        background: theme.palette.background.default,
                        color: '#000',
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {isSkelton
                ? Array.from({ length: cardsPerPage }).map((_, index) => (
                    <tr key={index}>
                      <td colSpan={columns.length}>
                        <TableSkeleten key={index} />
                      </td>
                    </tr>
                  ))
                : table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      style={{
                        backgroundColor:
                          row.index % 2 === 0 ? '#f9f9f9' : 'white',
                        borderRadius: '8px',
                        borderBottom: '1px solid #DADAFF',
                      }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          style={{
                            padding: '0 8px',
                            height: '50px',
                            boxSizing: 'border-box',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: '#000',
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
            </tbody>
            <tfoot
              style={{
                height: '50px',
                backgroundColor: '#CCE0FF',
                fontSize: theme.typography.h4.fontSize,
                fontWeight: theme.typography.h2.fontWeight,
                textAlign: 'left',
              }}
            >
              <tr>
                <td colSpan={columns.length}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      width: '100%',
                    }}
                  >
                    <Pagination
                      variant='outlined'
                      shape='rounded'
                      color='primary'
                      count={Math.ceil(apiData.length / cardsPerPage)}
                      page={pageIndex + 1}
                      onChange={handleChangePage}
                      sx={{
                        '& .MuiPaginationItem-root': {
                          color: '#6B7280',
                          border: '1px solid #E5E7EB',
                          borderRadius: '50%',
                          width: '38px',
                          height: '38px',
                        },
                        '& .MuiPaginationItem-page.Mui-selected': {
                          backgroundColor: '#FFF',
                          border: '1px solid  #FFF,',
                        },
                        '& .MuiPaginationItem-page:hover': {
                          backgroundColor: '#FFF',
                        },
                        '& .MuiPaginationItem-ellipsis': {
                          color: '#6B7280',
                          textAlign: 'center',
                          fontFamily: 'Sora',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          lineHeight: '24px',
                          letterSpacing: '0.08px',
                        },
                        '& .MuiPaginationItem-icon': {
                          color: '#4B5563',
                        },
                      }}
                    />
                  </Box>
                </td>
              </tr>
            </tfoot>
          </table>
        </Box>
      </Box>
      <TableDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        deletePost={deletePost}
        feedDataForDialog={feedDataForDialog}
      />
      <PostViewModal
        open={isModalOpen}
        handlePostViewModal={handlePostViewModal}
      />
    </>
  )
}

export default CustomTableTanstack
