const DashboardMainSectionStyle = {
  spaceParentBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    boxSizing: 'border-box',
    background: '',
    width: '100%',
    // height:'700px'
  },
  skelton_Box: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    boxSizing: 'border-box',
    background: '',
  },
  first_section_of_space_main_box: {
    display: 'flex',
    justifyContent: '',
    background: '',
    width: '100%',
    gap: '16px',
  },

  spaceImageStyle: (theme) => ({
    height: '48px',
    width: '48px',
    borderRadius: '50%',
    objectFit: 'cover',
    border: `2px solid ${theme.palette.sky.light}`,
    backgroundPosition: 'center',
  }),
  space_detail_box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '4px',
    background: '',
    width: 'fit-content',
  },
  avtarBox: {
    width: '100%',
    display: 'flex',
    background: ' ',
    justifyContent: 'start',
  },
  avtarGroup: {
    display: 'flex',
    '& .MuiAvatar-root': {
      height: '30px',
      width: '30px',
    },
    '& .MuiAvatarGroup-avatar': {
      background: '#FFF',
      color: '#1F2937',
      height: '30px',
      width: '30px',
      border: '1px solid #E5E7EB',
    },
  },
  avtarStyle: (theme) => ({
    width: '30px',
    height: '30px',
  }),
  iconButtonStyle: {
    height: '30px',
    width: '30px',
    mt: '2px',
    borderRadius: '50%',
    backgroundColor: 'darkgray',
    '&:hover': { backgroundColor: 'darkgray' },
  },
  date_and_seeMore_box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '',
    width: '100%',
  },
  pagination_Box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '',
  },
  space_seeMore_button: { height: '20px', width: '25px' },
}
export const parentDiv = (theme) => ({
  background: theme.palette.background.default,
  // background:'pink',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: '24px',
  boxSizing: 'border-box',
  padding: '20px',
})

export default DashboardMainSectionStyle
export const space_Main_Box = (theme) => ({
  width: {
    xs: 'fit-content',
    sm: '320px',
    md: '358px',
  },
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  gap: '12px',
  borderRadius: '16px',
  boxSizing: 'border-box',
  background: theme?.palette.gray.main,
  border: `1px solid ${theme.palette.sky.light}`,
  cursor: 'pointer',
})
export const space_name = (theme) => ({
  color: theme?.palette.text.main,
  fontSize: theme.typography.mediumText.fontSize,
  fontWeight: theme?.typography.h1.fontWeight,
  fontFamily: theme?.typography.menualText.fontFamily,
  lineHeight: 'normal',
  cursor: 'pointer',
  background: '',
  height: 'fit-content',
  '&:hover': { textDecoration: 'underline' },
})
export const space_website_and_category = (theme) => ({
  color: theme?.palette.text.main,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme?.typography.h5.fontWeight,
  fontFamily: theme?.typography.menualText.fontFamily,

  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  height: 'fit-content',
  background: '',
  lineHeight: 'normal',
})
export const space_description = (theme) => ({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  color: theme?.palette.text.main,
  fontFamily: theme?.typography.menualText.fontFamily,

  fontWeight: theme?.typography.h5.fontWeight,
  width: '100%',
  height: '55px',
  lineHeight: '1.3',
  fontSize: theme.typography.h5.fontSize,
})
export const workspace_date = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.menualText.fontFamily,

  color: theme?.palette.text.main,
  height: 'fit-content',
})
export const space_favourite_button = (val, theme) => ({
  background: '',
  height: '25px',
  width: '25px',
  ml: 'auto',
  color: `${val.isFavourite === true ? 'gold' : theme.palette.border.star}`,
})
export const create_new_space_box = (theme) => ({
  paddingX: {
    xs: '68px',
    sm: '64px',
  },
  background: theme?.palette.gray.main,
  height: '208px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '32px',
  borderRadius: '16px',
  boxSizing: 'border-box',
  boxShadow:
    '0px 0px 1px 0px rgba(82, 82, 137, 0.08), 0px 2px 2px 0px rgba(82, 82, 137, 0.07), 0px 4px 2px 0px rgba(82, 82, 137, 0.04), 0px 7px 3px 0px rgba(82, 82, 137, 0.01), 0px 11px 3px 0px rgba(82, 82, 137, 0.00)',
})
export const create_new_space_box_Typography = (theme) => ({
  fontSize: {
    xs: theme.typography.mediumText.fontSize,
    sm: theme.typography.h2.fontSize,
  },
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.menualText.fontFamily,

  color: theme?.palette.black.main,
  lineHeight: '1',
})
export const create_new_space_box_button = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  background: theme?.palette.gray.light,
  height: '40px',
  width: '40px',
})
export const dialogStyle = {
  warningAmberRounded_Style: {
    background: '',
    color: 'red',
    height: '30px',
    width: '30px',
  },
  Icon_Box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'var(--Components-Static-Icons-Icon-Height-LG, 62px)',
    height: 'var(--Components-Static-Icons-Icon-Height-LG, 62px)',
    borderRadius:
      'var(--Components-Static-Icons-Circular-Border-Radius, 999px)',
    border:
      '7px solid var(--Components-Static-Icons-Soft-Outlined-Yellow-Border-Color, #FEFCE8)',
    background:
      'var(--Components-Static-Icons-Soft-Outlined-Yellow-Bg-Color, #FEF9C3)',
  },
  warningAmberRoundedStyle: {
    background: '',
    color: 'red',
    height: '30px',
    width: '30px',
  },

  dialogActions: {
    background: '',
    padding: '0px',
    gap: '10px',
  },
}
export const dialog_Parent = (theme) => ({
  borderRadius: '16px',
  background: theme.palette.background.default,
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: {
    xs: '12px 10px',
    sm: 'var(--Components-Static-Icons-Icon-Height-LG, 20px) 50px',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
})
export const archive_Workspaces_Text = (theme) => ({
  color: theme?.palette.black.main,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: {
    xs: theme?.typography.h4.fontWeight,
    sm: theme?.typography.h3.fontWeight,
  },
  fontSize: {
    xs: theme.typography.mediumText.fontSize,
    sm: theme.typography.h2.fontSize,
  },
  lineHeight: 'normal',
})
export const paragraoh_Text = (theme) => ({
  color: theme?.palette.black.light,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: {
    xs: theme?.typography.h5.fontWeight,
    sm: theme?.typography.h4.fontWeight,
  },
  fontSize: {
    xs: theme.typography.h5.fontSize,
    sm: theme.typography.h4.fontSize,
  },
  lineHeight: 'normal',
  textAlign: 'center',
})
export const button_style = (theme) => ({
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h4.fontWeight,
  fontSize: theme.typography.h5.fontSize,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '8px 16px',
  textTransform: 'capitalize',
  background: theme.palette.button.dark,
  color: theme?.palette.customWhite.main,
  borderRadius: 'var(--Components-Button-Border-Radius, 8px)',
  '&:hover': {
    backgroundColor: theme.palette.button.dark,

    color: theme?.palette.customWhite.main,
  },
})
export const filterofSapceStyle = {
  container_Parent: {
    display: { xs: '', sm: 'flex' },
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '',
    width: { xs: '100%' },
    height: '36px',
  },

  btn_Box: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: { xs: '0px', sm: '12px' },
    background: '',
  },
  all_Customtext_mainBox: {
    background: '',
    display: 'flex',
    flexDirection: 'column',
    width: { xs: '190px', sm: '250px' },
    gap: '5px',
  },
  button_Box: {
    background: '',
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    boxSizing: 'border-box',
    mt: '10px',
  },
}
export const dropDown_Box = (theme) => ({
  background: '',
  display: 'flex',
  padding: '5px',
  borderRadius: '4px',
  cursor: 'pointer',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: theme?.palette.sky.light,
  },
})
export const menu_Tag_Style = (theme) => ({
  borderRadius: '12px',
  backgroundColor: theme?.palette.gray.main,
  border: `1px solid ${theme.palette.gray.light}`,
  paddingY: '10px',
  paddingX: '17px',
  boxShadow:
    '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
})
export const custom_Typography_Text = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme?.typography.h4.fontWeight,
  color: theme?.palette.black.main,
  fontFamily: theme?.typography.h4.fontFamily,
})
export const all_workspaces_Text = (theme) => ({
  fontSize: {
    xs: theme.typography.h3.fontSize,
    sm: theme.typography.large.fontSize,
  },
  fontFamily: theme?.typography.menualText.fontFamily,
  fontWeight: theme?.typography.menualText.fontWeight,
  color: theme?.palette.black.main,
  lineHeight: 'normal',
  background: '',
  height: 'fit-content',
  letterSpacing: ' -1.2px',
})
export const selectText_BackgroundColor = (theme) => ({
  fontSize: theme.typography.mediumText.fontSize,
  fontFamily: theme?.typography.menualText.fontFamily,

  color: theme?.palette.primary.light,
})
export const apply_Cancel_Btn = (theme) => ({
  borderRadius: '8px',
  border: `1px solid ${theme.palette.primary.light}`,
  paddingY: '2px',
  width: '70px',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: theme?.palette?.sky.light,
    border: `1px solid ${theme.palette.sky.light}`,
  },
})

export const welcomMain_Box = () => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '452px',
  height: 'fit-content',
  gap: '16px',
  background: '',
  mt: '200px',
  mx: 'auto',
})

export const span_BoxStyle = () => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '8px',
  background: '',
})
export const userWelcome_Text = (theme) => ({
  fontSize: theme.typography.mediumText.fontSize,
  fontFamily: theme?.typography.menualText.fontFamily,
  fontWeight: theme?.typography.h5.fontWeight,
  color: theme?.palette.text.default,
  lineHeight: '30px',
  fontStyle: 'normal',
})

export const welcomeParagraph_Text = (theme) => ({
  fontSize: theme.typography.extraLarge.fontSize,
  fontFamily: theme?.typography.menualText.fontFamily,
  fontWeight: theme?.typography.h4.fontWeight,
  color: theme?.palette.black.main,
  lineHeight: 'normal',
  fontStyle: 'normal',
  textAlign: 'center',
})
export const style_Pagination = (theme) => ({
  '& .MuiPaginationItem-root': {
    color: '#6B7280',
    border: `1px solid ${theme.plaette.gray.light}`,
    borderRadius: '999px',
    width: '38px',
    height: '38px',
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    backgroundColor: theme.plaette.gray.light,
    border: `1px solid ${theme.plaette.gray.light}`,
  },
  '& .MuiPaginationItem-page:hover': {
    backgroundColor: 'white',
  },
  '& .MuiPaginationItem-ellipsis': {
    color: theme?.palette?.text?.hover,
    textAlign: 'center',
    fontFamily: theme?.typography.menualText.fontFamily,
    fontWeight: theme?.typography.h4.fontWeight,
    fontSize: theme.typography.h4.fontSize,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.08px',
  },
  '& .MuiPaginationItem-icon': {
    color: theme?.palette?.text?.hover,
  },
})
