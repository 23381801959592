import {
  FacebookSvg,
  GoogleSvg,
  InstagramSvg,
  LinkedInSvg,
  PinterestSvg,
  TikTokSvg,
  TwitterSvg,
  YoutubeSvg,
} from './svgImageWOrkSPace'

const objectsArray = [
  // {
  //   heading: 'Google',
  //   description: 'Google Business Profile',
  //   image: <GoogleSvg width={52} height={52} />,
  // },
  {
    heading: 'Facebook',
    description: 'Page or Group',
    image: <FacebookSvg width={52} height={52} />,
  },
  // {
  //   heading: 'LinkedIn',
  //   description: 'Company page or profile',
  //   image: <LinkedInSvg />,
  // },
  {
    heading: 'Instagram',
    description: 'Professional account or profile',
    image: <InstagramSvg />,
  },
  // {
  //   heading: 'Twitter',
  //   description: 'Profile',
  //   image: <TwitterSvg />,
  // },

  {
    heading: 'Youtube',
    description: 'Channel',
    image: <YoutubeSvg />,
  },
  // {
  //   heading: 'Pinterest',
  //   description: 'Business profile',
  //   image: <PinterestSvg />,
  // },
  // {
  //   heading: 'TikTok',
  //   description: 'Acount',
  //   image: <TikTokSvg />,
  // },
]
export default objectsArray
