import YoutubePost from './YoutubePost'
import React, { useContext, useState, useEffect } from 'react'
import UserContext from '../../../../Context/userContext'
import YoutubePostModel from './YoutubePostModel'
import { Box } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getSpecificSpacesAPI } from '../../../../API'
import EmptyScreenYoutube from './emptyScreenYoutube'
import { getYoutubeChannelApi } from '../../../../API/PlatformsAPIs/YoutubeApi'
import YtChannels from './YtChannels'
import { Loader } from '@infostacks/ui-components'
import { useTheme } from '@emotion/react'
import youtubeStyle from './youtubeStyle'

const Youtube = () => {
  const { currentUser, userAuth, stateCheck } = useContext(UserContext)
  const { id } = useParams()
  const [youtubeModal, setYoutubeModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const basePath = currentPath.split('/').slice(0, 3).join('/')
  const [ytFound, setYtFound] = useState()
  console.log('ytFound',ytFound)
  const [ytAccounts, setYtAccounts] = useState([])
  const theme = useTheme()
  const getYtProfileslist = async (spaceId) => {
    try {
      const res = await getYoutubeChannelApi(userAuth, spaceId)
      console.log('res', res)
      setYtAccounts(res)
    } catch (error) {
      console.log('error', error)
    }
  }

  const getRecentSpace = async (spaceId) => {
    setLoading(true)
    try {
      const fetchSpace = await getSpecificSpacesAPI(
        userAuth,
        currentUser,
        spaceId
      )
      const platforms = fetchSpace.platforms?.some(
        (val) => val.name === 'youtube'
      )
      setYtFound(platforms)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    const spaceId = window.sessionStorage.getItem('spaceId')
    if (id !== spaceId) {
      navigate(`${basePath}/${spaceId}`)
    }
    const fetch = async () => {
      if (userAuth) {
        getRecentSpace(spaceId)
        getYtProfileslist(spaceId)
      }
    }
    fetch()
  }, [])

  const handleClose = () => {
    setYoutubeModal(false)
  }

  return (
    <Box
      sx={youtubeStyle.ytChannelparentBox(theme)}
    >
    
      {loading ? (
       <Box sx={youtubeStyle.ytLoaderBox(theme)}>
       <Loader thickness={6} sx={{ color: 'blue' }} />
     </Box>
      ) : ytFound ? (
        <YtChannels ytAccounts={ytAccounts} workspaceId={id}/>
      ) : (
        <EmptyScreenYoutube />
      )}
      {/* {youtubeModal && (
        <YoutubePostModel
          youtubeModal={youtubeModal}
          setYoutubeModal={setYoutubeModal}
          handleClose={handleClose}
        />
      )} */}
    </Box>
  )
}

export default Youtube
