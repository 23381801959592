import { Box, Card, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import {
  googleServicesHeaderText,
  card_Main,
  description,
  footerText,
  googleServiceStyle,
  integratedText,
  name,
} from './googleServiceStyle'
import { googleAnalyticsApi } from '../../../../API/PlatformsAPIs/GoogleApi'
import UserContext from '../../../../Context/userContext'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import CustomMoreVertRoundedButton from '../../../ReusableComponents/customMoreVertRoundedButton'
import CustomMenu from '../../../ReusableComponents/customMenu'

const GooglService = () => {
  const { userAuth } = useContext(UserContext)
  let spaceId = '65e0a386710fa47ecb4927ab'
  const [anchorEl, setAnchorEl] = useState(null)
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const navigate = useNavigate()
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const menuItems = [
    {
      label: 'Unarchive Spaces',
      onClick: () => navigate('/dashboard/archived-spaces'),
    },
  ]
  const googleAnalytics = async () => {
    try {
      const response = await googleAnalyticsApi('65dde37a47f07538a3da736d')
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      window.location.assign(data?.url)
    } catch (error) {
      console.error('Error fetching analytics Auth URL:', error.message)
    }
  }
  // useEffect(()=>{
  //   const fetchServices = async () => {

  //       await googleAnalytics()

  //   }
  //   fetchServices()
  // })
  const googleData = [
    {
      integratedText: 'Integrated',
      svg: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='48'
          height='48'
          viewBox='0 0 58 64'
          fill='none'
        >
          <g clipPath='url(#clip0_2587_7891)'>
            <path
              d='M57.6985 55.8725C57.7044 56.9973 57.4711 58.1106 57.0141 59.1384C56.5571 60.1663 55.8869 61.0852 55.0479 61.8345C54.2088 62.5837 53.2202 63.146 52.1473 63.4842C51.0745 63.8224 49.9421 63.9287 48.8251 63.7961C44.7588 63.1944 41.7792 59.6561 41.8784 55.5468V8.30334C41.7801 4.18977 44.7672 0.649484 48.8386 0.0543294C49.9546 -0.0763196 51.0856 0.0314559 52.1568 0.370528C53.228 0.709599 54.215 1.27224 55.0525 2.02123C55.89 2.77023 56.559 3.68851 57.0151 4.71534C57.4713 5.74216 57.7042 6.85414 57.6985 7.97771V55.8725Z'
              fill='#F9AB00'
            />
            <path
              d='M7.91887 48.0438C12.2875 48.0438 15.8289 51.5855 15.8289 55.9539C15.8289 60.3226 12.2875 63.864 7.91887 63.864C3.55019 63.864 0.00878906 60.3226 0.00878906 55.9542C0.00878906 51.5855 3.55019 48.0438 7.91887 48.0438ZM28.7319 24.0693C24.3427 24.31 20.937 27.9918 21.0391 32.3866V53.6339C21.0391 59.4002 23.5763 62.9009 27.2937 63.647C28.4462 63.8806 29.6363 63.8548 30.7776 63.5714C31.9188 63.2879 32.9827 62.754 33.8921 62.0084C34.8014 61.2627 35.5333 60.324 36.0348 59.2604C36.5363 58.1967 36.7947 57.0348 36.7914 55.8588V32.0204C36.7933 30.9666 36.5854 29.9229 36.1797 28.9503C35.774 27.9777 35.1786 27.0956 34.4284 26.3555C33.6782 25.6153 32.7881 25.032 31.8101 24.6396C30.832 24.2471 29.7856 24.0531 28.7319 24.0693Z'
              fill='#E37400'
            />
          </g>
          <defs>
            <clipPath id='clip0_2587_7891'>
              <rect
                width='57.7066'
                height='64'
                fill='white'
                transform='translate(0 0.000244141)'
              />
            </clipPath>
          </defs>
        </svg>
      ),
      title: 'Google Analytics',
      description:
        'Integrating Google boosts productivity and enhances user experience on our platform.',
      integrations: '360+ Integrations',
    },
  ]
  const theme = useTheme()
  return (
    <Box sx={googleServiceStyle.parentContainer(theme)}>
      <Box sx={googleServiceStyle.GoogleServicesHeader}>
        <Typography sx={googleServicesHeaderText(theme)}>
          Google Services
        </Typography>
        <CustomMoreVertRoundedButton onClick={handleMenuOpen} />
        <CustomMenu
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          open={anchorEl}
          menuItems={menuItems}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
      </Box>
      {googleData.map((value, index) => (
        <Card key={index} sx={card_Main(theme)}>
          <Box sx={googleServiceStyle.header}>
            {value.svg}
            <Typography sx={integratedText(theme)}>
              {value.integratedText}
            </Typography>
          </Box>
          <Typography sx={name(theme)}>{value.title}</Typography>
          <Typography sx={description(theme)}>{value.description}</Typography>
          <Typography sx={footerText(theme)}>{value.integrations} </Typography>
        </Card>
      ))}
    </Box>
  )
}

export default GooglService
