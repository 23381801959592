import { Box, Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import ImageSwiperSection from './imageSwiperSection'
import { useTheme } from '@emotion/react'
import imageSectionStyle, { uploadMoreBtn } from './imageSectionStyle'

const ImageSection = ({
  getRootProps,
  media,
  setMedia,
  setImageSrc,
  setEditimageIndex,
  setEditModal,
  mediaType,
  setMediaType,
  loading,
}) => {
  const theme = useTheme()
  const handleChange = (event) => {
    setMediaType(event.target.value)
  }
  return (
    <Box sx={imageSectionStyle.mediaBox}>
      <Box sx={imageSectionStyle.main_Box}>
        <RadioGroup row value={mediaType} onChange={handleChange}>
          <FormControlLabel
            disabled={loading}
            value='post'
            control={
              <Radio
                sx={{ '&.Mui-checked': { color: theme.palette.button.dark } }}
              />
            }
            label='Post'
            sx={imageSectionStyle.radioButoon_Text(theme)}
          />
          <FormControlLabel
            disabled={loading}
            value='story'
            control={
              <Radio
                sx={{ '&.Mui-checked': { color: theme.palette.button.dark } }}
              />
            }
            label='Story'
            sx={imageSectionStyle.radioButoon_Text(theme)}
          />
        </RadioGroup>
        <Button
          disabled={media.length === 8 || loading}
          {...getRootProps()}
          sx={uploadMoreBtn(media, theme)}
        >
          Upload Max 8
        </Button>
      </Box>
      <ImageSwiperSection
        media={media}
        setMedia={setMedia}
        setImageSrc={setImageSrc}
        setEditimageIndex={setEditimageIndex}
        setEditModal={setEditModal}
      />
    </Box>
  )
}

export default ImageSection
