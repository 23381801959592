import {
  Box,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import fb from './../../../Assets/Images/facebook.png'
import linkedin from './../../../Assets/Images/linkedin.png'
import google from './../../../Assets/Images/google.png'
import { useTheme } from '@emotion/react'
import danger from './../../../Assets/Images/danger.png'
import {
  emailResolver,
  emailValidator,
  passwordResolver,
  passwordValidator,
} from '../../../Validators/authValidator'
import { useForm } from 'react-hook-form'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import UserContext from '../../../Context/userContext'
import { Loader } from '@infostacks/ui-components'
import { notification } from 'antd'
import { InfoSvg } from '../../Dashboard/WorkSpaceSocialPage/svgImageWOrkSPace'
import signinPageStyles, {
  button_Style,
  commonTextFieldStyle,
  ContinueButton,
  credentialsMesg_Text,
  errorBoxStyles,
  footer_Text,
  forgetPassword_Style,
  labelTextfield,
  or_Text_Style,
  signinFormStyle,
  signup_Button,
  subHeading_Text_Style,
  welcome_Text_Style,
} from '../signinPageStyles'
import { useNavigate } from 'react-router-dom'

const SigninForm = () => {
  const {
    logInContext,
    forgotPasswordContext,
    logInWithGoogleContext,
    logInWithFacebookContext,
  } = useContext(UserContext)
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const [apiError, setApiError] = useState()
  console.log('apiError', apiError)
  const [api, contextHolder] = notification.useNotification()
  const navigate = useNavigate()

  const openNotification = (
    placement,
    type,
    message,
    icon,
    description,
    duration
  ) => {
    api.open({
      message: message,
      description: description,
      placement: placement,
      icon: icon,
      type: type,
      duration: duration,
    })
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields },
  } = useForm()

  const onSubmit = async (loginData) => {
    setApiError()
    console.log('loginData', loginData)
    setLoading(true)
    const { email, password } = loginData
    try {
      const respFromFirebase = await logInContext(email, password)
      console.log('login response', respFromFirebase)
      if (respFromFirebase.error === 1) {
        setApiError('Invalid credentials')
      }
      // setApiError(respFromFirebase.message)  this is for when too many wrong attempts
    } catch (error) {
      console.log(error, 'error from firebase')
      setApiError(error.message || 'An error occurred')
    }
    setLoading(false)
  }

  const forgetButton = async () => {
    setApiError()
    try {
      const email = watch('email')

      if (email) {
        const resOfForgotPassword = await forgotPasswordContext(email)
        console.log('resOfForgotPassword', resOfForgotPassword)
        if (resOfForgotPassword.message === 'User Not Found') {
          setApiError(resOfForgotPassword.message)
        }
        if (resOfForgotPassword.message !== 'User Not Found') {
          openNotification(
            'top',
            'info',
            <Typography sx={{ fontWeight: 600 }}>Forget Password</Typography>,
            <InfoSvg height={24} width={24} />,
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
              {resOfForgotPassword.message}
            </Typography>,
            5
          )
        }
      } else {
        setApiError('Email is required for password reset.')
      }
    } catch (error) {
      console.log(error, 'forget error')
    }
  }

  return (
    <form
      className='signinForm'
      onSubmit={handleSubmit(onSubmit)}
      style={signinFormStyle.form_Style}
    >
      {contextHolder}
      <Typography sx={welcome_Text_Style(theme)}>Welcome back</Typography>
      <Typography sx={subHeading_Text_Style(theme)}>
        Enter Details to signin
      </Typography>
      <Box sx={signinFormStyle.googleBtn_Box}>
        <Button
          onClick={logInWithGoogleContext}
          variant='outlined'
          sx={button_Style(theme)}
          startIcon={
            <img
              src={google}
              alt='google'
              style={{ height: '20px', width: '20px' }}
            />
          }
        >
          Continue with google
        </Button>

        <Button
          startIcon={
            <img
              src={linkedin}
              alt='linkedin'
              style={{ height: '20px', width: '20px' }}
            />
          }
          variant='outlined'
          sx={{ ...button_Style(theme), display: 'none' }}
        >
          Continue with LinkedIn
        </Button>
        <Button
          onClick={logInWithFacebookContext}
          startIcon={
            <img
              src={fb}
              alt='facebook'
              style={{ height: '20px', width: '20px' }}
            />
          }
          variant='outlined'
          sx={{ ...button_Style(theme), display: 'none' }}
        >
          Continue with Facebook
        </Button>
      </Box>

      <Box sx={signinFormStyle.devider_Box}>
        <Divider
          orientation='horizontal'
          sx={signinFormStyle.devider_Style(theme)}
        />
        <Typography sx={or_Text_Style(theme)}>or</Typography>
        <Divider
          orientation='horizontal'
          sx={signinFormStyle.devider_Style(theme)}
        />
      </Box>

      <Box sx={signinFormStyle.emailField_Box}>
        <label className='textfield-label' style={labelTextfield(theme)}>
          Email*
        </label>
        <div style={{ position: 'relative' }}>
          <TextField
            fullWidth
            autoComplete='off'
            variant='outlined'
            placeholder='Email'
            {...register('email', emailValidator)}
            sx={commonTextFieldStyle(errors.email)}
          />
          <FormHelperText sx={signinPageStyles.helperText}>
            {errors.email && touchedFields.email
              ? emailResolver[errors.email?.type]
              : apiError === 'User Not Found'
              ? 'User Not Found'
              : apiError === 'Email is required for password reset.'
              ? 'Email is required for password reset.'
              : ''}
          </FormHelperText>
        </div>
      </Box>
      <Box sx={signinFormStyle.passwordField_Box}>
        <label className='textfield-label' style={labelTextfield(theme)}>
          Password
        </label>
        <div style={{ position: 'relative' }}>
          <TextField
            sx={commonTextFieldStyle(errors.password)}
            fullWidth
            type={showPassword ? 'text' : 'password'}
            variant='outlined'
            placeholder='Password'
            autoComplete='off'
            {...register('password', passwordValidator)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge='end'
                    style={{
                      fontSize: '0.6rem',
                      marginRight: 'px',
                    }}
                  >
                    {showPassword ? (
                      <Visibility fontSize='small' />
                    ) : (
                      <VisibilityOff fontSize='small' />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText sx={signinPageStyles.helperText}>
            {errors.password && touchedFields.password
              ? passwordResolver[errors.password?.type]
              : ''}
          </FormHelperText>
        </div>
      </Box>

      <Typography onClick={forgetButton} sx={forgetPassword_Style(theme)}>
        Forget Password
      </Typography>

      <Button type='submit' variant='contained' sx={ContinueButton(theme)}>
        {loading ? <Loader thickness={7} size={30} /> : 'Continue'}
      </Button>

      <Box sx={signinFormStyle.footer_Box}>
        <Typography sx={footer_Text(theme)}>Don't have an account?</Typography>
        <Button
          onClick={() => navigate('/signup')}
          variant='text'
          color='black'
          sx={signup_Button(theme)}
        >
          Signup here
        </Button>
      </Box>

      <Box sx={errorBoxStyles(apiError, theme)}>
        <img src={danger} alt='' width={20} height={18} />
        <Typography sx={credentialsMesg_Text(theme)}>
          Your credentials are incorrect. Please try again.
        </Typography>
      </Box>
    </form>
  )
}
export default SigninForm
