import makeHeader from '../../Headers'
import { getBlob, getDownloadURL, getStorage, ref } from 'firebase/storage'
const storage = getStorage()

export function timeAgo(dateString) {
  const now = new Date()
  const date = new Date(dateString)
  const diffInMs = now - date

  const seconds = Math.floor(diffInMs / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30) // Approximation
  const years = Math.floor(months / 12) // Approximation

  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''} ago`
  } else if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} ago`
  } else if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
  } else {
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`
  }
}


export const instagramProfilesListApi = async (userAuth, spaceId) => {
  // console.log('userAuth',userAuth, "space",spaceId);
  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/instagram/list_profiles?workspaceId=${spaceId}`

    const response = await fetch(apiUrl, makeHeader('GET', idToken))
    const responseData = await response.json()

    return responseData // Return the data here
  } catch (error) {
    console.error('Error in instagramProfilesListApi:', error)
    throw error
  }
}

export const hostedLinksToFiles = async (filePath) => {
  try {
    const fileRef = ref(storage, filePath);
    const blob = await getBlob(fileRef);

    const file = new File([blob], 'media-file', { type: blob.type });
file.path='video47'
    const fileUrl = URL.createObjectURL(file);

    console.log("File object:", file);
    console.log("File link:", fileUrl);

    return { file, url: fileUrl }; // Return in the required format
  } catch (error) {
    console.error('Error fetching file from Firebase Storage:', error);
    return null; // Return null or handle the error gracefully
  }
};


// export const hostedLinksToFiles = async (filePaths) => {
//   try {
//     const mediaArray = await Promise.all(
//       filePaths.map(async (filePath) => {
//         const fileRef = ref(storage, filePath);
//         const blob = await getBlob(fileRef);
        
//         const file = new File([blob], 'file-name', { type: blob.type });
//         const fileUrl = URL.createObjectURL(file);

//         // Return an object for each file
//         return { file, url: fileUrl };
//       })
//     );

//     // Store the array of media objects
//     // setVideoUrl(mediaArray);
//     console.log("Media array:", mediaArray);
//   } catch (error) {
//     console.error('Error fetching files from Firebase Storage:', error);
//   }
// };

export const instagramCreatePostApi = async (
  userAuth,
  pageId,
  instaId,
  id,
  formData,
  mediaType
) => {
  console.log('mediaType',mediaType);
  // console.log('body', formData)

  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/instagram/post_media?workspaceId=${id}&pageId=${pageId}&igUserId=${instaId}&mediaType=${mediaType}`

    const response = await fetch(apiUrl, makeHeader('POST', idToken, formData))
    const responseData = await response.json()

    return responseData // Return the data here
  } catch (error) {
    console.error('Error in instagramCreatePostApi:', error)
    throw error
  }
}


 
export const instagramSchedulePostApi = async (
  userAuth,
  id,
  pageId,
  instaId,
  mediaType,
  formData,
) => {
  // console.log('userAuth',userAuth, "pageId",pageId,'instaId',instaId,'spaceId',id,'body',formData);
  console.log('body', formData)

  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/instagram/schedule_post?workspaceId=${id}&pageId=${pageId}&igUserId=${instaId}&mediaType=${mediaType}`

    const response = await fetch(apiUrl, makeHeader('POST', idToken, formData))
    const responseData = await response.json()

    return responseData // Return the data here
  } catch (error) {
    console.error('Error in instagramSchedulePostApi:', error)
    throw error
  }
}



export const instagramGetAllPostsApi = async (
  userAuth,
  id,
  igUserId,
  pageId
) => {
  // console.log('workspaceId', id, 'igUserId', igUserId, 'pageId', pageId)

  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/instagram/read_media?workspaceId=${id}&igUserId=${igUserId}&pageId=${pageId}`

    const response = await fetch(apiUrl, makeHeader('GET', idToken))
    const responseData = await response.json()

    return responseData // Return the data here
  } catch (error) {
    console.error('Error in instagramGetAllPostsApi:', error)
    throw error
  }
}


export const instagramEditPostApi = async (
  userAuth,
  id,
  pageId,
  igUserId,
  mediaType,
  postId,
  formData
) => {
  // console.log('workspaceId', id, 'igUserId', igUserId, 'pageId', pageId)

  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/instagram/update_post?workspaceId=${id}&pageId=${pageId}&igUserId=${igUserId}&mediaType=${mediaType}&postId=${postId}`

    const response = await fetch(apiUrl, makeHeader('POST', idToken,formData))
    const responseData = await response.json()

    return responseData // Return the data here
  } catch (error) {
    console.error('Error in instagramEditPostApi:', error)
    throw error
  }
}


export const getInstagramUserProfile = async (userAuth, id, igUserId) => {
  // console.log('userAuth', userAuth, 'workspaceId', id, 'igUserId', igUserId)

  try {
    const idToken = await userAuth.getIdToken(true)
    const apiUrl = `${process.env.REACT_APP_ANALYTICS_API}/instagram/user_profile?workspaceId=${id}&igUserId=${igUserId}`
    const response = await fetch(apiUrl, makeHeader('GET', idToken))
    const responseData = await response.json()
    // console.log('responseData responseData', responseData)
    return responseData
  } catch (error) {
    console.log('Error in getInstagramUserProfile', error)
    throw error
  }
}
