import { Box, Typography } from '@mui/material'
import { Modal } from 'antd'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { useTheme } from '@emotion/react'
import { CloseRounded } from '@mui/icons-material'
import {
  CommentInstaIconSvg,
  LikeInstaIconSvg,
  VerifyInstaIconSvg,
} from '../PasswordStartAdornment/svgFile'
import {
  getInstagramUserProfile,
  timeAgo,
} from '../../API/PlatformsAPIs/InstagramApi'
import { useContext, useEffect, useState } from 'react'
import UserContext from '../../Context/userContext'
import { useParams } from 'react-router-dom'
import { Loader } from '@infostacks/ui-components'

const CommomPostViewModal = ({
  open,
  onCancel,
  showReactions = false,
  showComments = false,
  showShares = false,
  val,
  postAndStoryOption,
}) => {
  const { userAuth, postData } = useContext(UserContext)
  const { id, igUserId } = useParams()
  const [userData, setUserData] = useState()
  const [loading, setLoading] = useState()
  let data
  // console.log('postData set userContext', postData)
  if (postAndStoryOption === 'Post') {
    data = postData
  } else data = val
  const theme = useTheme()
  const {
    thumbnailUrl,
    createdAt,
    caption,
    mediaItems = [],
    reactionsCount = 0,
    commentsCount = 0,
    sharesCount = 0,
    contentType,
  } = data

  // console.log('data in CommomPostViewModal', data)
  const contentTypeFromVal = val?.contentType || contentType
  const instagramUserPrfileData = async () => {
    setLoading(true)
    try {
      const response = await getInstagramUserProfile(userAuth, id, igUserId)
      // console.log('response getInstagramUserProfile', response)
      setUserData(response)
      setLoading(false)
    } catch (error) {
      console.error('Error from instagramUserPrfileData:', error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      instagramUserPrfileData()
    }

    fetchData()
  }, [userAuth, id, igUserId])

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      centered
      maskClosable={false}
      closable={false}
      height={'fit-content'}
      width={600}
      styles={{
        body: {
          boxSizing: 'border-box',
          padding: '0px',
        },
      }}
      style={{
        background: 'white',
        borderRadius: ' var(--Numbers-Number-12, 24px)',
        boxSizing: 'border-box',
        padding: '0px',
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '531px',
            width: '100%',
          }}
        >
          <Loader thickness={6} />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              width: '100%',
              height: '40px',
              display: 'flex',
              gap: '12px',
              alignItems: 'center',
            }}
          >
            <img
              src={userData?.profile_picture_url || ''}
              alt='profilePic'
              height={40}
              width={40}
              style={{ borderRadius: '50%' }}
            />
            <Box sx={{ width: '100%' }}>
              <span
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                }}
              >
                <Typography
                  style={{
                    lineHeight: '20px',
                    color: theme.palette.black.main,
                    fontFamily: theme.typography.h2.fontFamily,
                    fontSize: theme.typography.h5.fontSize,
                    fontWeight: theme.typography.h2.fontWeight,
                  }}
                >
                  {userData?.name || ''}
                </Typography>
                <VerifyInstaIconSvg />
                <Typography
                  style={{
                    display: contentTypeFromVal === 'post' ? 'flex' : 'none',
                    lineHeight: '20px',
                    color: theme.palette.border.dark,
                    fontFamily: theme.typography.h2.fontFamily,
                    fontSize: theme.typography.h5.fontSize,
                    fontWeight: theme.typography.h5.fontWeight,
                    fontStyle: 'normal',
                    textAlign: 'center',
                  }}
                >
                  {userData?.username || ''}
                </Typography>
              </span>
              <Typography
                style={{
                  lineHeight: '18px',
                  color: theme.palette.black.light,
                  fontFamily: theme.typography.h2.fontFamily,
                  fontSize: theme.typography.paragraph.fontSize,
                  fontWeight: theme.typography.h5.fontWeight,
                }}
              >
                {timeAgo(createdAt)}
              </Typography>
            </Box>
            <CloseRounded
              onClick={onCancel}
              sx={{ height: '24px', width: '24px', cursor: 'pointer' }}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              alignItems: 'center',
              mt: contentTypeFromVal === 'story' ? '12px' : '',
            }}
          >
            <Typography
              sx={{
                display: contentTypeFromVal === 'post' ? 'flex' : 'none',
                color: theme.palette.black.main,
                fontFamily: theme.typography.h2.fontFamily,
                fontSize: theme.typography.h5.fontSize,
                fontWeight: theme.typography.h5.fontWeight,
                width: '100%',
                mx: 'auto',
                mt: '12px',
                overflowY: 'auto',
                maxHeight: '70px',
                lineHeight: '24px',
                textAlign: 'start',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {caption}
            </Typography>

            <Swiper
              spaceBetween={20}
              navigation
              modules={[Pagination, Navigation]}
              breakpoints={{
                0: { slidesPerView: 1 },
                768: { slidesPerView: 1 },
              }}
              className='mySwiper'
              style={{
                width: '100%',
                height: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {mediaItems.map((item, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  {item.mediaType === 'image' ? (
                    <img
                      src={item.mediaUrl}
                      alt={`media ${index}`}
                      style={{
                        height: '100%',
                        width: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        borderRadius: '16px',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <video
                      controls
                      style={{
                        objectFit: 'contain',
                        height: '100%',
                        width: '100%',
                        borderRadius: '16px',
                        backgroundPosition: 'center',
                      }}
                    >
                      <source src={item.mediaUrl} />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>

          <Box
            sx={{
              boxSizing: 'border-box',
              width: '100%',
              display: contentTypeFromVal === 'post' ? 'flex' : 'none',
              mt: '16px',
              gap: '20px',
            }}
          >
            {[
              showReactions && {
                count: reactionsCount,
                label: <LikeInstaIconSvg />,
              },
              showComments && {
                count: commentsCount,
                label: <CommentInstaIconSvg />,
              },
              showShares && { count: sharesCount, label: 'Shares' },
            ]
              .filter(Boolean)
              .map(({ count, label }, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                  }}
                >
                  {label}
                  <Typography
                    sx={{
                      color: theme.palette.black.main,
                      fontFamily: theme.typography.h2.fontFamily,
                      fontSize: theme.typography.h4.fontSize,
                      fontWeight: theme.typography.h4.fontWeight,
                      lineHeight: '24px',
                    }}
                  >
                    {count}
                  </Typography>
                </Box>
              ))}
          </Box>
        </>
      )}
    </Modal>
  )
}

export default CommomPostViewModal
