import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import 'swiper/css/bundle'
import { homePage } from '../homePageStyle'
import { useTheme } from '@emotion/react'

const newsItems = [
  { id: 1, text: 'Ever felt frustrated with a website or app?' },
  {
    id: 2,
    text: 'Discover the latest trends in web development! Discover the latest',
  },

  { id: 3, text: 'React is becoming a must-know for developers.' },
  { id: 4, text: "Don't miss out on the latest updates!" },
  { id: 5, text: 'Explore modern UI/UX designs!' },
  { id: 6, text: 'Web3: The future of decentralized apps?' },
]
const TrendingAiSearch = () => {
  const theme = useTheme()
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={homePage.trendingPostBlurLeft} />

      <Swiper
        spaceBetween={10}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        loop={true}
        slidesPerView={'auto'}
        modules={[Autoplay]}
        style={homePage.trendingNewsSwiper}
      >
        {newsItems.map((news) => (
          <SwiperSlide key={news.id} style={homePage.trendingNewsSwiperSlide}>
            <Button sx={homePage.trendingNews(theme)}>{news.text}</Button>
          </SwiperSlide>
        ))}
      </Swiper>

      <Box sx={homePage.trendingPostBlurRight} />
    </Box>
  )
}

export default TrendingAiSearch
