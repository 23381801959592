import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { Switch } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import React from 'react'

const ToggleButton = ({ generateMediaButton, setGenerateMediaButtton }) => {
  const handleChange = (checked) => {
    setGenerateMediaButtton(checked)
  }
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: '10px',
        background: '',
      }}
    >
      <Typography
        sx={{
          fontSize: theme?.typography.h5.fontSize,
          fontWeight: theme?.typography.h1.fontWeight,
          fontFamily: theme?.typography.h2.fontFamily,
          fontStyle: 'normal',
          lineHeight: '20px',
        }}
      >
        Generate media
      </Typography>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={generateMediaButton}
        onChange={handleChange}
        style={{
          backgroundColor: generateMediaButton
            ? theme.palette.button.dark
            : undefined, // Green when checked
        }}
      />
    </Box>
  )
}

export default ToggleButton
