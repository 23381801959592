const signinPageStyles = {
  parent_Box: (theme) => ({
    background: theme.palette.background.default,
    width: '100%',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '20px',
  }),
  main_Box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '',
    boxSizing: 'border-box',
    paddingY: '20px',
  },
  formMainBox: {
    width: '400px',
    display: 'flex',
    height: 'fit-content',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '',
    gap: '69px',
  },
  logo_MainBox: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    background: '',
    mr: 'auto',
    cursor: 'pointer',
  },
  // buttonStyle: {
  //   flex: 1,
  //   textTransform: 'capitalize',
  //   color: '#000',
  //   fontFamily: 'Libre Franklin',
  //   fontSize: '14px',
  //   fontWeight: 400,
  //   borderRadius: '14px',
  //   border: '1px solid #C6C6CE',
  //   paddingX: '12px',
  //   paddingY: '10px',
  //   boxSizing: 'border-box',
  // },

  helperText: {
    position: 'absolute',
    bottom: '-16px',
    color: 'red',
    width: '100%',
    background: '',
    textAlign: 'end',
    lineHeight: 'normal',
  },
  swiper_Style: {
    width: '426px',
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  swiperSlide_Style: {
    background: '',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: 'fit-content',
  },
  swiperSlide_Img: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    borderRadius: '32px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
}
export default signinPageStyles

export const labelTextfield = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  color: theme.palette.black.main,
  width: 'auto',
  marginRight: 'auto',
  lineHeight: '20px',
})
export const commonTextFieldStyle = (error) => ({
  background: '#FFF',
  display: 'flex',

  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    gap: '12px',
    '&:hover fieldset': {
      borderColor: '#C2C2C2',
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'red' : '#241BFF',
    },
    '& fieldset': {
      borderColor: '#C2C2C2',
    },
    '& .MuiOutlinedInput-input': {
      height: '20px',
      padding: '12px 16px',
      color: '#000',
      fontFamily: 'Sora',
      fontSize: '16px',
    },
  },
})
export const ContinueButton = (theme) => ({
  width: '100%',
  height: '44px',
  boxSizing: 'border-box',
  padding: '10px',
  fontFamily: theme.typography.h2.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  lineHeight: '24px',
  textTransform: 'capitalize',
  borderRadius: '14px',
  backgroundColor: theme.palette.button.dark,
  marginTop: '42px',
})
export const latics_Text_Style = (theme) => ({
  lineHeight: 'normal',
  fontSize: theme.typography.headingThree.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h2.fontWeight,
  color: theme.palette.black.main,
})
export const signinFormStyle = {
  form_Style: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'white',
  },
  googleBtn_Box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    marginTop: '50px',
  },
  devider_Box: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: '',
    marginTop: '16px',
    gap: '17px',
  },
  devider_Style: (theme) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.border.main,
  }),
  emailField_Box: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '4px',
    mt: '16px',
    background: '',
  },
  passwordField_Box: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '4px',
    marginTop: '12px',
  },
  footer_Box: {
    display: 'flex',
    width: '100%',
    background: '',
    alignItems: 'center',
    gap: '4px',
    mt: '16px',
  },
}
export const welcome_Text_Style = (theme) => ({
  lineHeight: 'normal',
  fontSize: theme.typography.h1.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h2.fontWeight,
  color: theme.palette.black.main,
})
export const subHeading_Text_Style = (theme) => ({
  lineHeight: '20px',
  mt: '16px',
  fontSize: theme.typography.h5.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.black.main,
})
export const button_Style = (theme) => ({
  width: '100%',
  height: 'fit-content',
  display: 'flex',
  textTransform: 'capitalize',
  borderRadius: '14px',
  border: `1px solid ${theme.palette.border.hover}`,
  boxSizing: 'border-box',
  padding: '7px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: theme.typography.h4.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.black.main,
})
export const or_Text_Style = (theme) => ({
  fontSize: theme.typography.h4.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.border.dark,
})
export const forgetPassword_Style = (theme) => ({
  fontWeight: theme.typography.h4.fontWeight,
  fontFamily: theme.typography.h5.fontFamily,
  color: theme.palette.black.main,
  width: 'fit-content',
  textTransform: 'capitalize',
  fontSize: theme.typography.h5.fontSize,
  cursor: 'pointer',
  lineHeight: '20px',
  background: '',
  textDecoration: 'underLine',
  ml: 'auto',
  mt: '16px',
})
export const footer_Text = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  lineHeight: '20px',
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.black.light,
})
export const signup_Button = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  lineHeight: '20px',
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.black.main,

  textDecoration: 'underLine',
  boxSizing: 'border-box',
  padding: '0px',
  textTransform: 'capitalize',
  ':hover': { textDecoration: 'underLine' },
})

export const credentialsMesg_Text = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  lineHeight: '20px',
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.black.light,
})
export const errorBoxStyles = (apiError, theme) => ({
  width: '100%',
  height: '44px',
  border: `1px solid ${theme.palette.error.dark}`,
  borderRadius: '14px',
  background: theme.palette.error.extraLight,
  display: apiError === 'Invalid credentials' ? 'flex' : 'none',
  gap: '12px',
  boxSizing: 'border-box',
  padding: '10px',
  mt: '50px',
})
