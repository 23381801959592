import { Loader } from '@infostacks/ui-components'
import { Box, Button } from '@mui/material'
import React from 'react'
import { draftEditFooterStyle } from './manualPostFooterStyle'
import { useTheme } from '@emotion/react'

const DraftEditFooter = ({ handleOptionSelect, handleForm, loading }) => {
  const theme = useTheme()

  const handleEdit = (option) => {
    handleOptionSelect(option)
  }
  return (
    <Box sx={draftEditFooterStyle.parent_Box}>
      {/* Button or Loader */}
      <Box sx={draftEditFooterStyle.loadingBox(loading, theme)}>
        {loading ? (
          <Loader thickness={7} size={30} color='white' /> // Ensure `Loader` is imported
        ) : (
          <Button
            onClick={() => handleEdit('DraftToEdit')}
            sx={draftEditFooterStyle.save_Button_Style}
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default DraftEditFooter
