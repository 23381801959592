import { color } from 'framer-motion'

export const createRolePageStyle = {
  parent_Box: {
    width: '100%',
    padding: '15px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  loader_Box: {
    position: 'fixed',
    color: '',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },
  formStyle: {
    gap: '23px',
    display: 'flex',
    flexDirection: 'column',
    background: '',
  },
  textField_BoxMain: {
    width: '100%',
    display: 'flex',
    alignItems: 'end',
    justifyContent: '',
    height: '55px',
    background: '',
  },
  textFieldStyle: {
    width: '30%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'start',
    '& .MuiInput-underline:before': {
      borderBottomColor: '#241BFF',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#241BFF',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#241BFF',
    },
  },
  menuStyle: {
    width: '250px',
    paddingX: '8px',
    paddingY: '8px',
    boxSizing: 'border-box',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },

  meniItem_Text: {
    backgroundColor: 'transparent',
    '&.MuiMenuItem-root': { backgroundColor: '' },
    '&:hover': { backgroundColor: 'transparent' },
  },
  meniItem_ColorStyle: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    backgroundColor: 'transparent',
    '&.MuiMenuItem-root': { backgroundColor: 'transparent' },
    '&:hover': { backgroundColor: 'transparent', boxShadow: 'none' },
  },

  text_Box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '23px',
    overflowY: 'auto',
    height: '77vh',
    background: '',
  },
  span_Style: {
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
    background: '',
    width: '70%',
  },
  customAccordion_Box: {
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',
    background: '',
  },
}

export const actionStyle = (theme) => ({
  color: theme?.palette.black.main,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h1.fontFamily,
  fontSize: theme.typography.mediumText.fontSize,
  fontStyle: 'normal',
  lineHeight: '30px',
  background: '',
  width: 'fit-content',
})
export const paragraph_Text = (theme) => ({
  fontWeight: theme?.typography.h5.fontWeight,
  fontFamily: theme?.typography.h3.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontStyle: 'normal',
  lineHeight: '20px',
  color: theme?.palette.black.main,
  background: '',
  width: '100%',
})

export const discard_ButtonStyle = (theme) => ({
  background: theme.palette.background.default,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
  textTransform: 'capitalize',
  color: '#EF4444',
  borderColor: '#EF4444',
  borderRadius: '8px',
  paddingX: { xs: '8px', sm: '10px' },
  paddingY: { xs: '4px', sm: '4px' },
  boxSizing: 'border-box',
  '&:hover': {
    transition: 'background-color 0.2s ease-in-out',
    background: theme.palette.background.default,
    color: '#EF4444',
    borderColor: '#EF4444',
  },
})
export const save_ButtonStyle = (theme) => ({
  color: theme?.palette.customWhite.main,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
  textTransform: 'capitalize',
  borderRadius: '8px',
  boxSizing: 'border-box',
  paddingX: { xs: '8px', sm: '10px' },
  paddingY: { xs: '4px', sm: '4px' },
  background: theme.palette.button.dark,
  '&:hover': {
    backgroundColor: theme.palette.button.dark,
    transition: 'background-color 0.2s ease-in-out',
    color: 'white',
  },
})
export const adminPageStyle = {
  parent_Box: {
    width: '100%',
    background: '',
    padding: '15px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '23px',
  },
  main_Box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
    background: '',
  },
  arrow_Box: {
    display: 'flex',
    width: '50%',
    gap: '12px',
  },
  arrowBack_Style: {
    height: '25px',
    width: '25px',
    color: '#000',
    background: '',
    visibility: { xs: 'hidden', sm: 'visible' },
    display: { xs: 'none', sm: 'flex' },
    cursor: 'pointer',
    mt: '10px',
  },
  customAccordion_Box: {
    width: '100%',
    background: '',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  custom_MenuStyle_Text: {
    fontSize: '14px',
    fontFamily: 'poppins',
    fontWeight: '500',
    lineHeight: 'normal',
    letterSpacing: '0.07px',
    borderRadius: '8px',
    '&:hover': { backgroundColor: '#F3F4F6' },
  },
  iconButton: {
    paddingX: '5px',
    paddingY: '5px',
    boxSizing: 'border-box',
    borderRadius: '8px',
    background: ' #DADAFF',
    '&:hover': {
      backgroundColor: ' #DADAFF',
    },
  },
}
export const edit_Role_Text = (theme) => ({
  fontSize: theme.typography.menuItems.fontSize,
  fontWeight: theme?.typography.menuItems.fontWeight,
  color: theme?.palette.black.main,
  fontFamily: theme?.typography.menuItems.fontFamily,
  '&:hover': {
    backgroundColor: theme?.palette?.gray.hover,
    borderRadius: 'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)',
  },
})
export const menuRole_Style = (theme) => ({
  borderRadius: '12px',
  background: theme.palette.background.default || '#ffffff',
  padding:
    'var(--Overlays-Dropdowns-List-Spacing-Y, 0px) var(--Overlays-Dropdowns-List-Spacing-X, 8px) ',
  border: `1px solid ${theme?.palette?.gray?.light || '#d3d3d3'}`,
  boxShadow:
    ' 0px 10px 8px 0px rgba(0, 0, 0, 0.04), 0px 4px 3px 0px rgba(0, 0, 0, 0.10)    ',
})
export const admin_Text = (theme) => ({
  fontSize: {
    xs: theme.typography.h3.fontSize,
    sm: theme.typography.large.fontSize,
  },
  fontFamily: theme?.typography.h2.fontFamily,
  fontWeight: theme?.typography.menualText.fontWeight,
  color: theme?.palette.black.main,
  lineHeight: 'normal',
  letterSpacing: '-0.96px',
})
export const menu_Style = (theme) => ({
  borderRadius: '12px',
  background: theme.palette.background.default,
  paddingY: { xs: '2px', sm: '4px' },
  paddingX: { xs: '8px', sm: '8px' },
  border: `1px solid ${theme.palette.gray.light}`,
  boxShadow:
    '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
})
export const date_Text = (theme) => ({
  fontSize: {
    xs: theme.typography.menuItems.fontSize,
  },
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.h4.fontWeight,
  color: theme?.palette.black.main,
  lineHeight: '20px',
})
export const manageRoleTableStyle = {
  parent_Box: {
    background: '',
    padding: '15px',
    boxSizing: 'border-box',
  },
  main_Box: {
    display: { xs: '', sm: 'flex' },
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '',
    boxSizing: 'border-box',
    width: { xs: '100%' },
    paddingY: { xs: '0px', sm: '10px' },
  },
  span_Style: {
    display: 'flex',
    gap: '12px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowBack_Style: {
    height: '25px',
    width: '25px',
    color: '#000',
    background: '',
    visibility: { xs: 'hidden', sm: 'visible' },
    display: { xs: 'none', sm: 'flex' },
    cursor: 'pointer',
  },
}
export const manageRole_Text = (theme) => ({
  fontSize: {
    xs: theme.typography.h3.fontSize,
    sm: theme.typography.large.fontSize,
  },
  fontFamily: theme?.typography.menualText.fontFamily,
  fontWeight: theme?.typography.menualText.fontWeight,
  color: theme?.palette.black.main,
  lineHeight: 'normal',
  letterSpacing: '-1.2px',
})

export const btn_Box = (tick, newRole) => ({
  display: tick === true || newRole.length === 0 ? 'none' : 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  gap: '8px',
  marginLeft: 'auto',
})
export const allRolesTable_Style = {
  parent_Box: (theme) => ({
    background: theme.palette.background.default,
    padding: '15px',
    boxSizing: 'border-box',
  }),
  main_Box: {
    display: { xs: '', sm: 'flex' },
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '',
    boxSizing: 'border-box',
    width: { xs: '100%' },
    paddingY: { xs: '0px', sm: '10px' },
  },
  span_Style: {
    display: 'flex',
    gap: '12px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowBack: {
    height: '25px',
    width: '25px',
    color: '#000',
    background: '',
    visibility: { xs: 'hidden', sm: 'visible' },
    display: { xs: 'none', sm: 'flex' },
    cursor: 'pointer',
  },
  loder_Box: {
    position: 'fixed',
    color: '',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },
  model_Style: {
    width: '600px',
    background: '',
    height: 'fit-content',
    mx: 'auto',
    my: 'auto',
    borderRadius: '16px',
  },
  model_MainBox: {
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    padding: '20px',
    borderRadius: '16px',
    gap: '10px',
  },
  members_Box: {
    display: 'flex',
    width: '100%',
    background: '',
    justifyContent: 'space-between',
  },
  custom_Scrollbar_Box: {
    background: '',
    width: '100%',
    maxHeight: '300px',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  map_Middle_Box: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    background: '',
    justifyContent: '',
    height: 'fit-content',
    gap: '10px',
  },
  fullName_Box: {
    display: 'flex',
    background: '',
    alignItems: 'center',
    gap: '20px',
  },
}
export const allRoles_Text_Style = (theme) => ({
  fontSize: {
    xs: theme.typography.h3.fontSize,
    sm: theme.typography.large.fontSize,
  },
  fontFamily: theme?.typography.menualText.fontFamily,
  fontWeight: theme?.typography.menualText.fontWeight,
  color: theme?.palette.black.main,
  lineHeight: 'normal',
  letterSpacing: '-1.2px',
})
export const members_Text_Style = (theme) => ({
  fontSize: theme.typography.h2.fontSize,
  fontFamily: theme?.typography.menualText.fontFamily,
  fontWeight: theme?.typography.menualText.fontWeight,
  color: theme?.palette.black.main,
  background: '',
  lineHeight: 'normal',
})
export const fullname_Text_Style = (theme) => ({
  fontSize: theme.typography.mediumText.fontSize,
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme?.typography.menualText.fontWeight,
  color: theme?.palette.black.main,
  lineHeight: 'normal',
})
export const map_Box = (theme) => ({
  backgroundColor: theme.palette.gray.main,
  border: `1px solid ${theme.palette.sky.main}`,
  borderRadius: '16px',
  display: 'flex',
  boxSizing: 'border-box',
  padding: '10px',
  gap: '16px',
  alignItems: 'center',
})
export const emial_TextStyle = (theme) => ({
  fontSize: theme.typography.paragraph.fontSize,
  fontFamily: theme?.typography.h2.fontFamily,
  fontWeight: theme?.typography.h5.fontWeight,
  color: theme?.palette?.black?.light,
  lineHeight: 'normal',
})
