import { Box, Modal } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './../Sidebar'
import UserContext from '../../Context/userContext'
import { Loader } from '@infostacks/ui-components'
import DashboardHeader from './DashboardHeader'
import { useTheme } from '@emotion/react'
import BuyMoreCreditsAndSeats from '../BuyMoreCreditsAndSeats'
import Confetti from 'react-confetti'

function Dashboard() {
  const { isLoading, currentUser, userAuth } = useContext(UserContext)
  const [moreCreditsAndSeatsPopup, setMoreCreditsAndSeatsPopup] =
    useState(false)
  const [showConfetti, setShowConfetti] = useState(false)

  const handlemoreCreditsAndSeatsPopup = (state) => {
    setMoreCreditsAndSeatsPopup(state)
  }

  const handleClick = () => {
    setShowConfetti(true)
    setTimeout(() => setShowConfetti(false), 6000) // Stop confetti after 3 seconds
  }

  console.log('userAuth Db', userAuth)
  // const navigate =useNavigate()

  const theme = useTheme()
  return (
    <Box
      sx={{
        width: '100vw',
        background: theme.palette.background.default,
        display: 'flex',
        height: '100vh',
      }}
    >
      {/* flexDirection:'column', */}
      {isLoading ? (
        <Box
          sx={{
            position: 'fixed',
            color: '',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <Loader thickness={6} sx={{ color: 'blue' }} />
        </Box>
      ) : (
        <>
          {showConfetti && (
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              recycle={false}
            />
          )}
          <Sidebar
            handlemoreCreditsAndSeatsPopup={handlemoreCreditsAndSeatsPopup}
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              background: theme.palette.background.default,
            }}
          >
            <DashboardHeader />
            <Outlet />
            <Modal
              open={Boolean(moreCreditsAndSeatsPopup)}
              sx={{
                width: 'fit-content',
                height: 'fit-content',
                mx: 'auto',
                my: 'auto',
              }}
            >
              <BuyMoreCreditsAndSeats
                setMoreCreditsAndSeatsPopup={setMoreCreditsAndSeatsPopup}
                handleClick={handleClick}
              />
            </Modal>
          </Box>
        </>
      )}
    </Box>
  )
}

export default Dashboard
