import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts'
import { progressStyle } from './styleUserProfile'
import { useTheme } from '@emotion/react'

// Simulated data showing fluctuating progress
const data = [
  { name: 'Jan', progress: 10 },
  { name: 'Feb', progress: 20 },
  { name: 'Mar', progress: 30 },
  { name: 'Apr', progress: 10 },
  { name: 'May', progress: 50 },
  { name: 'Jun', progress: 90 },
  { name: 'Jul', progress: 70 },
  { name: 'Aug', progress: 50 },
  { name: 'Sep', progress: 80 },
  { name: 'Nov', progress: 90 },
  { name: 'Dec', progress: 80 },
]
// Assuming "Success" theme color
const successColor = '#4CAF50' // Replace with your theme's success color if applicable.

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const theme = useTheme()

    return (
      <Box sx={progressStyle.tooltip_MainBox}>
        <Typography sx={progressStyle.tooltip_Label(theme)}>{label}</Typography>
        <Typography sx={progressStyle.tooltip_value_Label(theme)}>
          {`${payload[0].value}%`}
        </Typography>
      </Box>
    )
  }
  return null
}

const Progress = () => {
  const latestProgress = data[data.length - 1].progress
  const theme = useTheme()
  return (
    <Box sx={progressStyle.parent_Box(theme)}>
      <Box sx={progressStyle.progress_Box}>
        <Typography sx={progressStyle.progress_Text(theme)}>
          Progress
        </Typography>
        <Typography sx={progressStyle.percentage_Text(theme)}>
          {latestProgress}%
        </Typography>
      </Box>
      <Box
        sx={{
          width: '80px',
          height: '60px',
          position: 'relative',
        }}
      >
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart data={data}>
            {/* Removed dotted grid lines */}
            <CartesianGrid stroke='#e0e0e0' strokeWidth={0} />
            <XAxis dataKey='name' hide />
            <YAxis hide />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type='monotone'
              dataKey='progress'
              stroke={successColor}
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default Progress
