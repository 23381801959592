import { Box, Button, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../../Context/userContext'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { Loader } from '@infostacks/ui-components'
import Confetti from 'react-confetti'
import paymentSuccessfullStyle, {
  congratulations_Style,
  countinueButton,
  welcome_Style,
} from './paymentStyle'
import { VerifyIcon } from '../PasswordStartAdornment'

const PaymentSuccessfull = () => {
  const { userAuth, currentUser, updateUserContext } = useContext(UserContext)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const theme = useTheme()
  const fullName = currentUser?.fullName
  const nameParts = fullName ? fullName.split(' ') : []
  console.log('curr in payment', currentUser)
  const [showConfetti, setShowConfetti] = useState(false)

  const handleClick = () => {
    setShowConfetti(true)
    setTimeout(() => setShowConfetti(false), 6000) // Stop confetti after 3 seconds
  }

  const updateUser = async () => {
    setLoading(true)
    try {
      const res = await updateUserContext(
        userAuth,
        { fullName: currentUser?.fullName },
        currentUser?._id
      )
      console.log('user with customerId', res)
      if (res.message === 'User updated successfully') {
        setLoading(false)
        handleClick()
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    const updateData = async () => {
      if (userAuth) {
        updateUser()
      }
    }
    updateData()
  }, [])
  return (
    <Box sx={paymentSuccessfullStyle.parent_Box(theme)}>
      {loading ? (
        <Loader thickness={7} />
      ) : (
        <Box sx={paymentSuccessfullStyle.middle_Box}>
          {showConfetti && (
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              recycle={false}
            />
          )}
          <Box sx={paymentSuccessfullStyle.main_Box}>
            <Box sx={paymentSuccessfullStyle.svgBox}></Box>
          </Box>
          <span style={paymentSuccessfullStyle.span_Style}>
            <Typography sx={welcome_Style(theme)}>
              🎉 Welcome to AILatics
            </Typography>
            <VerifyIcon />
            <Typography sx={congratulations_Style(theme)}>
              Congratulations, <b>{nameParts[0]}</b> You're now on the{' '}
              <b style={{ textTransform: 'capitalize' }}>
                {currentUser?.Subscription?.planType}
              </b>{' '}
              Plan.
            </Typography>
          </span>
          <Button
            onClick={() => navigate('/dashboard')}
            sx={countinueButton(theme)}
          >
            Continue
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default PaymentSuccessfull
