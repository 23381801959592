import * as React from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { useLocation, useNavigate } from 'react-router-dom'
import style from './breadStyles'
import { useTheme } from '@emotion/react'

export default function NavbarBreadcrumbs({
  breadClick,
  crumbClick,
  sx,
  separator,
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const crumbs = location.pathname
    .split('/')
    .filter((url) => url !== '' && isNaN(url))
  // console.log('crumbs1', crumbs)
  crumbs.pop()
  // console.log('crumbs2', crumbs)
  function handleClick(event) {
    event.preventDefault()
    // console.info('You clicked a breadcrumb.');
    if (breadClick) breadClick()
  }
  function handleCrumbClick(to) {
    // console.info('You clicked a breadcrumb.');
    if (crumbClick) crumbClick()
    navigate(to)
  }
  const theme = useTheme()
  return (
    <Box
      role='presentation'
      onClick={handleClick}
      sx={{ ...style.box(theme), ...sx }}
    >
      <Breadcrumbs
        separator={separator}
        aria-label='breadcrumb'
        maxItems={3}
        itemsAfterCollapse={1}
        // itemsBeforeCollapse={2}
      >
        {crumbs.map((item, index) => {
          if (index === crumbs.length - 1) {
            return (
              <Typography key={item} style={style.link(theme)}>
                {item[0].toUpperCase() + item.slice(1).toLowerCase()}
              </Typography>
            )
          } else {
            return (
              <Link
                key={item}
                fontSize='20px'
                fontWeight={600}
                color={theme.palette.text.main}
                underline='hover'
                onClick={() => {
                  handleCrumbClick(`/${crumbs.slice(0, index + 1).join('/')}`)
                }}
                href={`/${crumbs.slice(0, index + 1).join('/')}`}
              >
                {item[0].toUpperCase() + item.slice(1).toLowerCase()}
              </Link>
            )
          }
        })}
      </Breadcrumbs>
    </Box>
  )
}
