const instaFormSyle = {
  instagramModalsParentBox: {
    height: '731px',
    width: 'fit-content',
    display: 'flex',
    boxSizing: 'border-box',
    padding: '0px',
    gap: '30px',
  },

  postForm: (theme) => ({
    boxSizing: 'border-box',
    paddingBottom: '20px',
    paddingTop: '20px',
    height: '731px',
    width: '680px',
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '24px',
  }),
  modelHeader: {
    display: 'flex',
    boxSizing: 'border-box',
    paddingX: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  createNewPost_Text: (theme) => ({
    fontSize: theme?.typography.mediumText.fontSize,
    fontWeight: theme.typography.menualText.fontWeight,
    lineHeight: '30px',
    fontFamily: theme?.typography.h2.fontFamily,
  }),

  uplodeMedia_Text: (theme) => ({
    color: theme.palette.black.main,
    fontStyle: 'normal',
    lineHeight: '30px',
    fontSize: theme?.typography.mediumText.fontSize,
    fontWeight: theme?.typography.h5.fontWeight,
    fontFamily: theme?.typography.h2.fontFamily,
  }),
  button_generateBox: {
    display: 'flex',
    gap: '10px',
    height: 'fit-content',
    width: 'fit-content',
    mt: '13px',
  },

  uploadBox: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    background: '',
  },
  calender_Box: {
    background: '',
    margin: 'auto',
    height: 'fit-content',
    width: 'fit-content',
    borderRadius: '8px',
  },
}
export default instaFormSyle
export const postModelButton1 = (theme) => ({
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: '9px var(--Components-Button-Spacing-X-SM, 12px)',
  fontSize: theme?.typography.menuItems.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: theme.palette.button.dark,
  fontStyle: 'normal',
  lineHeight: '20px',
  width: 'fit-content',
  textTransform: 'capitalize',
  height: '38px',
  color: theme.palette.customWhite.main,
  ':hover': {
    background: theme.palette.button.dark,
  },
})

export const mediaBtn = (theme) => ({
  height: '38px',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: '9px var(--Components-Button-Spacing-X-SM, 12px)',
  fontSize: theme?.typography.menuItems.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: theme.palette.button.dark,
  fontStyle: 'normal',
  lineHeight: '20px',
  width: 'fit-content',
  textTransform: 'capitalize',
  color: 'white',
  '&.Mui-disabled': {
    color: theme.palette.customWhite.main, // Text color for disabled state
  },
  ':hover': {
    background: theme.palette.button.dark,
  },
})

export const generateBtn = (theme) => ({
  height: '38px',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: '9px var(--Components-Button-Spacing-X-SM, 12px)',
  fontSize: theme?.typography.h5.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: theme.palette.primary.aiColor,
  fontStyle: 'normal',
  lineHeight: '20px',
  width: 'fit-content',
  textTransform: 'capitalize',
  color: theme.palette.customWhite.main,
  '&.Mui-disabled': {
    color: 'white', // Text color for disabled state
  },
  ':hover': {
    background: theme.palette.primary.aiColor,
  },
})

export const nextButton = (theme) => ({
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: '9px var(--Components-Button-Spacing-X-SM, 12px)',
  fontSize: theme?.typography.h5.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: theme.palette.button.dark,
  fontStyle: 'normal',
  lineHeight: '20px',
  width: 'fit-content',
  textTransform: 'capitalize',
  color: theme.palette.customWhite.main,
  ':hover': {
    background: theme.palette.button.dark,
  },
})
