import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { emptyScrHeading, homePage } from '../homePageStyle'
import CreatePostButton from './CreatePostButton'
import SearchBar from './SerachBar'
import PostList from '../TrendingPost'
import TrendingAiSearch from '../TrendingSearch'

const EmptyPage = () => {
  const theme = useTheme()
  const [value, setValue] = useState('')
  const [showPost, setShowPost] = useState(false)
  const [prompt, setPrompt] = useState('')

  return (
    <Box sx={homePage.emptyScreen}>
      {showPost ? (
        <Box sx={homePage.chatPageMain}>
          <Box sx={homePage.chatPagePost}>
            <PostList value={value} />
          </Box>
          <Box sx={homePage.chatPageSearch}>
            <TrendingAiSearch />
            <SearchBar setValue={setValue} setShowPost={setShowPost} />
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={homePage.emptyScreenPage}>
            <Box sx={homePage.emptyScrMain}>
              <Typography sx={emptyScrHeading(theme)}>
                What can I help with?
              </Typography>

              <SearchBar
                setValue={setValue}
                setShowPost={setShowPost}
                prompt={prompt}
              />
              <CreatePostButton setPrompt={setPrompt} />
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default EmptyPage
