import React from 'react'
import { trendingPostStyle } from '../homePageStyle'
import { Box, Skeleton } from '@mui/material'
import { useTheme } from '@emotion/react'

const PostSkeleton = () => {
  const theme = useTheme()
  return (
    <Box sx={trendingPostStyle.postSkeleton(theme)}>
      <Box sx={trendingPostStyle.postSkeletonhead}>
        <Box sx={{ display: 'flex', gap: '5px', flexDirection: 'row' }}>
          <Skeleton variant='circular' width={35} height={35} />
          <Skeleton variant='circular' width={35} height={35} />
        </Box>
        <Skeleton
          variant='rectangular'
          width='30%'
          height={35}
          sx={{ borderRadius: '40px' }}
        />
      </Box>
      <Box sx={trendingPostStyle.skeletonBottom}>
        <Skeleton variant='text' width='80%' height={50} />
        <Skeleton variant='text' width='60%' height={40} />
      </Box>
    </Box>
  )
}

export default PostSkeleton
