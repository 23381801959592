import { CustomButton } from '../../../../ReusableComponents/customButtons'
import { OutlinedYoutubeSvg } from '../../../../PasswordStartAdornment/svgFile'
import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import youtubeStyle from '../youtubeStyle'

const YtChannels = ({ytAccounts,workspaceId}) => {
console.log('first',ytAccounts)
 const navigate = useNavigate()
  const theme = useTheme()

  

  return (
    <>
      <Box sx={youtubeStyle.ytchannelHeader(theme)}>
                <Typography sx={youtubeStyle.ytchannelHeaderTypography(theme)}>Youtube</Typography>
              </Box>
              <Box sx={youtubeStyle.ytchannelCardParentBox(theme)}>
              {ytAccounts.map((val, ind) => (
              <Box
                    onClick={() =>
                      navigate(
                        `/dashboard/youtube/${val?.id}/${workspaceId}`
                      )
                    }
                    key={ind}
                    sx={youtubeStyle.ytchannelCard(theme)}
                  >
                    <Box sx={youtubeStyle.ytchannelCardContentBox(theme)}>
                    <img
                      src={val?.snippet?.thumbnails?.high?.url}
                      height={48}
                      width={48}
                      style={youtubeStyle.ytchannelCardContentImage(theme)}
                    />
                    <Box sx={youtubeStyle.ytchannelCardContentNameAndUrlBox(theme)}>
                      <Typography sx={youtubeStyle.ytchannelCardContentNameTypography(theme)}>
                        Channel Name :{' '}
                        <b>{val?.snippet?.title}</b>
                      </Typography>
                      <Typography sx={youtubeStyle.ytchannelCardContentUrlTypography(theme)}>
                        Custom Url :{' '}
                        <b>{val?.snippet?.customUrl}</b>
                      </Typography>
                      
                    </Box>
                    </Box>
                    <Typography sx={youtubeStyle.ytchannelCardSubscribersTypography(theme)}>
                        Subscribers :{' '}
                        <b>{val?.statistics?.subscriberCount}</b>
                      </Typography>
                      <Typography sx={youtubeStyle.ytchannelCardVideosCountTypography(theme)}>
                        Total Videos :{' '}
                        <b>{val?.statistics?.videoCount}</b>
                      </Typography>
                      <Typography sx={youtubeStyle.ytchannelCardViewsCountTypography(theme)}>
                        Total Views :{' '}
                        <b>{val?.statistics?.viewCount}</b>
                      </Typography>
                  </Box>
                   ))}
                   
              </Box>
              </>
      
  )
}

export default YtChannels
{/* <Typography sx={{fontSize: theme.typography.h5.fontSize,fontFamily: theme.typography.h2.fontFamily,fontWeight: theme.typography.h5.fontWeight,color: '#000',}}>
                      {val?.snippet?.description}
                      </Typography> */}