import React, { useContext, useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from '@mui/material'

import {
  CustomButton,
  CustomFilterButton,
} from '../../../../../ReusableComponents/customButtons'
import { headerstyle, searchButton } from '../../instagramStyle'
import CustomMenu from '../../../../../ReusableComponents/customMenu'
import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import { useTheme } from '@emotion/react'
import UserContext from '../../../../../../Context/userContext'

const Header = ({
  setInstaModal,
  filterBtns,
  setFilterBtns,
  postAndStoryOption,
  setPostAndStoryOption,
}) => {
  const { setPostData, setButtonStatus } = useContext(UserContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const [postAndStoryAnchorEl, setPostAndStoryAnchorEl] = useState(null)

  const handleClosebtn = () => {
    setAnchorEl(null)
  }

  const handlePostAndStoryClosebtn = () => {
    setPostAndStoryAnchorEl(null)
  }

  const handleOptionSelect = (option) => {
    setFilterBtns(option)
    handleClosebtn()
  }

  const handlePostAndStorySelect = (option) => {
    setPostAndStoryOption(option)
    handlePostAndStoryClosebtn()
  }

  const menuItems = [
    {
      label: 'Posted',
      onClick: () => handleOptionSelect('Posted'),
      color: '#115E59',
    },
    {
      label: 'Scheduled',
      onClick: () => handleOptionSelect('Scheduled'),
      color: '#1E40AF',
    },
    {
      label: 'Drafted',
      onClick: () => handleOptionSelect('Drafted'),
      color: '#6B7280',
    },
    {
      label: 'Deleted',
      onClick: () => handleOptionSelect('Deleted'),
      color: '#991B1B',
    },
    {
      label: 'Failed',
      onClick: () => handleOptionSelect('Failed'),
      color: '#991B1B',
    },
  ]
  const postAndStoryMenuItems = [
    {
      label: 'Post',
      onClick: () => handlePostAndStorySelect('Post'),
    },
    {
      label: 'Story',
      onClick: () => handlePostAndStorySelect('Story'),
    },
  ]

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePostAndStoryClick = (event) => {
    setPostAndStoryAnchorEl(event.currentTarget)
  }
  const handleCreatePost = (val) => {
    setButtonStatus(null)
    setPostData(null)
    setInstaModal(val)
  }
  const theme = useTheme()
  return (
    <>
      <Box sx={headerstyle.instagramHeader}>
        <Typography sx={headerstyle.main(theme)}>Instagram</Typography>
        <Box sx={headerstyle.headerButtons}>
          <TextField
            variant='outlined'
            placeholder='Search posts'
            size='small'
            sx={headerstyle.searchbar(theme)}
            InputProps={{
              style: headerstyle.searchBar_Field_Style,
              endAdornment: (
                <InputAdornment position='end'>
                  <Button variant='contained' sx={searchButton(theme)}>
                    Search
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <CustomFilterButton
            buttonText={filterBtns}
            onClick={handleClick}
            endIcon={
              <KeyboardArrowDownOutlined
                sx={{ fontSize: '20px', marginLeft: -0.5 }}
              />
            }
            background={
              filterBtns === 'Posted'
                ? '#CCFBF1'
                : filterBtns === 'Scheduled'
                ? '#DBEAFE'
                : filterBtns === 'Drafted'
                ? '#6B7280'
                : filterBtns === 'Deleted'
                ? '#FEE2E2'
                : filterBtns === 'Failed'
                ? '#FEE2E2'
                : null
            }
            hoverBackgroundColor={
              filterBtns === 'Posted'
                ? '#CCFBF1'
                : filterBtns === 'Scheduled'
                ? '#DBEAFE'
                : filterBtns === 'Drafted'
                ? '#6B7280'
                : filterBtns === 'Deleted'
                ? '#FEE2E2'
                : filterBtns === 'Failed'
                ? '#FEE2E2'
                : null
            }
            color={
              filterBtns === 'Posted'
                ? '#115E59'
                : filterBtns === 'Scheduled'
                ? '#1E40AF'
                : filterBtns === 'Drafted'
                ? '#D1D5DB'
                : filterBtns === 'Deleted'
                ? '#991B1B'
                : filterBtns === 'Failed'
                ? '#991B1B'
                : null
            }
          />
          <CustomFilterButton
            buttonText={postAndStoryOption}
            onClick={handlePostAndStoryClick}
            endIcon={
              <KeyboardArrowDownOutlined
                sx={{ fontSize: '20px', marginLeft: -0.5 }}
              />
            }
          />
          <CustomButton
            buttonText={'Create'}
            onClick={() => handleCreatePost(true)}
          />
        </Box>
      </Box>
      <CustomMenu
        menuItems={menuItems}
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={handleClosebtn}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <CustomMenu
        menuItems={postAndStoryMenuItems}
        anchorEl={postAndStoryAnchorEl}
        open={postAndStoryAnchorEl}
        onClose={handlePostAndStoryClosebtn}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </>
  )
}

export default Header
