import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import appLogo from '../../Assets/Images/aiLatics-Logo.png'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import navStyles, { NavMainBox, customNavOptionButton } from './navStyles'
import { SigninAndSignupButton } from '../ReusableComponents/customButtons'
const NavBar = () => {
  const navigate = useNavigate()
  const signIn = () => {
    navigate('/signin')
  }
  const signUp = () => {
    navigate('/signup')
  }
  const theme = useTheme()
  return (
    <Box sx={NavMainBox(theme)}>
      <Box sx={navStyles.navLogo}>
        <img src={appLogo} alt='app-logo' height={'100%'} width={'100%'} />
      </Box>
      <Box sx={navStyles.NavCenterOptions}>
        <Button sx={customNavOptionButton(theme)}>Home</Button>
        <Button sx={customNavOptionButton(theme)}>services</Button>
        <Button sx={customNavOptionButton(theme)}>How it works</Button>
      </Box>
      <Box sx={navStyles.NavButtonsBox}>
        <SigninAndSignupButton
          onClick={signIn}
          buttonText='Login'
          variant='outlined'
          background={theme.palette.sky.extraLight}
          hoverBackgroundColor={theme.palette.sky.extraLight}
          color={theme.palette.button.dark}
          border={`1px solid ${theme.palette.button.dark}`}
        />
        <SigninAndSignupButton
          buttonText='Get Started'
          hoverBackgroundColor={theme.palette.primary.main}
          onClick={signUp}
        />
      </Box>
    </Box>
  )
}

export default NavBar
