import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import manualPostFooterStyle from './manualPostFooterStyle'
import { Loader } from '@infostacks/ui-components'
import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import CustomMenu from '../../../../../../ReusableComponents/customMenu'
import UserContext from '../../../../../../../Context/userContext'
import { useTheme } from '@emotion/react'
import { formatDate } from '../../../../../../../API'

const DraftToPublishFooter = ({
  handleOptionSelect,
  handleForm,
  loading,
  setTimeModal,
  ScheduleTime,
  selectedOption,
}) => {
  const { postData, buttonStatus, setScheduleTime } = useContext(UserContext)
  const [draftedSelectedOption, setDraftedSelectedOption] =
    useState('DraftToPublish')
  const theme = useTheme()
  console.log('first', ScheduleTime)
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosebtn = () => {
    setAnchorEl(null)
  }
  const DraftToSchedule = () => {
    setScheduleTime(null)
    handleOptionSelect('DraftToSchedule')
    setDraftedSelectedOption('DraftToSchedule')
    setAnchorEl(null)
  }
  const DraftToPublish = () => {
    handleOptionSelect('DraftToPublish')
    setDraftedSelectedOption('DraftToPublish')
    setAnchorEl(null)
  }
  const menuItems = [
    {
      label: 'Publish',
      onClick: () => DraftToPublish(),
    },
    {
      label: 'Schedule',
      onClick: () => DraftToSchedule(),
    },
    //   {
    //     label: 'Draft',
    //     onClick: () => handleOptionSelect('Draft'),
    //   },
  ]
  return (
    <>
      <Box sx={manualPostFooterStyle.footerMain_Box}>
        <Box
          sx={manualPostFooterStyle.scheduleDisplayStyles(
            ScheduleTime,
            selectedOption
          )}
        >
          <Typography sx={manualPostFooterStyle.schedule_Text(theme)}>
            Schedule date:{' '}
            <span style={manualPostFooterStyle.scheduleDateTIme_Text(theme)}>
              {formatDate(ScheduleTime)}
            </span>
          </Typography>
          <Button
            onClick={() => setTimeModal(true)}
            sx={manualPostFooterStyle.edit_Button(loading, theme)}
          >
            Edit
          </Button>
        </Box>
        <Box sx={manualPostFooterStyle.loadingBox(loading, theme)}>
          {loading ? (
            <Loader thickness={7} size={30} color='white' />
          ) : (
            <>
              <Button
                onClick={() => handleForm(draftedSelectedOption)}
                sx={manualPostFooterStyle.selectText_Button(theme)}
              >
                {selectedOption}
              </Button>
              <Divider
                variant='middle'
                orientation='vertical'
                sx={{ height: '18px', bgcolor: 'white' }}
              />
              <IconButton
                onClick={handleClick}
                sx={{ boxSizing: 'border-box', padding: '4px' }}
              >
                <KeyboardArrowDownOutlined
                  sx={manualPostFooterStyle.keyboardArrowDownOutlined(theme)}
                />
              </IconButton>
            </>
          )}
        </Box>
      </Box>
      <CustomMenu
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={handleClosebtn}
        menuItems={menuItems}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </>
  )
}

export default DraftToPublishFooter
