const inviteLandingPageStyles = {
  parentBox: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    minHeight: { xs: 'fit-content', sm: '100vh' },
    gap: { xs: '20px', sm: '20px', md: '30px', xl: '27px' },
    fontFamily: 'Montserrat',
    padding: { xs: '5px', sm: '10px' },
    position: 'relative',
    boxSizing: 'border-box',
    overflowY: 'hidden',
    overflowX: 'hidden',
    background: theme.palette.background.default,
  }),
  circularProgress: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },

  buttonParentBox: {
    display: 'flex',
    width: 'fit-content',
    gap: '16px',
    height: 'fit-content',
    mt: '24px',
  },
}

export default inviteLandingPageStyles

export const absoluteBox = (theme) => ({
  backgroundColor: theme.palette.primary.main,
  opacity: 0.6,
  borderRadius: '600px',
  width: '1050px',
  height: '250px',
  position: 'absolute',
  zIndex: '-1',
  marginTop: '23%',
  filter: 'blur(339.79998779296875px)',
})
export const mainCard_Style = (theme) => ({
  background: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  paddingY: '40px',
  paddingX: '40px',
  width: '750px',
  height: 'fit-content',
  gap: '10px',
  my: 'auto',
  borderRadius: '16px',
  boxShadow:
    '0px 20px 24px -4px rgba(16, 24, 40, 0.10), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
})

export const workspaceNameTypo = (theme) => ({
  fontSize: theme?.typography.large.fontSize,
  color: theme?.palette.black.light,
  fontFamily: theme?.typography.menualText.fontFamily,
  fontWeight: theme?.typography.menualText.fontWeight,
  lineHeight: 'normal',
  letterSpacing: '-1.2px',
})
export const categoryText = (theme) => ({
  fontSize: theme?.typography.h4.fontSize,
  color: theme?.palette.border.dark,
  fontFamily: theme?.typography.menualText.fontFamily,
  fontWeight: theme?.typography.h5.fontWeight,
  lineHeight: '24px',
})
export const invitedTypography = (theme) => ({
  fontSize: theme?.typography.mediumText.fontSize,
  color: theme?.palette.black.main,
  fontFamily: theme?.typography.h2.fontFamily,
  fontWeight: theme?.typography.h2.fontWeight,
  mt: '12px',
  width: '100%',
  textAlign: 'center',
  lineHeight: '30px',
})
export const cancelButton = (theme) => ({
  textTransform: 'capitalize',
  boxSizing: 'border-box',
  width: '130px',
  borderRadius: '10px',
  fontWeight: theme?.typography.h2.fontWeight,
  lineHeight: '24px',
  fontFamily: theme?.typography.h2.fontFamily,
  fontSize: theme?.typography.h4.fontSize,
  border: `1px solid ${theme.palette.error.main}`,
  color: theme?.palette.error.main,
  ':hover': { border: `1px solid ${theme.palette.error.main}` },
})
export const acceptButton = (theme) => ({
  textTransform: 'capitalize',
  boxSizing: 'border-box',
  width: '130px',
  borderRadius: '10px',
  fontSize: theme?.typography.h4.fontSize,
  border: `1px solid ${theme.palette.primary.main}`,
  background: theme.palette.button.dark,
  fontWeight: theme?.typography.h2.fontWeight,
  lineHeight: '24px',
  fontFamily: theme?.typography.h2.fontFamily,
  ':hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.button.dark,
  },
})
export const desc_Typography = (theme) => ({
  fontSize: theme?.typography.h4.fontSize,
  color: theme?.palette.border.dark,
  fontFamily: theme?.typography.h2.fontFamily,
  fontWeight: theme?.typography.h5.fontWeight,
  lineHeight: '24px',
  fontStyle: 'normal',
})
