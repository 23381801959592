import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import CustomMenu from '../../../../../../ReusableComponents/customMenu'
import { Loader } from '@infostacks/ui-components'
import { formatDate } from '../../../../../../../API'
import manualPostFooterStyle from './manualPostFooterStyle'
import { useTheme } from '@emotion/react'
import UserContext from '../../../../../../../Context/userContext'
import SchedulePublishFooter from './schedulePublishFooter'
import ScheduleEditFooter from './scheduleEditFooter'
import DraftEditFooter from './draftEditFooter'
import DraftToPublish from './draftToPublishFooter'
import DraftToPublishFooter from './draftToPublishFooter'

const ManualPostFooter = ({
  loading,
  handleOptionSelect,
  selectedOption,
  setSelectedOption,
  anchorEl,
  handleClosebtn,
  handleClick,
  handleForm,
  ScheduleTime,
  setTimeModal,
  filterBtns,
}) => {
  const { postData, buttonStatus } = useContext(UserContext)

  const menuItems = [
    {
      label: 'Publish',
      onClick: () => handleOptionSelect('Publish'),
    },
    {
      label: 'Schedule',
      onClick: () => handleOptionSelect('Schedule'),
    },
    {
      label: 'Draft',
      onClick: () => handleOptionSelect('Draft'),
    },
  ]

  // const handleOptionSelect = (option) => {
  //   // console.log('agya 123123123', option)
  //   setSelectedOption(option)
  //   handleClosebtn()
  // }

  const theme = useTheme()

  return (
    <>
      {filterBtns === 'Scheduled' && buttonStatus === 'Publish' ? (
        <SchedulePublishFooter
          postData={postData}
          handleOptionSelect={handleOptionSelect}
          handleForm={handleForm}
          loading={loading}
        />
      ) : filterBtns === 'Scheduled' && buttonStatus === 'Edit' ? (
        <ScheduleEditFooter
          postData={postData}
          handleOptionSelect={handleOptionSelect}
          handleForm={handleForm}
          loading={loading}
          setTimeModal={setTimeModal}
          ScheduleTime={ScheduleTime}
          selectedOption={selectedOption}
        />
      ) : filterBtns === 'Drafted' && buttonStatus === 'Edit' ? (
        <DraftEditFooter
          handleOptionSelect={handleOptionSelect}
          handleForm={handleForm}
          loading={loading}
        />
      ) : filterBtns === 'Drafted' && buttonStatus === 'Publish' ? (
        <DraftToPublishFooter
          postData={postData}
          handleOptionSelect={handleOptionSelect}
          handleForm={handleForm}
          loading={loading}
          setTimeModal={setTimeModal}
          ScheduleTime={ScheduleTime}
          selectedOption={selectedOption}
        />
      ) : (
        <Box sx={manualPostFooterStyle.footerMain_Box}>
          <Box
            sx={manualPostFooterStyle.scheduleDisplayStyles(
              ScheduleTime,
              selectedOption
            )}
          >
            <Typography sx={manualPostFooterStyle.schedule_Text(theme)}>
              Schedule date:{' '}
              <span style={manualPostFooterStyle.scheduleDateTIme_Text(theme)}>
                {formatDate(ScheduleTime)}
              </span>
            </Typography>
            <Button
              onClick={() => setTimeModal(true)}
              sx={manualPostFooterStyle.edit_Button(loading, theme)}
            >
              Edit
            </Button>
          </Box>
          <Box sx={manualPostFooterStyle.loadingBox(loading, theme)}>
            {loading ? (
              <Loader thickness={7} size={30} color='white' />
            ) : (
              <>
                <Button
                  onClick={() => handleForm(selectedOption)}
                  sx={manualPostFooterStyle.selectText_Button(theme)}
                >
                  {selectedOption}
                </Button>
                <Divider
                  variant='middle'
                  orientation='vertical'
                  sx={{ height: '18px', backgroundColor: 'white' }}
                />
                <IconButton
                  onClick={handleClick}
                  sx={{ boxSizing: 'border-box', padding: '4px' }}
                >
                  <KeyboardArrowDownOutlined
                    sx={manualPostFooterStyle.keyboardArrowDownOutlined(theme)}
                  />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
      )}

      <CustomMenu
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={handleClosebtn}
        menuItems={menuItems}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </>
  )
}

export default ManualPostFooter
