import React from 'react'
import Box from '@mui/material/Box'
import MoreVertRounded from '@mui/icons-material/MoreVertRounded'
import { useTheme } from '@mui/material/styles'

const CustomMoreVertRoundedButton = ({
  onClick,
  background,
  color,
  hoverBackgroundColor,
}) => {
  const theme = useTheme()

  return (
    <Box
      onClick={onClick}
      sx={{
        textTransform: 'capitalize',
        cursor: 'pointer',
        display: 'flex',
        boxSizing: 'border-box',
        padding: '6px',
        gap: '10px',
        alignItems: 'center',
        borderRadius: '8px',
        height: '36px',
        width: '36px',
        background: background || theme.palette.button.dark,
        '&:hover': {
          hoverBackgroundColor:
            hoverBackgroundColor || theme.palette.button.dark,
        },
      }}
    >
      <MoreVertRounded
        sx={{
          color: color || theme?.palette.customWhite.main,
        }}
      />
    </Box>
  )
}
export default CustomMoreVertRoundedButton
