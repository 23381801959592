import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import ailatics from '../../../../Assets/Images/aiLatics-Logo.png'
// import { ailatics } from '../assets'
import MenuSvg from '../assets/svg/MenuSvg'
import { links } from '../config'
import { navigation } from '../constants'
import Button from './Button'
import { HambugerMenu } from './design/Header'
import { Typography } from '@mui/material'
import { curve } from '../assets'

const Header = () => {
  const pathname = useLocation()
  const navigate = useNavigate()
  const [openNavigation, setOpenNavigation] = useState(false)

  const toggleNavigation = () => {
    if (openNavigation) {
      setOpenNavigation(false)
      enablePageScroll()
    } else {
      setOpenNavigation(true)
      disablePageScroll()
    }
  }

  const handleClick = () => {
    if (!openNavigation) return

    enablePageScroll()
    setOpenNavigation(false)
  }

  return (
    <div
      className={`fixed top-0 left-0 w-full z-50 border-b border-n-6 lg:bg-n-8/90 lg:bg-blur-sm ${
        openNavigation ? 'bg-n-8' : 'bg-n-8/90 backdrop-blur-sm'
      }`}
    >
      <div className='flex items-center px-5 lg:px-7.5 xl:px-10 max-lg:py-4'>
        <a className='block w-auto  xl:mr-8' href='#hero'>
          <div className='flex gap-2.5 items-center'>
            <img
              src={ailatics}
              alt='ailatics'
              style={{
                maxWidth: '60px',
                width: '100%',
                maxHeight: '60px',
                height: '100%',
              }}
              className='pointer-events-none select-none'
            />
            <p className='hidden md:flex body-1 max-w-3xl mx-auto mt-2 text-white text-4xl lg:text-4xl md:text-3xl  relative font-semibold'>
              AILatics
              <img
                src={curve}
                className='absolute top-full left-0 w-full xl:-mt-1 pointer-events-none select-none'
                width={624}
                height={28}
                alt='Curve'
              />
            </p>
          </div>
        </a>

        <nav
          className={`${
            openNavigation ? 'flex' : 'hidden'
          }  fixed top-[5rem] left-0 right-0 bottom-0 bg-n-8 lg:static lg:flex lg:mx-auto justify-center lg:items-center lg:bg-transparent`}
        >
          <div className='relative z-2 flex flex-col  items-center justify-center m-auto lg:flex-row'>
            {navigation.map((item) => (
              <a
                key={item.id}
                href={item.url}
                // target={item.external ? "_blank" : "_self"}
                // rel={item.external && "noreferrer noopener"}
                onClick={handleClick}
                className={`block relative font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 ${
                  item.onlyMobile && 'lg:hidden'
                } px-6 py-6 md:py-8 lg:mr-0.25 lg:text-xs lg:font-semibold ${
                  item.url === pathname.hash
                    ? 'z-2 lg:text-n-1'
                    : 'lg:text-n-1/50'
                } lg:leading-5 lg:hover:text-n-1 xl:px-12`}
              >
                {item.title}
              </a>
            ))}
          </div>

          <HambugerMenu />
        </nav>
        <div className=' flex  gap-8 align-middle ml-auto lg:ml-0 justify-self-end '>
          <p
            onClick={() => navigate('/signin')}
            className=' cursor-pointer body-1 flex   max-w-3xl mx-auto mt-2.5 text-white  text-xl   relative font-semibold transition-colors hover:text-color-1'
          >
            Login
            <img
              src={curve}
              className='absolute top-full left-0 w-full   xl:-mt-2.5 lg:-mt-2 pointer-events-none select-none'
              width={624}
              height={28}
              alt='Curve'
            />
          </p>
          <Button
            onClick={() => navigate('/signup')}
            className='hidden lg:flex'
            external
          >
            Get Started-free
          </Button>
        </div>
        <Button onClick={toggleNavigation} className='ml-5 lg:hidden' px='px-3'>
          <MenuSvg openNavigation={openNavigation} />
        </Button>
      </div>
    </div>
  )
}

export default Header
