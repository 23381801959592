import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import UserContext from '../../../../Context/userContext'
import { Box, Typography } from '@mui/material'
import {
  headerstyle,
  instagramContentBox,
  instagramStyle,
} from './instagramStyle'
import { getSpecificSpacesAPI } from '../../../../API'
import { Loader } from '@infostacks/ui-components'
import EmptyScreen from './emptyScreen'
import { instagramProfilesListApi } from '../../../../API/PlatformsAPIs/InstagramApi'
import { useTheme } from '@emotion/react'
const Instagram = () => {
  const { currentUser, userAuth, stateCheck } = useContext(UserContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const basePath = currentPath.split('/').slice(0, 3).join('/')
  const [IgFound, setIgFound] = useState()
  const [loading, setLoading] = useState(true)
  const [instagramAccounts, setInstagramAccounts] = useState([])
  const getInstagramProfileslist = async (spaceId) => {
    try {
      const res = await instagramProfilesListApi(userAuth, spaceId)
      console.log('instagramProfilesListApi', res)
      setInstagramAccounts(res.filter((obj) => obj.instagram_business_account))
    } catch (error) {
      console.log('error', error)
    }
  }

  const getRecentSpace = async (spaceId) => {
    setLoading(true)
    try {
      const fetchSpace = await getSpecificSpacesAPI(
        userAuth,
        currentUser,
        spaceId
      )
      console.log('fetchSpace', fetchSpace)
      const platforms = fetchSpace.platforms?.some(
        (val) => val.name === 'instagram'
      )
      setIgFound(platforms)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    const spaceId = window.sessionStorage.getItem('spaceId')
    if (id !== spaceId) {
      navigate(`${basePath}/${spaceId}`)
    }
    const fetch = async () => {
      if (userAuth) {
        getRecentSpace(spaceId)
        getInstagramProfileslist(spaceId)
      }
    }
    fetch()
  }, [id, basePath, navigate, stateCheck, userAuth])
  const theme = useTheme()
  return (
    <Box sx={instagramStyle.parentContainer(theme)}>
      {loading ? (
        <Box sx={instagramStyle.circularProgressBox}>
          <Loader thickness={6} sx={{ color: 'blue' }} />
        </Box>
      ) : (
        <Box sx={instagramContentBox(IgFound)}>
          {IgFound ? (
            <>
              <Box sx={headerstyle.instagramHeader}>
                <Typography sx={headerstyle.main(theme)}>Instagram</Typography>
              </Box>
              <Box sx={instagramStyle.main_Box}>
                {instagramAccounts.map((val, ind) => (
                  <Box
                    onClick={() =>
                      navigate(
                        `/dashboard/instagram/${val?.id}/${val?.instagram_business_account?.id}/posts/${id}`
                      )
                    }
                    key={ind}
                    sx={instagramStyle.card_Box(theme)}
                  >
                    <img
                      src={val?.instagram_business_account?.profile_picture_url}
                      height={48}
                      width={48}
                      style={instagramStyle.img_Style(theme)}
                    />
                    <Box sx={instagramStyle.accountDetails_Box}>
                      <Typography sx={instagramStyle.account_TextStyle(theme)}>
                        Account Name :{' '}
                        <b>{val?.instagram_business_account?.name}</b>
                      </Typography>
                      <Typography sx={instagramStyle.account_TextStyle(theme)}>
                        User Name :{' '}
                        <b>{val?.instagram_business_account?.username}</b>
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <EmptyScreen />
          )}
        </Box>
      )}
    </Box>
  )
}

export default Instagram
