import { Box, Typography } from '@mui/material'
import React from 'react'
import Profile from './profile'
import ActiveGoals from './activeGoals'
import Progress from './progress'
import CompletedTask from './completedTask'
import DueTask from './dueTask'
import { useTheme } from '@emotion/react'
import styleUserProfile, {
  parent_Box,
  recent_ActivitiesText,
} from './styleUserProfile'
const ProfileView = () => {
  const theme = useTheme()
  return (
    <Box sx={parent_Box(theme)}>
      <Box sx={styleUserProfile.main_Box(theme)}>
        <Profile />
        <Box sx={styleUserProfile.second_Box(theme)}>
          <ActiveGoals /> <Progress /> <CompletedTask /> <DueTask />
        </Box>
      </Box>
      <Typography sx={recent_ActivitiesText(theme)}>
        Recent Activities
      </Typography>
    </Box>
  )
}
export default ProfileView
